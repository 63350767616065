import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { IListagemLCDPR, LCDPRService } from '../../../shared/services/api/fiscal/LCDPR/LCDPRService';
import dayjs from 'dayjs';
import { FerramentasDaListagemLCDPR } from '../../../shared/components/ferramentas-da-listagem/FerramentasDaListagemLCDPR';


export const ListagemDeLCDPR: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  
  const [rows, setRows] = useState<IListagemLCDPR[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const dataInicial = useMemo(() => {
    return searchParams.get('datainicial') || dayjs().startOf('month').format('DD/MM/YYYY').toString();
  }, [searchParams]);

  const dataFinal = useMemo(() => {
    return searchParams.get('datafinal') || dayjs().format('DD/MM/YYYY').toString();
  }, [searchParams]);
  
  const tipo = useMemo(() => {
    return searchParams.get('tipo') || 'todos'; 
  }, [searchParams]);

  const numero = useMemo(() => {
    return searchParams.get('numero') || '';
  }, [searchParams]);
  

  const produtor = useMemo(() => {
    return searchParams.get('produtor') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  function verificaFormato(data: string) {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(data);
  }

  const [busca, setBusca] = useState(true); 
  const [autoComplete, setAutoComplete] = useState(true);
  const [data, setData] = useState(true);

  useEffect(() => {
    if(tipo === 'produtor'){
      setData(true);
      setBusca(false);
      setAutoComplete(true);
    }else if(tipo === 'numero'){
      setBusca(true);
      setAutoComplete(false);
      setData(false);
    }else{
      setBusca(false);
      setAutoComplete(false);
      setData(false);
    }
  }, [tipo]);
  
  useEffect(() => {
    if (tipo === 'todos' || tipo === 'produtor' && produtor != '' && verificaFormato(dataInicial) && verificaFormato(dataFinal) || tipo === 'numero' && numero != '') { 
      setIsLoading(true);
   
      debounce(() => {
        LCDPRService.getAll(pagina, dataInicial, dataFinal, produtor, numero, tipo)
          .then((result) => {
            setIsLoading(false);
  
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }
    
  }, [numero, pagina, tipo, dataInicial, dataFinal, produtor]);
  
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de LCDPR'
      barraDeFerramentas={
        <FerramentasDaListagemLCDPR
          produtor={Number(produtor)}
          mostrarInputBusca={busca}
          mostrarInputBasicSelect
          mostrarInputData={data}
          mostrarAutoComplete={autoComplete}
          dataInicial={dataInicial}
          dataFinal={dataFinal}
          textoDaBusca={numero}
          textoDoSelect={tipo}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/lcdpr/detalhe/novo')}
          aoMudarDataInicial={datainicial => setSearchParams({ produtor: produtor, numero: numero, tipo: tipo, pagina: '1', datainicial: datainicial, datafinal: dataFinal }, { replace: true })}
          aoMudarDataFinal={datafinal => setSearchParams({ produtor: produtor, numero: numero, tipo: tipo, pagina: '1', datainicial: dataInicial, datafinal: datafinal }, { replace: true })}
          aoMudarTextoDeBusca={texto => setSearchParams({ produtor: produtor, numero: texto, tipo: tipo, pagina: '1', datainicial: dataInicial, datafinal: dataFinal }, { replace: true })}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ produtor: produtor, numero: numero, tipo: textoselect, pagina: '1', datainicial: dataInicial, datafinal: dataFinal  }, { replace: true})}
          aoMudarTextoDoParceiro={texto => setSearchParams({ produtor: texto.toString(), numero: numero, tipo: tipo, pagina: '1', datainicial: dataInicial, datafinal: dataFinal }, { replace: true })}
          dadosSelecao={[
            { nameBD: 'produtor', descricao: 'Produtor/Data' },
            { nameBD: 'numero', descricao: 'Número' },
            { nameBD: 'todos', descricao: 'Todos' },
          ]}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data Emissão</TableCell>
              <TableCell>Data Caixa</TableCell>
              <TableCell>Produtor</TableCell>
              <TableCell>Participante</TableCell>
              <TableCell>Número Participante</TableCell>
              <TableCell>Valor Entrada</TableCell>
              <TableCell>Valor Saída</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idlcdpr} 
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/lcdpr/detalhe/${row.idlcdpr}`)}
              >
                <TableCell>{dayjs(row.dataemissao).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{dayjs(row.datacaixa).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{row.produtor}</TableCell>
                <TableCell>{row.participante}</TableCell>
                <TableCell>{row.numero}</TableCell>
                <TableCell>{row.entrada.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                <TableCell>{row.saida.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
              </TableRow>
            ))}
          </TableBody>
  
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
  
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ pagina: newPage.toString(), produtor: produtor, numero: numero, tipo: tipo, datainicial: dataInicial, datafinal: dataFinal }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};