/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
 
interface Item {
  iditensrom: string;
  qtd: number;
  descricao: string;
  fazenda: string;
  conversor: number;
  un: string;
  subtotal: number;
  total: number;
  qtdajuste: number;
  totaldesconto: number;
  preco: number;
}

interface romaneio {
  idromaneio: string,
  dataemissao: Date
  vencimento: Date;
  numero: number;
  numeronfe: string;
  valortotal: number
  nome: string;
  cpf: string;
  valoritens: number;
  seguro: number;
  frete: number;
  icmsmercaodira: number; 
}

interface Entry {
  romaneios: romaneio;
  itens: Item[];
}


async function RRelatorioRomaneioDetalhes(data: Entry[], datai: string, dataf: string){
  const TotalGeral = data.reduce((acc, item) => acc + item.romaneios.valortotal, 0);

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }


  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [20, 10, 20, 10],
    pageOrientation: 'portrait',
    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],  
          widths: [48, 500],  
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },  
              {
                border: [false, false, false, false],
                table: {
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Relatório de Romaneios Detalhados', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      { text: 'Período de: '+datai+' a '+dataf,  alignment: 'left', fillColor: '#dddddd', fontSize: 8},     
      data.map(e => {
        const { romaneios, itens } = e;
        const rows = itens.map((row) => {
          return [
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(row.qtd) , fontSize: 7, alignment: 'right'},
            {text: row.descricao, alignment: 'left',  fontSize: 7},
            {text: row.un, alignment: 'center',  fontSize: 7},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(row.conversor) , fontSize:7, alignment: 'center'},
            {text: row.fazenda, alignment: 'left',  fontSize: 7},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(row.preco) , fontSize: 7, alignment: 'right'},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(row.subtotal) , fontSize: 7, alignment: 'right'},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(row.qtdajuste) , fontSize: 7, alignment: 'right'},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(row.totaldesconto) , fontSize: 7, alignment: 'right'},
            {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(row.total) , fontSize: 7, alignment: 'right'},
          ]; 
        });  
        return [
          [             
            [                            
              {  
                margin: [0, 5, 0, 0],    
                table: { 
                  widths: [50, 52, 55, 58, 280], 
                  body: [
                    [
                      {text: 'Data \n'+ moment(e.romaneios.vencimento).format('DD/MM/YYYY'), bold: true, fontSize: 9, alignment: 'center', border: [true, true, false, false]},
                      {text: 'Vencimento \n'+ moment(e.romaneios.vencimento).format('DD/MM/YYYY'), bold: true, fontSize: 9, alignment: 'center', border: [false, true, false, false]},
                      {text: 'Nº Romaneio \n '+ e.romaneios.numero, alignment: 'right',  fontSize: 9, border: [false, true, false, false]}, 
                      {text: 'Nº Nota Fiscal \n'+e.romaneios.numeronfe, alignment: 'left',  fontSize: 9, border: [false, true, false, false]}, 
                      {text: 'Cliente \n'+ e.romaneios.nome+' CPF/CNPJ: '+e.romaneios.cpf, alignment: 'left',  fontSize: 9, border: [false, true, true, false]}, 
                    ],
                  ] 
                },  
              },
              {   
                table: {
                  widths: [42, 100, 15, 18, 80, 30, 40, 40, 40, 45],  
                  body: [
                    [
                      { text: 'Quantidade',  alignment: 'left', fillColor: '#dddddd', fontSize: 7}, 
                      { text: 'Descrição',  alignment: 'left', fillColor: '#dddddd', fontSize: 7}, 
                      { text: 'UN',  alignment: 'center', fillColor: '#dddddd', fontSize: 7},
                      { text: 'KG',  alignment: 'center', fillColor: '#dddddd', fontSize: 7},   
                      { text: 'Fazenda',  alignment: 'left', fillColor: '#dddddd', fontSize: 7}, 
                      { text: 'Unitário',  alignment: 'right', fillColor: '#dddddd', fontSize: 7},
                      { text: 'Preço Total',  alignment: 'right', fillColor: '#dddddd', fontSize: 7},
                      { text: 'Qtd. Ajuste',  alignment: 'right', fillColor: '#dddddd', fontSize: 7},
                      { text: 'Desconto',  alignment: 'right', fillColor: '#dddddd', fontSize: 7},
                      { text: 'Valor Liquido',  alignment: 'right', fillColor: '#dddddd', fontSize: 7},
                    ],
                    ...rows,
                  ]
                },       
              },   
              {   
                table: {
                  widths: [200, 60, 45, 45, 80, 60],  
                  body: [
                    [
                      { text: '',  alignment: 'left', fontSize: 7, border: [false, false, false, false]}, 
                      { text: 'Sub. Total \n'+ new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(e.romaneios.valoritens), alignment: 'right',  fontSize: 10, border: [false, false, false, false]}, 
                      { text: 'Seguro \n'+ new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(e.romaneios.seguro) ,  alignment: 'right', fontSize: 10, border: [false, false, false, false]},   
                      { text: 'Frete \n'+ new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(e.romaneios.frete),  alignment: 'right', fontSize: 10, border: [false, false, false, false]}, 
                      { text: 'ICMS Mercadoria \n'+ new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(e.romaneios.icmsmercaodira),  alignment: 'right', fontSize: 10, border: [false, false, false, false]},
                      { text: 'Total \n'+ new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(e.romaneios.valortotal),  alignment: 'right', fontSize: 10, border: [false, false, false, false]},
                    ],
                    [
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},
                      { text: '', border: [false, false, false, true]},  
                      { text: '', border: [false, false, false, true]},
                    ]
                  ]
                },       
              }                                          
            ]
          ]                           
            
        ];
      }     
      ) ,
      { margin: [0, 2, 18, 0],  text: 'Total Geral: '+ new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(TotalGeral) , fontSize: 14, alignment: 'right'}
    ]
  };
  pdfMake.createPdf(documentDefinition).open();

}

export default RRelatorioRomaneioDetalhes;