import { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField,  VForm, useVForm, IVFormErrors, VSwitch, BasicSelect, VTextFieldData } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { DependenteService } from '../../../shared/services/api/rh/colaborador/DependenteService';
import { Api } from '../../../shared/services/api/axios-config';

interface IFormData {
  idfun: string;
  nome: string;
  nascimento: Date;
  datadoc: Date;
  tipodependente: string;
  tipo: string;
  status: boolean;
  colaborador: string;
}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'colaborador'>> = yup.object().shape({
  idfun: yup.string().required(),
  nome: yup.string().required().min(20),
  nascimento: yup.date().required('O campo é obrigatório')
    .test('valid-date', 'Selecione uma data válida', function (value) {
      const currentDate = new Date();
      if (!value || value > currentDate) return false; 
      return true;
    }),
  datadoc: yup.date().required('O campo é obrigatório')
    .test('valid-date', 'Selecione uma data válida', function (value) {
      const currentDate = new Date();
      if (!value || value > currentDate) return false; 
      return true;
    }),
  tipodependente: yup.string().required(),
  tipo: yup.string().required(),
  status: yup.boolean().required().default(true),
});

export const DetalheDependentes: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const { idColaborador = 'novo' } = useParams<'idColaborador'>();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');
  const [nomeColaborador, setNomeColaborador] = useState('');
  
  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      
      DependenteService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate(`/dependentes?idfun=${idColaborador}`);
          } else {
            setDescricao(result.nome);
            formRef.current?.setData(result);
          }
        });
    } else {
      formRef.current?.setData({
        nome: '',
        nascimento: '',
        datadoc: '',
        tipodependente: '',
        tipo: '',
        status: true,
        idfun: {idColaborador},
        colaborador: nomeColaborador,
      });
    }

  }, [id, nomeColaborador]);

  useEffect(() => {
    const fetchData = async () => {
      if (id == 'novo') {  
        try {
          const response = await Api.get(`/funcionarios/${idColaborador}`);
          const colaboradorData = response.data;

          setNomeColaborador(colaboradorData.nome);
          
        } catch (error) {
          setIsLoading(false);
          alert('Erro ao obter dados do colaborador.');
          navigate(`/dependentes?idfun=${idColaborador}`);
        }
      }
    };
  
    fetchData();
  }, [id, idColaborador]);
  
  const handleSave = (dados: IFormData) => {
    dados.idfun = idColaborador;
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { colaborador, ...dadosSemColaborador } = dados;
  
    formValidationSchema.
      validate(dadosSemColaborador, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          
          DependenteService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate(`/dependentes?idfun=${idColaborador}`);
                } else {
                  navigate(`/dependentes/detalhe/${result}/${idColaborador}`);
                }
              }
            });
        } else {
          DependenteService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate(`/dependentes?idfun=${idColaborador}`);
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      DependenteService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate(`/dependentes?idfun=${idColaborador}`);
          }
        });
    }
  };

  return (
    
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Novo Dependente' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao={false}
 
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate(`/dependentes?idfun=${idColaborador}`)}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate(`/dependentes/detalhe/novo/${idColaborador}`)}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >
      
      <VForm ref={formRef} onSubmit={handleSave}>
        
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid item>
              <Typography variant='h6'>Geral</Typography>
            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>          
                <VTextField
                  fullWidth
                  name='colaborador'
                  disabled={true}
                  label='Colaborador'
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>          
                <VTextField
                  fullWidth
                  name='nome'
                  disabled={isLoading}
                  label='Nome do Dependente'
                  onChange={e => setDescricao(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <VTextFieldData
                  fullWidth
                  name='nascimento'
                  label='Data de Nascimento'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <VTextFieldData
                  fullWidth
                  name='datadoc'
                  label='Atualização Doc.'
                  disabled={isLoading}
                />
              </Grid>          
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <BasicSelect
                  data={[
                    { nameBD: 'filho(a)', descricao: 'Filho(a)' },
                    { nameBD: 'esposo(a)', descricao: 'Esposo(a)' },
                  ]}
                  label='Tipo de Dependente'
                  name='tipodependente'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                <BasicSelect
                  data={[
                    { nameBD: 'Salario Familia', descricao: 'Salário Família' },
                    { nameBD: 'Imposto de Renda', descricao: 'Imposto de Renda' },
                  ]}
                  label='Tipo'
                  name='tipo'
                  disabled={isLoading}
                />
              </Grid>
              {(id != 'novo') && (
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <VSwitch
                    name='status'
                    label='Status'
                    fdisabled={isLoading}
                  />
                </Grid> 
              )}
            </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};