import {  Box, Button, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid,  LinearProgress,  MenuItem,  Paper, Select, Switch } from '@mui/material';
import { useState } from 'react';
import {  AutoCompleteParceiroListagem } from '../../../shared/forms';
import { RelatorioRomaneiosService } from '../../../shared/services/api/faturamento/romaneios/RRelatorios';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import RRelatorioRomaneioDetalhes from './RRelatorioRomaneioDetalhes';
import RRomaneioResumido from './RRomaneioResumido';
           
export const RelatoriosRomaneios: React.FC = () => {                      
  const [isLoading, setIsLoading] = useState(false);      
  const [uidparceiro, setUidParceiros] = useState('');           
  const [status, setStatus] = useState('todos');    
  const [dataInicial, setDataInicial] = useState<Dayjs | null>(dayjs()); 
  const [dataFinal, setDataFinal] = useState<Dayjs | null>(dayjs()); 
  const [detalhes, setDetalhes] = useState(false);    
      
  const handleSave = () => {              
    RelatorioRomaneiosService                   
      .RRomaneios(status, uidparceiro, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'), detalhes)   
      .then((result) => {           
        setIsLoading(false);         
        if (result instanceof Error) {                
          alert(result.message);             
        }else {   
          if(detalhes){
            RRelatorioRomaneioDetalhes(result, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'));  
          }else{
            RRomaneioResumido(result, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'));  
          }           
        }          
      });    
  };    

  const handleParceiros = (uuidparceiro: string | undefined) => {
    if (uuidparceiro != null) {
      setUidParceiros(uuidparceiro);
    }else{
      setUidParceiros('');
    }
  };

  return (
    <Box   alignItems="center" width='100%' alignContent='center' height="100%"  display="flex" flexDirection="column"  >
      <Box gap={1}   padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='80%' height="20%" alignContent='center'></Box>
      <Box    width='50%' alignItems="center"  component={Paper} height="40%"
        alignContent='center'>       
        <DialogTitle>Relatório de Romaneios</DialogTitle>
        <DialogContent  dividers>
          
          {isLoading && (
            <Grid item>
              <LinearProgress variant='indeterminate' />  
            </Grid>                   
          )}   
          <Grid container  item direction="row" gap={2}>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <AutoCompleteParceiroListagem
                  uuidparceiro={handleParceiros} 
                />             
              </Grid> 
              <Grid item xs={3} sm={3} md={6} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    value={dataInicial}
                    onChange={(newValue) => setDataInicial(newValue)} 
                  />
                </LocalizationProvider>
        
              </Grid>  
              <Grid item xs={3} sm={3} md={6} lg={3} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    value={dataFinal}
                    onChange={(newValue) => setDataFinal(newValue)} 
                  />
                </LocalizationProvider>
              </Grid>   
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Select
                  fullWidth
                  value={status}
                  onChange={(event) => setStatus(event.target.value)}
                >
                  <MenuItem value={'aberto'}>Aberto</MenuItem>
                  <MenuItem value={'abertovencimento'}>Aberto Por Vencimento</MenuItem>
                  <MenuItem value={'fechado'}>Fechado</MenuItem>
                  <MenuItem value={'todos'}>Todos</MenuItem>
                </Select>           
              </Grid>   
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <FormControlLabel
                  labelPlacement="top"
                  control={
                    <Switch 
                      checked={detalhes}
                      onChange={(e) => setDetalhes(e.target.checked)} 
                    />    
                  } 
                  label = 'Detalhes'
                />         
              </Grid>          
            </Grid>        
          </Grid>         
        </DialogContent >     
        <DialogActions>
          <Button variant='outlined' autoFocus onClick={handleSave}>Visualizar</Button>
        </DialogActions>
      </Box>
    </Box>
  );
};   