
import { useEffect, useState } from 'react';
import { LinearProgress, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate} from 'react-router-dom';
import { FerramentasDaListagem } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';

import { Environment } from '../../../shared/environment';

import { useLocation } from 'react-router-dom';
import NavTabs from './NavTabs';
import dayjs from 'dayjs';
import { EventosService, IListagemEventos } from '../../../shared/services/api/rh/colaborador/EventosService';


export const ListagemEventos: React.FC = () => {

  const navigate = useNavigate();
  
  const [rows, setRows] = useState<IListagemEventos[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const location = useLocation();
  const idfun = new URLSearchParams(location.search).get('idfun'); 
  
  
  
  useEffect(() => {
    setIsLoading(true);

    if(idfun !== null){
      EventosService.getAll(idfun)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
          } else {
            
            setRows(result);
          }
        });
    }
  }, [idfun]);
  
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Eventos'
      barraDeFerramentas={
        <FerramentasDaListagem
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate(`/verbaseventos/detalhe/novo/${idfun}`)}
        />
      }
    >
     
        
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <NavTabs
          id={idfun!}  
          case={2}
        />
        
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Descrição</TableCell>
              <TableCell>Valor</TableCell>
              <TableCell>Tipo de Cálculo</TableCell>
              <TableCell>Base de Cálculo</TableCell>
              <TableCell>Tipo de Verba</TableCell>
              <TableCell>Data Expiração</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Fixo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idfun} 
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/verbaseventos/detalhe/${row.ideventos}/${row.idfun}`)}
              >
                <TableCell>{row.descricao}</TableCell>
                <TableCell>{row.valor}</TableCell>
                <TableCell>{row.tipocalculo}</TableCell>
                <TableCell>{row.basecalculo}</TableCell>
                <TableCell>{row.tipoverba}</TableCell>
                <TableCell>{dayjs(row.dataexpiracao).format('DD/MM/YYYY')}</TableCell>
                <TableCell><Switch checked={row.status} /></TableCell>
                <TableCell><Switch checked={row.fixo} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
  
          {rows.length === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
  
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};