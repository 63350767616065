import { Autocomplete, Box, Button, CircularProgress, Icon, MenuItem, Paper, Select, TextField, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useDebounce } from '../../hooks';
import { LocalizacaoService } from '../../services/api/estoque/localizacao/LocalizacaoService';
interface IDadosSelect {
  nameBD: string;
  descricao: string;
}

type TAutoCompleteOption = {
  idlocalizacao: string;
  label: string;
}

interface IFerramentasDaListagemProps {
  dadosSelecao?: IDadosSelect[];
  dataInicial?: string;
  dataFinal?: string;
  mostrarInputData?: boolean;
  mostrarInputLocalizacao?: boolean;
  aoMudarDataInicial?: (novoTexto: string) => void;
  aoMudarDataFinal?: (novoTexto: string) => void;
  aoMudarLocalizacao?: (novoTexto: string) => void;
  textoBotaoNovo?: string;
  mostrarBotaoNovo?: boolean;
  aoClicarEmNovo?: () => void;
  mostrarInputBasicSelect?: boolean
  textoDoSelect?: string;
  aoMudarTextoDoSelect?: (novoTextoselect: string) => void;
}
export const FerramentasDaListagemTransferencia: React.FC<IFerramentasDaListagemProps> = ({
  dataInicial = '',
  aoMudarDataInicial,
  dataFinal = '',
  aoMudarDataFinal,
  aoMudarLocalizacao,
  mostrarInputData = false,
  mostrarInputLocalizacao = false,
  aoClicarEmNovo,
  textoBotaoNovo = 'Novo',
  mostrarBotaoNovo = true,
  dadosSelecao =[],
  mostrarInputBasicSelect = false,
  textoDoSelect = '',
  aoMudarTextoDoSelect,
}) => {
  const theme = useTheme();
  const partesDaDataInicial = dataInicial.split('/');
  const dataInicialFormatada = partesDaDataInicial[2] + '-' + partesDaDataInicial[1] + '-' + partesDaDataInicial[0];

  const partesDaDataFinal = dataFinal.split('/');
  const dataFinalFormatada = partesDaDataFinal[2] + '-' + partesDaDataFinal[1] + '-' + partesDaDataFinal[0];


  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<string | undefined>();

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');

  useEffect(() => {
    setIsLoading(true);

    debounce(() => {
      LocalizacaoService.getAllList( busca)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            // alert(result.message);
          } else {
            setOpcoes(result.data.map(localizacao => ({ idlocalizacao: localizacao.idlocalizacao, label: localizacao.descricao })));
          }
        });
    });
  }, [busca, selectedId]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.idlocalizacao === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);

  return (
    <Box
      gap={1}
      marginX={1}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(5)}
      component={Paper}
      sx={{ height: '60px' }}
    >
      {mostrarInputBasicSelect && (
        <Select
          size="small" sx={{ minWidth: 150, height: '55px' }} 
          value={textoDoSelect}
          onChange={(event) => aoMudarTextoDoSelect?.(event.target.value)}
        >
          {dadosSelecao.map((item) => (
            <MenuItem key={item.nameBD} value={item.nameBD}>
              {item.descricao}
            </MenuItem>
          ))}
        </Select>
      )}

      {mostrarInputLocalizacao && (
        <Autocomplete
          sx={{ minWidth: 250}}  
          openText='Abrir'
          closeText='Fechar'
          noOptionsText='Sem opções'
          loadingText='Carregando...'

          disablePortal

          options={opcoes}
          loading={isLoading}
          value={autoCompleteSelectedOption}
          onInputChange={(_, newValue) => setBusca(newValue)}
          onChange={(_, newValue) => { setSelectedId(newValue?.idlocalizacao); aoMudarLocalizacao?.(newValue?.idlocalizacao || ''); setBusca('');}}
          popupIcon={(isLoading) ? <CircularProgress size={28} /> : undefined}
          renderInput={(params) => (
            <TextField
              {...params}

              label="Origem"
            />
          )}
        />
      )}

      {mostrarInputData && (

        <LocalizationProvider dateAdapter={AdapterDayjs}>
        
          <DatePicker 
            sx={{ minWidth: 150, maxWidth: 150}}
            label="Data Inicial" 
            format='DD/MM/YYYY'
            defaultValue={dayjs()}
            value={dayjs(dataInicialFormatada)}
            onChange={(date: Dayjs | null) => {
              const novoTexto = date ? date.format('DD/MM/YYYY') : '';
              aoMudarDataInicial?.(novoTexto);
            }}
          />

          <DatePicker 
            sx={{ minWidth: 150, maxWidth: 150}}
            label="Data Final" 
            format='DD/MM/YYYY'
            defaultValue={dayjs()}
            value={dayjs(dataFinalFormatada)}
            onChange={(date: Dayjs | null) => {
              const novoTexto = date ? date.format('DD/MM/YYYY') : '';
              aoMudarDataFinal?.(novoTexto);
            }}
          />
      
        </LocalizationProvider>
      )} 
      

      <Box flex={1} display="flex" justifyContent="end">
        {mostrarBotaoNovo && (
          <Button
            color='primary'
            disableElevation
            variant='contained'
            onClick={aoClicarEmNovo}
            endIcon={<Icon>add</Icon>}
          >{textoBotaoNovo}</Button>
        )}
      </Box>
    </Box>
  );
};