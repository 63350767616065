/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import Chart from 'react-apexcharts';

interface GraficoAreaProps {
  series: {
    name: string;
    data: number[];
  }[];
  options?: {
    title?: {
      text?: string;
    };
    colors?: string[];
    dataLabels?: {
      enabled?: boolean;
    };
    theme?: {
      mode?: 'dark' | 'light' | undefined;
    };
    xaxis?: { 
      categories?: string[];
    } 
  };
}

const GraficoBarra: React.FC<GraficoAreaProps> = ({ series, options = {} }) => {
  return (
    <React.Fragment>
      <Chart
        type='bar'
        height={250}
        series={series}
        options={{
          title: {
            text: options.title?.text,
          },
          colors: options.colors,
          dataLabels: { enabled: options.dataLabels?.enabled },
          theme: {
            mode: options.theme?.mode,
          },
          xaxis: { 
            categories: options.xaxis?.categories
          },
          plotOptions:{
            bar:{
              horizontal: true
            }
          }         
        }}
      />
    </React.Fragment>
  );
};

export default GraficoBarra;
