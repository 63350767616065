/*
-> Para usar esse componente importe-o:
import { BasicSelectUF } from '../../../shared/forms';

-> Use dessa forma passando a variável onde o valor da seleção será armazenado, nesse caso é "uf":
  <BasicSelectUF
    name='uf'
    disabled={isLoading}
  />
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useField } from '@unform/core';
import { useEffect } from 'react';


interface BasicSelectProps {
  name: string;
  disabled: boolean;
}

export const BasicSelectUF: React.FC<BasicSelectProps> = (props) => {
  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    clearError(); 
    setAge(event.target.value as string);
  };

  const { fieldName, registerField, error, clearError } = useField(props.name);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => age,
      setValue: (_, newValue) => setAge(newValue),
    });
  }, [registerField, fieldName, age]);

  return (
    <Box >
      <FormControl fullWidth error={!!error}>
        <InputLabel id="demo-simple-select-label">UF</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="UF"
          onChange={handleChange}
          disabled={props.disabled}
        >
          <MenuItem value={'AC'}>AC</MenuItem>
          <MenuItem value={'AL'}>AL</MenuItem>
          <MenuItem value={'AP'}>AP</MenuItem>
          <MenuItem value={'AM'}>AM</MenuItem>
          <MenuItem value={'BA'}>BA</MenuItem>
          <MenuItem value={'CE'}>CE</MenuItem>
          <MenuItem value={'DF'}>DF</MenuItem>
          <MenuItem value={'ES'}>ES</MenuItem>
          <MenuItem value={'GO'}>GO</MenuItem>
          <MenuItem value={'MA'}>MA</MenuItem>
          <MenuItem value={'MT'}>MT</MenuItem>
          <MenuItem value={'MS'}>MS</MenuItem>
          <MenuItem value={'MG'}>MG</MenuItem>
          <MenuItem value={'PA'}>PA</MenuItem>
          <MenuItem value={'PB'}>PB</MenuItem>
          <MenuItem value={'PR'}>PR</MenuItem>
          <MenuItem value={'PE'}>PE</MenuItem>
          <MenuItem value={'PI'}>PI</MenuItem>
          <MenuItem value={'RJ'}>RJ</MenuItem>
          <MenuItem value={'RN'}>RN</MenuItem>
          <MenuItem value={'RS'}>RS</MenuItem>
          <MenuItem value={'RO'}>RO</MenuItem>
          <MenuItem value={'RR'}>RR</MenuItem>
          <MenuItem value={'SC'}>SC</MenuItem>
          <MenuItem value={'SP'}>SP</MenuItem>
          <MenuItem value={'SE'}>SE</MenuItem>
          <MenuItem value={'TO'}>TO</MenuItem>
        </Select>
      </FormControl>
      {error && <div style={{ fontSize: 14,color: '#D32F2F' }}>{error}</div>} 
    </Box>
  );
};
