/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Avatar, Box, Grid, LinearProgress, Paper, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField, VForm, useVForm, IVFormErrors, AutoCompleteFuncionario, VTextFieldData, VFieldMoney, AutoCompletePeriodo, BasicSelect } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { ValeService } from '../../../shared/services/api/rh/vales/ValeService';
import dayjs from 'dayjs';
import { dataextenso } from '../../../shared/services/util/dataextenso';
import { DialogVale } from './DialogVale';
import PDFReciboVale from './ImpressaoVale';
import { Environment } from '../../../shared/environment';

interface IFormData {
  idfun: string;
  tipodesconto: string;
  valor: number;
  realizacao: string;
  historico: string;
  dataemissao: Date;
  periodo: string;
  financeiro: string;
  parcela: string;
  idoperacao: string;
  totalvale: number;
}

interface IFormDataParcelas {
  idfun: string;
  tipodesconto: string;
  valor: number;
  realizacao: string;
  historico: string;
  dataemissao: Date;
  periodo: string;
  financeiro: string;
  parcela: string;
  totalvale: number;
}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'idoperacao' | 'totalvale'>> = yup.object().shape({
  idfun: yup.string().required(),
  tipodesconto: yup.string().required().min(3).max(50),
  valor: yup.number().required().moreThan(0),
  realizacao: yup.string().required().min(1).max(255),
  historico: yup.string().required(),
  dataemissao: yup.date().required(),
  periodo: yup.string().required().min(7).max(40),
  financeiro: yup.string().required().min(3).max(3),
  parcela: yup.string().required(),
});

export const DetalheDeVale: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

  const [isLoading, setIsLoading] = useState(false);
  const [nome, setNome] = useState('');
  const [idfun, setIdfun] = useState('');

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [urlimg, setUrlimg] = useState('');

  const [dadosparcelamento, setDadosParcelamento] = useState<IFormDataParcelas>({} as IFormDataParcelas);

  
  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);

      ValeService.getById(id)
        .then((result) => {
          setIsLoading(false); 

          if (result instanceof Error) {
            alert(result.message);
            navigate('/vales');
          } else {
            setIdfun(result.idfun);
            if (result.urlimg != null) {
              setUrlimg(Environment.URL_IMGFUNCIONARIO+result.urlimg);
            } 
            formRef.current?.setData(result);
            setNome(result.nome);
          }
        });
    } else {
      formRef.current?.setData({
        idfun: '',
        tipodesconto: 'salario',
        valor: 0.00,
        totalvale: 0.00,
        realizacoes: ' ',
        historico: '',
        periodo: dataextenso(new Date()),
        financeiro: 'sim',
        dataemissao: dayjs(),
        parcela: '1',
      });
    }  
  }, [id]);

  const handlefoto = (urlimg: string | undefined) => {
    if (urlimg != null) {
      setUrlimg(Environment.URL_IMGFUNCIONARIO+urlimg);
    }
  };

  const handleSave = (dados: IFormData) => {
    if (id !== 'novo') {
      dados.idfun = idfun;
    }
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          if (Number(dados.parcela) > 1){
            const total = dados.valor;
            setDadosParcelamento(dados);
            setOpen(true);
          }else{
            ValeService
              .create(dadosValidados)
              .then((result) => {
                setIsLoading(false);
                if (result instanceof Error) {
                  alert(result.message);
                } else {
                  if (isSaveAndClose()) {
                    handlePrint(result);  
                    navigate('/vales');
                  } else {
                    handlePrint(result); 
                    navigate(`/vale/detalhe/${result}`);
                  }
                }
              });
          }
        } else {
          ValeService
            .updateById(id, dados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/vales');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {

        const validationErrors: IVFormErrors = {};
        
        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      ValeService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/vales');
          }
        });
    }
  };

  const handlePrint = (id: string) => {
    ValeService.getByIdPrint(id)
      .then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
        } else { 
          PDFReciboVale(result);
        }
      });
  };

  const handleClose = (newValue?: string) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };
  
  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Novo Vale' : ''}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando={isLoading}
          mostrarBotaoSalvarEFecharCarregando={isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoImpressao={id !== 'novo'}
          mostrarBotaoApagarCarregando={isLoading}
          mostrarBotaoImpressaoCarregando={isLoading}

          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/vales')}
          mostrarBotaoVoltarCarregando={isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmImpressao={() => handlePrint(id)}
          aoClicarEmNovo={() => navigate('/vale/detalhe/novo')}
          mostrarBotaoNovoCarregando={isLoading}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={1} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid item>
              <Typography variant='h6'>Geral</Typography> 
            </Grid>
            <Grid container item direction="row" spacing={3}>
              <Grid container item direction="row" spacing={2} >
                <Grid item xs={4} sm={4} md={2} lg={2} xl={1} >
                  <Box width="100%" marginRight={5} >
                    <Avatar
                      variant='rounded'
                      alt='F'
                      src={urlimg}
                      sx={{ width: '100%', height: 150 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container item direction="row" spacing={2} >
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        {id === 'novo' ? (
                          <AutoCompleteFuncionario 
                            isExternalLoading={id === 'novo' ? isLoading : true}
                            urlfoto={handlefoto}
                          />
                        ) : (
                          <TextField
                            fullWidth
                            value={nome}
                            label='Nome do Funcionario'
                            disabled={true}
                          />
                        )}                        
                      </Grid>
                      <Grid item xs={8} sm={8} md={6} lg={6} xl={2}>
                        <AutoCompletePeriodo isExternalLoading={isLoading} />
                      </Grid>
                      <Grid item xs={4} sm={4} md={2} lg={6} xl={2}>
                        {id === 'novo' ? (
                          <BasicSelect
                            name='parcela'
                            label='Parcela'
                            data={[
                              { nameBD: '1', descricao: '1' },
                              { nameBD: '2', descricao: '2' },
                              { nameBD: '3', descricao: '3' },
                              { nameBD: '4', descricao: '4' },
                              { nameBD: '5', descricao: '5' },
                              { nameBD: '6', descricao: '6' },
                              { nameBD: '7', descricao: '7' },
                              { nameBD: '8', descricao: '8' },
                              { nameBD: '9', descricao: '9' },
                              { nameBD: '10', descricao: '10' },
                            ]}
                            disabled={isLoading}
                          />
                        ) : (
                          <VTextField
                            fullWidth
                            name='parcela'
                            label='Parcela'
                            disabled={true}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={6} xl={2}>
                        <VFieldMoney
                          fullWidth
                          name='valor'
                          label='Valor'
                          disabled={id === 'novo' ? isLoading : true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container item direction="row" marginTop={0} spacing={2} >
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <VTextField
                          fullWidth
                          name='realizacao'
                          label='Realizaçoes'
                          disabled={isLoading}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                        <BasicSelect
                          name='financeiro'
                          label='Financeiro'
                          data={[
                            { nameBD: 'sim', descricao: 'Sim' },
                            { nameBD: 'nao', descricao: 'Não' },
                          ]}
                          disabled={id === 'novo' ? isLoading : true}
                        />                      
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                        <BasicSelect
                          name='tipodesconto'
                          label='Forma Desconto'
                          data={[
                            { nameBD: 'salario', descricao: 'Salario' },
                            { nameBD: 'ferias', descricao: 'Feiras' },
                            { nameBD: '13salario', descricao: '13º Salario' },
                          ]}
                          disabled={isLoading}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                        <VTextFieldData
                          fullWidth
                          name='dataemissao'
                          label='Data Lancamento'
                          disabled={id === 'novo' ? isLoading : true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={11}>
                <VTextField
                  fullWidth
                  name='historico'
                  label='Historico'
                  multiline
                  rows={4}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>

            <DialogVale 
              dados={dadosparcelamento}
              value={''}  
              open={open} 
              onClose={handleClose}
            /> 
          </Grid>
        </Box>
      </VForm>

    </LayoutBaseDePagina>
  );
};