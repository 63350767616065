import {  Box, Button, DialogActions, DialogContent, DialogTitle,  Grid, LinearProgress,  Paper } from '@mui/material';
import { useState } from 'react';
import {  AutoCompletePeriodo,  IVFormErrors, VForm, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { RelatorioService } from '../../../shared/services/api/rh/Relatorios/RelatoriosService';
import RelatorioFaltaPorCei from './RelatorioFaltaPorCei';

interface IFomeTipoPgto {
  periodo: string;
}

const formValidationSchema: yup.ObjectSchema<IFomeTipoPgto> = yup.object().shape({
  periodo: yup.string().required(),
});
      
export const FaltaPorCei: React.FC = () => {          
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);         
  const [periodo, setPeriodo] = useState('');
              
  const handleSave = (dados: IFomeTipoPgto) => {                               
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        RelatorioService
          .RelatorioFaltaPorCei(dadosValidados.periodo)   
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else 
            {
              RelatorioFaltaPorCei(result, periodo);
            }
          });
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};   

        errors.inner.forEach(error => {   
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });            
  };      
  
  const handlePeriodo = (periodos: string | undefined) => {    
    if (periodos != null) {     
      setPeriodo(periodos);                  
    }else{                       
      setPeriodo('');                         
    }                 
  }; 
  
  return (
    <VForm ref={formRef} onSubmit={handleSave}>
      <Box  alignItems="center" width='100%' alignContent='center' height="100%"  display="flex" flexDirection="column"  >
        <Box gap={1}  padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='80%' height="20%" margin={1}  alignContent='center'></Box>
        <Box   width='20%' alignItems="center"  component={Paper} height="35%" margin={1} 
          alignContent='center'>       
          <DialogTitle>Falta Por CEI</DialogTitle>
          <DialogContent  dividers>    
            {isLoading && (           
              <Grid item>  
                <LinearProgress variant='indeterminate' />  
              </Grid>                      
            )}   
            <Grid container  padding={1} item direction="row" gap={2}>
              <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <AutoCompletePeriodo 
                    isExternalLoading={isLoading}  
                    novoperiodo={handlePeriodo}
                  />
                </Grid> 
              </Grid>        
            </Grid>         
          </DialogContent>     
          <DialogActions>
            <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={save}>Visualizar</Button>
          </DialogActions>
        </Box>
      </Box>
    </VForm> 
  );
};   