import { Box, Button, Grid, Icon, IconButton, Paper, TextField, Typography, useTheme } from '@mui/material';
import { AutoCompletePeriodo01 } from '../../forms';
import SearchIcon from '@mui/icons-material/Search';
import { Environment } from '../../environment';
import { useState } from 'react';

interface IFerramentasDaListagemProps {
  textoTitulo?: string; 
  mostrarTextoTitulo?: boolean;
  textoDaBusca?: string;
  mostrarInputBusca?: boolean;
  textoBotaoNovo?: string;
  mostrarBotaoNovo?: boolean;
  aoClicarEmNovo?: () => void;
  aoMudarTextoDeBusca?: (novoTexto: string) => void;
  aoClicarEmPesquisa?: (novoPeriodo: string) => void;
  aoMudarPeriodo?: (novoTextoDoStatus: string) => void;
}
export const FerramentasDaListagemFolha: React.FC<IFerramentasDaListagemProps> = ({
  textoDaBusca = '',
  textoTitulo = '',
  aoMudarPeriodo, 
  mostrarTextoTitulo = false,
  aoClicarEmNovo,
  textoBotaoNovo = 'Novo',
  mostrarBotaoNovo = true,
  aoClicarEmPesquisa,
  aoMudarTextoDeBusca,
}) => {
  const theme = useTheme();
  const [periodo, setPeriodo] = useState(''); 

  const handlPeriodo = (fperiodo: string) => {
    if (fperiodo != null) {
      const ames = fperiodo.slice(0, -5);         
      const aano = fperiodo.slice(-4);
      setPeriodo(`${ames}/${aano}`);  
      aoMudarPeriodo?.(`${ames}/${aano}`);
    }       
  };

  const handlePesquisa = () => {
    aoClicarEmPesquisa?.(periodo);
  };

  return (
    <Box
      gap={1}
      marginX={1}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(8)}
      component={Paper}
    >
      {mostrarTextoTitulo && (
        <Typography
          component="span"
          sx={{
            fontFamily: 'Arial, sans-serif',
          }}
        >
          {textoTitulo}
        </Typography>
      )}
      <Grid container item direction="row" spacing={2}>  
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container item direction="row">  
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}> 
              <AutoCompletePeriodo01 isExternalLoading={false} novoperiodo={handlPeriodo} />
            </Grid>   
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>  
              <IconButton color='primary'>
                <SearchIcon sx={{ fontSize: 45 }} onClick={() => {handlePesquisa();}} />
              </IconButton>
            </Grid>   
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} marginTop={1}>  
              <TextField
                size="small"
                value={textoDaBusca}
                placeholder={Environment.INPUT_DE_BUSCA}
                onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
              />
            </Grid>   
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} marginTop={2}> 
              <Box flex={1} display="flex" justifyContent="end">
                {mostrarBotaoNovo && (
                  <Button
                    color='primary'
                    disableElevation
                    variant='contained'
                    onClick={aoClicarEmNovo}
                    endIcon={<Icon>add</Icon>}
                  >{textoBotaoNovo}</Button>
                )}
              </Box>
            </Grid>    
          </Grid>   
        </Grid>   
      </Grid>   
    </Box>
  );
};