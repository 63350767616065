
import { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField,  VForm, useVForm, IVFormErrors, VSwitch, VTextFieldNumber } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { ContaBancariaService } from '../../../shared/services/api/cadastros/contaBancaria/ContaBancariaService';


interface IFormData {
  nomebanco: string;
  agencia: string;
  numeroconta: string;
  status: boolean;
  titular: string;
  ir: boolean;
  banco: string;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  nomebanco: yup.string().required(),
  agencia: yup.string().required(),
  numeroconta: yup.string().required(),
  status: yup.boolean().optional().default(true),
  titular: yup.string().required(),
  ir: yup.boolean().required(),
  banco: yup.string().required(),
});


export const DetalheDeContaBancaria: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      
      ContaBancariaService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/contabancaria');
          } else {
            setDescricao(result.titular);
            formRef.current?.setData(result);
          }
        });
    } else {
      formRef.current?.setData({
        nomebanco: '',
        agencia: '',
        numeroconta: '',
        status: true,
        titular: '',
        ir: false,
        banco: '',
      });
    }
  }, [id]);

  
  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          
          ContaBancariaService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
                console.log(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/contabancaria');
                } else {
                  navigate(`/contabancaria/detalhe/${result}`);
                }
              }
            });
        } else {
          ContaBancariaService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/contabancaria');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };


  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      ContaBancariaService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/contabancaria');
          }
        });
    }
  };


  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Nova Conta Bancária' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvar={id !== 'novo'}
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao = {false}
 
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/contabancaria')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/contabancaria/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <VTextField
                  fullWidth
                  name='nomebanco'
                  disabled={isLoading}
                  label='Banco'
                />
              </Grid> 
              <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>          
                <VTextFieldNumber
                  fullWidth
                  name='banco'
                  disabled={isLoading}
                  label='Nº do Banco'
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>          
                <VTextField
                  fullWidth
                  name='agencia'
                  disabled={isLoading}
                  label='Agência'
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={2} xl={4}>          
                <VTextField
                  fullWidth
                  name='numeroconta'
                  disabled={isLoading}
                  label='Nº da Conta'
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>          
                <VTextField
                  fullWidth
                  name='titular'
                  disabled={isLoading}
                  label='Titular'
                  onChange={e => setDescricao(e.target.value)}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={2} lg={2} xl={1}>
                <VSwitch
                  name='ir'
                  label='IR'
                  fdisabled={isLoading}/>
              </Grid> 
              <Grid item xs={3} sm={3} md={2} lg={2} xl={1}>
                {(id != 'novo') && (
                  <VSwitch
                    name='status'
                    label='Ativo'
                    fdisabled={isLoading}/>
                )}
              </Grid> 
            </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};