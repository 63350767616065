import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { AutoCompleteProduto, BasicSelect, IVFormErrors, VFieldMoney, VForm, VTextField, VTextFieldData, VTextFieldNumber, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { SaidaService } from '../../../shared/services/api/estoque/saida/SaidaService';
import { Dayjs } from 'dayjs';

interface IFormData {
  iditens: string;
  idprod: string;
  qtdsaida: number; 
  validade?: string | null;
  un: string;
  qtdentrada: number;
  conversor: number;
  preco: number;
  total: number;

  referencia: string;
  qtdestoque: number;
  filtro: string;
  descricao: string;
  reqvencimento: boolean;
}

export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  status: boolean;
  dados?: IFormData;
  onDados: (dados: IFormData) => void;
  onClose: (value?: string) => void;
}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'preco' | 'total' | 'qtdentrada' | 'iditens' | 'descricao'  | 'filtro' | 'referencia'>> = yup.object().shape({
  reqvencimento: yup.boolean().optional().default(false),
  idprod: yup.string().required(),
  validade: yup.string().nullable().when('reqvencimento', {
    is: true,
    then: () => yup.date().nullable().required('Campo obrigatório').test('valid-date', 'Selecione uma data válida', function (value) {
      const currentDate = new Date();
      if (!value || value < currentDate) return false; 
        
      return true;
    })
  }),
  qtdsaida: yup.number().required().test('qtdsaida', 'A quantidade de saída deve ser menor ou igual ao estoque e diferente de zero', function (value, context) {
    const qtdestoque = context.parent.qtdestoque || 0; 
    return value <= qtdestoque && value !== 0;
  }),
  qtdestoque: yup.number().required(),
  un: yup.string().required(),
  conversor: yup.number().required().moreThan(0),
});

export const DialogSaidaOS: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {
  const {formRef, save, } = useVForm();
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const [isLoading, setIsLoading] = useState(false);  
  const [id, setId] = useState('');
  const guuid = uuidv4();
  const [idprod, setIdprod] = useState<string>();
  const [validade, setValidade] = useState<Dayjs | null>(null);

  const [preco, setPreco] = useState(0);
  const [total, setTotal] = useState(0);

  const itensnovo  = {
    filtro: 'descricao',
    idprod: '',
    idlocalizacao: '',
    validade: null,
    qtdsaida: '',
    un: '',
    conversor: 1,
    preco: 0,
    total: 0,
    qtdestoque: 0,
  };
  

  useEffect(() => {
    if(props.id === 'novo'){
      formRef.current?.setData(itensnovo);
      setIdprod(undefined);
      setValidade(null);
    }else{
      
      if (props.dados && typeof props.dados === 'object') {
        setPreco(props.dados?.preco || 0);
        formRef.current?.setData(props.dados); 
        const idprod = props.dados.idprod;
        const validade = props.dados.validade;
        setIdprod(props.dados.idprod);

        if(props.dados.validade !== null){
          SaidaService
            .estoqueQuantidadeFaz({idprod, vencimento: true, validade})
            .then((result) => {
              if (result instanceof Error) {
                alert(result.message);
              }else{
                formRef.current?.setFieldValue('qtdestoque', result.quantidade);
              }
            });
        }else{
          SaidaService
            .estoqueQuantidadeFaz({idprod, vencimento: false, validade})
            .then((result) => {
              if (result instanceof Error) {
                alert(result.message);
              }else{
                formRef.current?.setFieldValue('qtdestoque', result.quantidade);
              }
            });
        }
      }
    }
  }, [id, formRef]); 

  const handleProduto = (valor: string | undefined) => {
    formRef.current?.setFieldValue('descricao', valor);
  }; 

  const [referencia, setReferencia] = useState('');
  const handleReferencia = (valor: string | undefined) => {
    formRef.current?.setFieldValue('referencia', valor);
    if(valor !== '' || undefined || null){
      setReferencia(valor!);
    }
  }; 

  const handleValorSaida = (valor: number | undefined) => {
    const data = formRef.current?.getData();
    const qtdsaida = data?.qtdsaida;
    const conversor = data?.conversor;
    const tvalor = ((Number(qtdsaida) * Number(conversor)) * Number(valor));
    setPreco(valor || 0);
    setTotal(tvalor);
  };

  const handleQtd = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\./g, '');
      valor = valor.replace(/,/g, '.');
      valor = valor.replace(/(\d)(\d{4})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, ''); 
      const data = formRef.current?.getData();
      const conversor = data?.conversor;
      const tvalor = ((Number(valor) * Number(conversor)) * preco);
      setTotal(tvalor);
    }
  }; 

  const handleConversor = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\./g, '');
      valor = valor.replace(/,/g, '.');
      valor = valor.replace(/(\d)(\d{4})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, ''); 
      const data = formRef.current?.getData();
      const qtdsaida = data?.qtdsaida;
      const tvalor = ((Number(valor) * Number(qtdsaida)) * preco);
      setTotal(tvalor);
    }
  }; 
  
  const [reqvencimento, setReqvencimento] = useState<boolean | undefined>();
  const handleVencimento = (valor: boolean | undefined) => {
    setReqvencimento(valor);
    if(valor === false){
      formRef.current?.setFieldValue('validade', null);
    }
  }; 
      
  const handleSave = (dados: IFormData) => {

    const DadosValidados = {...dados, reqvencimento: reqvencimento};
    
    formValidationSchema.
      validate(DadosValidados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if(props.id === 'novo'){
          props.onDados({iditens: guuid, qtdentrada: 0, descricao: '', filtro:'', referencia: referencia, preco: preco, total: total, ...dadosValidados});
          onClose(value);
          setIsLoading(false);
        }else{
          props.onDados({iditens: props.id, qtdentrada: 0, descricao: '', filtro:'', referencia: referencia, preco: preco, total: total, ...dadosValidados});
          onClose(value);
          setIsLoading(false);
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};     
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleCancel = () => {
    onClose();
    setId('');
  };

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }else{
      setId(props.id);
    }
  }, [valueProp, open]);

  const [filtro, setFiltro] = useState('descricao');
  const handleFiltro = (valor: string | undefined) => {
    if (valor != null) {
      setFiltro(valor);
    }
  }; 

  const handleuuidproduto = (uuid: string | undefined) => {
    formRef.current?.setFieldValue('qtdestoque', 0);
    setIdprod(uuid!);
  };
  
  const handlevalidade = (data: Dayjs | null) => {
    setValidade(data);
  };

  useEffect(() => {
    if(idprod !== '' && idprod !== undefined  && reqvencimento === false){
      SaidaService
        .estoqueQuantidadeFaz({idprod, vencimento: reqvencimento!, validade})
        .then((result) => {
          if (result instanceof Error) {
            alert(result.message);
          }else{
            formRef.current?.setFieldValue('qtdestoque', result.quantidade);
          }
        });
    }else if(idprod !== '' && idprod !== undefined && reqvencimento === true){
      if(validade !== null){
        SaidaService
          .estoqueQuantidadeFaz({idprod, vencimento: reqvencimento!, validade})
          .then((result) => {
            if (result instanceof Error) {
              alert(result.message);
            }else{
              formRef.current?.setFieldValue('qtdestoque', result.quantidade);
            }
          });
      }
    }
  }, [idprod, validade]);

  return (
    <Dialog
      open={open}
      {...other}
      sx={{ width: '100%'  }}
      maxWidth="md"
    >
      <DialogTitle>Itens</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column" padding={1} >
            <Grid container item direction="row" padding={1} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} >
                  <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                    <AutoCompleteProduto
                      isExternalLoading={props.status ? isLoading : true}
                      descricao={handleProduto}
                      vencimento={handleVencimento}
                      referencia={handleReferencia} 
                      precoSaida={handleValorSaida}
                      idproduto={handleuuidproduto}
                      combustivel='false'
                      filtro={filtro}
                    />  
                  </Grid> 
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <BasicSelect
                      name='filtro'
                      label='Filtro'
                      aoClicar={handleFiltro}
                      data={[
                        { nameBD: 'descricao', descricao: 'Descrição' },
                        { nameBD: 'codbarrasaida', descricao: 'Código de Barras Saída' },
                        { nameBD: 'referencia', descricao: 'Referênca' },
                      ]}
                      disabled={props.status ? isLoading : true}
                    />           
                  </Grid>
                  <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                    <VTextField
                      fullWidth
                      name='descricao'
                      label='Ítem'
                      disabled={true}
                    />   
                  </Grid>  
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VTextField
                      fullWidth
                      name='referencia'
                      label='Referência'
                      disabled={true}
                    /> 
                  </Grid>    
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VTextFieldData
                      fullWidth
                      name='validade'
                      label='Data de Validade'
                      disabled={!reqvencimento || !props.status}
                      dataSelecionada={handlevalidade}
                    />
                  </Grid>  
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VFieldMoney
                      fullWidth
                      name='qtdestoque'
                      label='Quantidade em Estoque'
                      disabled={true}
                    />
                  </Grid>       
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VFieldMoney
                      fullWidth
                      name='qtdsaida'
                      label='Quantidade da Saída'
                      calcular={handleQtd}
                      disabled={props.status ? isLoading : true}
                    />
                  </Grid>   
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VTextField
                      fullWidth
                      name='un'
                      label='Unidade de Medida'
                      disabled={props.status ? isLoading : true}
                    /> 
                  </Grid>   
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <VTextFieldNumber
                      fullWidth
                      name='conversor'
                      label='Conversor'
                      calcular={handleConversor}
                      disabled={props.status ? isLoading : true}
                    />
                  </Grid>  
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button variant='outlined' disabled={props.status ? isLoading : true} autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
}; 