import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper,  Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LayoutBaseDePagina, } from '../../../shared/layouts';
import { Environment } from '../../../shared/environment';
import dayjs, { Dayjs } from 'dayjs';
import { FerramentasDaListagemFinanceiroEntreContas } from '../../../shared/components';
import { IListagem, LancamentoEntreConstasService } from '../../../shared/services/api/Financeiro/LancamentoEntreConstas';

export const ListagemEntreContas: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = useState<IListagem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [dataInicial, setDataInicial] = useState(''); 
  const [dataFinal, setDataFinal] = useState(''); 
  const navigate = useNavigate();
     
  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);  
  
  useEffect(() => {
    if(dataInicial !== ''){
      setIsLoading(true);  
      LancamentoEntreConstasService.getAll(pagina, dataInicial, dataFinal)
        .then((result) => {
          setIsLoading(false);  
          if (result instanceof Error) {
            alert(result.message);
          } else { 
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
          
        });
    }
  }, [pagina]);  
 

  const handlePesquisa = (datainicial: Dayjs | null, datafinal: Dayjs | null) => {
    const formattedDateInicial = datainicial ? dayjs(datainicial).format('DD/MM/YYYY') : '';
    const formattedDateFinal = datafinal ? dayjs(datafinal).format('DD/MM/YYYY') : '';
    setDataInicial(formattedDateInicial);
    setDataFinal(formattedDateFinal);
    setIsLoading(true);  
    LancamentoEntreConstasService.getAll(1, formattedDateInicial, formattedDateFinal)
      .then((result) => {
        setIsLoading(false);  
        if (result instanceof Error) {
          alert(result.message);
        } else { 
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });
  };  
 
  return (
    <LayoutBaseDePagina
      titulo='Lançamento Entre Contas'
      barraDeFerramentas={
        <FerramentasDaListagemFinanceiroEntreContas
          mostrarInputData={true}
          aoClicarEmPesquisa={handlePesquisa}
          aoClicarEmNovo={() => navigate('/lancamentoentrecontas/detalhe/novo')}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto', marginRight: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ maxWidth: 150, width:150 }} align='center'>Data Lançamento</TableCell>
              <TableCell align='left' >Nº Documento</TableCell>
              <TableCell align='left' >Conta Credito</TableCell>
              <TableCell align='left' >Conta de Debito</TableCell>
              <TableCell align='right' >Valor</TableCell>
            </TableRow>
          </TableHead> 
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idlan} 
                hover
                sx={{ cursor: 'pointer' }}  
                onClick={() => navigate(`/lancamentoentrecontas/detalhe/${row.idlan}`)}
              >
                <TableCell align='center'>{dayjs(row.datalan).format('DD/MM/YYYY')}</TableCell>
                <TableCell sx={{ maxWidth: 110, width:110 }} align='left'>{row.numero}</TableCell>
                <TableCell align='left'>{row.entrada}</TableCell>
                <TableCell align='left'>{row.saida}</TableCell>
                <TableCell align='right'>{row.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
              </TableRow>
            ))}
          </TableBody> 
   
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination  
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  ); 
};