/* eslint-disable @typescript-eslint/no-explicit-any */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
interface ContasPagar {
  dataemissao: Date;
  vencimento: Date;
  nome: string;
  cpf: string;
  historico: string;
  fazenda: string;
  numerodoc: string;
  valorparcela: number;
  acrescimo: number;
  valorpago: number;
  desconto: number; 
  valorpagar: number;
}

async function RelatorioContasPagar(dados: ContasPagar[], periodo: string){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const TValorpagar = dados.reduce((acc, item) => acc + item.valorpagar, 0);
  const TPago = dados.reduce((acc, item) => acc + item.valorpago, 0);
  const TDesconto = dados.reduce((acc, item) => acc + item.desconto, 0);
  const TAcrescimo = dados.reduce((acc, item) => acc + item.acrescimo, 0);
  const TParcela = dados.reduce((acc, item) => acc + item.valorparcela, 0);
  const contapagar = dados.map((pagar) => {
    return [
      {text: moment(pagar.vencimento).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 8},
      {text: pagar.numerodoc, alignment: 'left',  fontSize: 7},
      {text: pagar.nome+' \n CPF/CNPJ: '+pagar.cpf , alignment: 'left',  fontSize: 7},
      {text: pagar.fazenda, alignment: 'left',  fontSize: 7},
      {text: pagar.historico, alignment: 'left',  fontSize: 7},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(pagar.valorparcela), alignment: 'right', fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(pagar.acrescimo), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(pagar.desconto), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(pagar.valorpago), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(pagar.valorpagar), alignment: 'right',  fontSize: 8},
    ]; 
  });  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }


  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [10, 10, 20, 10],
    pageOrientation: 'landscape',  
    header: 'simple text',
    footer: {
      columns: [
        'Left part',
        { text: 'Right part', alignment: 'right' }
      ]
    }, 
    content: ([
      {   
        table: {
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]  
              },
              {
                border: [false, false, false, false],  
                table: {
                  widths: [720],
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                  ]
                }
              },
              
            ],
            [
              {text: 'Relatório de Contas a Pagar', colSpan: 2, margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
              {}
            ]
            
          ]
        }
      },
      {text: periodo,  fontSize: 8},
      {
        table:{
          widths: [45, 48, 130, 70, 200, 50, 32, 35, 40, 47],
          body: [
            [
              {text: 'Vencimento', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Número', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Parceiro', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Fazenda', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Histórico', alignment: 'center', fillColor: '#dddddd', fontSize: 8},
              {text: 'Valor Parcela', alignment: 'right', fillColor: '#dddddd',  fontSize: 8},
              {text: 'Acrés.', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
              {text: 'Desconto', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
              {text: 'Valor Pago', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
              {text: 'Valor Pagar', alignment: 'right', fillColor: '#dddddd', fontSize: 8},
            ],
            ...contapagar,
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: 'Totais',  fontSize: 8, alignment: 'right',  border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(TParcela), alignment: 'right', fontSize: 7},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(TAcrescimo), alignment: 'right', fontSize: 7},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(TPago), alignment: 'right', fontSize: 7},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(TDesconto), alignment: 'right', fontSize: 7},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(TValorpagar), alignment: 'right', fontSize: 7},
            ]
          ]
          
        },
      },
      
    ]),
      
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default RelatorioContasPagar;

