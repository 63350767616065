/* eslint-disable @typescript-eslint/no-unused-vars */
import {  Box, Button, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress,  Paper } from '@mui/material';
import { useState } from 'react';
import {  AutoCompleteFazenda,  AutoCompletePeriodo, BasicSelect, IVFormErrors, VForm, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { FolhaService } from '../../../shared/services/api/rh/folha/FolhaService';
import recibos from '../folha/Impressaorecibos';

interface IFomeTipoPgto {
  periodo: string;
  idfazenda: string;
  tipopagamento: string;
}

const formValidationSchema: yup.ObjectSchema<IFomeTipoPgto> = yup.object().shape({
  tipopagamento: yup.string().required(),
  periodo: yup.string().required(),
  idfazenda: yup.string().required(),
});    
          
export const ReciboFolha: React.FC = () => {          
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);         
                  
  const handleSave = (dados: IFomeTipoPgto) => {                    
    formValidationSchema.
      validate(dados, { abortEarly: false })   
      .then((dadosValidados) => {
        setIsLoading(true);
        FolhaService
          .ImprimirRecibo(dadosValidados.periodo, dadosValidados.idfazenda, dadosValidados.tipopagamento)   
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else 
            { 
              recibos(result);
            }
          });       
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};   
        errors.inner.forEach(error => {   
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });            
  };          
  
  return (
    <VForm ref={formRef} onSubmit={handleSave}>
      <Box  alignItems="center" width='100%' alignContent='center' height="100%"  display="flex" flexDirection="column"  >
        <Box gap={1}  padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='80%' height="20%" margin={1}  alignContent='center'></Box>
        <Box   width='40%' alignItems="center"  component={Paper} height="35%" margin={1} 
          alignContent='center'>       
          <DialogTitle>Recibo Pagamento</DialogTitle>
          <DialogContent  dividers>    
            {isLoading && (           
              <Grid item>  
                <LinearProgress variant='indeterminate' />  
              </Grid>                      
            )}   
            <Grid container  padding={1} item direction="row" gap={2}>
              <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <AutoCompleteFazenda 
                    isExternalLoading={isLoading}  />
                </Grid> 
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>              
                  <BasicSelect
                    data={[   
                      { nameBD: 'cheque', descricao: 'Cheque' },                  
                      { nameBD: 'deposito', descricao: 'Depósito' },   
                      { nameBD: 'dinheiro', descricao: 'Dinheiro' },    
                    ]}
                    label='Tipo Pagamento'
                    name='tipopagamento'                                                   
                    disabled={isLoading}                                  
                  />           
                </Grid>                    
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                  <AutoCompletePeriodo 
                    isExternalLoading={isLoading}  />
                </Grid> 
              </Grid>        
            </Grid>         
          </DialogContent>     
          <DialogActions>
            <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={save}>Visualizar</Button>
          </DialogActions>
        </Box>
      </Box>
    </VForm> 
  );
};   