/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { IVFormErrors, VForm, VTextField, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { NfeService } from '../../../shared/services/api/fiscal/nfe/NfeService';

interface IFormData {
  justificativa: string;
}

export interface ConfirmationDialogRawProps {
    id: string;
    open: boolean;
    status: number;
    onClose: (value?: string) => void;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  justificativa: yup.string().required().min(15),
});


export const DialoCancelar: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const {formRef, save, } = useVForm();
  const { onClose,  open, ...other } = props;
  const [isLoading, setIsLoading] = useState(false);  


  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        NfeService.cancelarnfe(props.id, dadosValidados)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              onClose();
            }
          });
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};     
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleCancel = () => {
    onClose();
  };
  
  return (
    <Dialog 
      open={open}
      {...other}
      maxWidth='xl'
      fullWidth sx={{ m: 0.1, maxWidth: '100%', }}
    >
      <DialogTitle>Cancelar Nota Fiscal</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef}  onSubmit={handleSave}>
          <Grid container direction="column" padding={1} >
            <Grid container item direction="row" padding={1} spacing={1}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={12}> 
                <VTextField
                  fullWidth
                  name='justificativa' 
                  label='Justificativa'
                  disabled={isLoading}
                />
              </Grid>  
            </Grid>  
          </Grid>  
        </VForm>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
};