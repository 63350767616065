/* eslint-disable @typescript-eslint/no-explicit-any */
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
interface IRNFE {
  serie: number;
  nfe: number;
  dataemissao: Date; 
  nome: string;
  cpf: string; 
  cfop: number; 
  vnfe: number;
  finalidade: string; 
  status: string; 
}

async function Relatorionfe(dados: IRNFE [], produtor: string, periodo: string){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const TTotal = dados.reduce((acc, item) => acc + item.vnfe, 0);
  const rows = dados.map((row) => {
    return [
      {text: moment(row.dataemissao).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 7},
      {text: row.serie, alignment: 'right',  fontSize: 7},
      {text: row.nfe, alignment: 'right',  fontSize: 7},
      {text: row.nome, alignment: 'left',  fontSize: 7},
      {text: (row.cpf.length == 11 ? (
        row.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')  
      ) : (
        row.cpf.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      )), fontSize: 7, alignment: 'left', }, 
      {text: row.finalidade, alignment: 'left',  fontSize: 7},    
      {text: row.cfop, alignment: 'left',  fontSize: 7},
      {text: row.status, alignment: 'left',  fontSize: 7},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(row.vnfe), alignment: 'right',  fontSize: 7},
    ]; 
  });  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous';   
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }


  const documentDefinition : any = {
    pageSize: 'A4',
    pageMargins: [15, 10, 10, 10],
    //pageOrientation: 'landscape',  
    header: 'simple text',
    footer: {
      columns: [
        'Left part',
        { text: 'Right part', alignment: 'right' }
      ]
    },
    content: ([
      {   
        table: {
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],  
                table: {
                  widths: [500],
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: '#FFFFFF',  alignment: 'center', fillColor: process.env.REACT_APP_URL_EMPRESA, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                  ]
                }
              },
              
            ],
            [
              {text: 'Relatório Notas Fiscais', colSpan: 2, margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
              {}
            ]
            
          ]
        }
      },
      {text: 'Produtor: ' + produtor, alignment: 'center',  fontSize: 13},
      {text: periodo,  fontSize: 8},
      {  
        table:{
          widths: [43, 20, 25, 150, 70, 55, 23, 50, 45],
          body: [
            [
              {text: 'Emissão', alignment: 'center', fillColor: '#dddddd', fontSize: 7},
              {text: 'Serie', alignment: 'center', fillColor: '#dddddd', fontSize: 7},
              {text: 'Número', alignment: 'center', fillColor: '#dddddd', fontSize: 7},
              {text: 'Parceiro', alignment: 'center', fillColor: '#dddddd', fontSize: 7},
              {text: 'CNPJ/CPF', alignment: 'center', fillColor: '#dddddd', fontSize: 7},
              {text: 'Finalidade', alignment: 'left', fillColor: '#dddddd',  fontSize: 7},
              {text: 'CFOP', alignment: 'center', fillColor: '#dddddd', fontSize: 7},
              {text: 'Status', alignment: 'left', fillColor: '#dddddd', fontSize: 7},
              {text: 'Valor', alignment: 'right', fillColor: '#dddddd', fontSize: 7},
            ],
            ...rows,
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: 'Total',  fontSize: 8, alignment: 'right',  border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(TTotal), alignment: 'right', fontSize: 7},
            ]
          ]
        },
      },
      
    ]),
      
  };
  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  pdfDocGenerator.open();
}
export default Relatorionfe;

