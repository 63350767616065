/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,  Grid,  LinearProgress, Paper } from '@mui/material';
import { AutoCompleteContaBancaria } from '../../../shared/forms/AutoCompleteContaBancaria';
import { IVFormErrors, VFieldMoney, VForm, VSwitch, VTextField, VTextFieldData, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { ContasPagarService, IListagemContasPagar } from '../../../shared/services/api/Financeiro/ContasPagar';

export interface ConfirmationDialogRawProps {
    value: string;
    open: boolean;
    dados?: IListagemContasPagar[];
    vselecionado: number;
    onClose: (value?: string) => void;
    ontotal: (value?: number) => void;
    onDados: (dados?: IListagemContasPagar[]) => void;
}

interface IFormData {
  valorpago: number;
  valorselecionado: number;
  idconta: number;
  databaixa: Date;
  numeroch: string;
  tipocaixa: boolean;
  valornfe: number;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  valorpago: yup.number().required().min(0.01).test('valorpago', 'O Valor Pago não pode ser maior que o Valor Selecionado', 
    function (value, context) {const valorpago = context.parent.valorselecionado || 0; 
      return value <= valorpago && value !== 0;
    }),
  valorselecionado: yup.number().required(), 
  idconta: yup.number().required(), 
  databaixa: yup.date().required(), 
  numeroch: yup.string().optional().default(''),
  tipocaixa: yup.boolean().required(), 
  valornfe: yup.number().optional().default(0), 
});


export const DialoBaixa: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const { formRef, save, } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [id, setId] = useState('');

  useEffect(() => {
    if(props.dados?.length === 1){ 
      setIsLoading1(false); 
    }else{
      setIsLoading1(true);
    }
    if (props.dados && typeof props.dados === 'object') {
      formRef.current?.setFieldValue('valorselecionado', props.vselecionado); 
      formRef.current?.setFieldValue('valorpago', props.vselecionado); 
    }
  }, [id, formRef]); 

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (props.dados && typeof props.dados === 'object') {
          ContasPagarService
            .Baixa(dadosValidados,  props.dados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                setValue('0');
                onClose('true');
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};  
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleCancel = () => {
    setValue('0');
    onClose('false');
  };

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }else{
      setId(valueProp);
    }
  }, [valueProp, open]);


  return (
    <Dialog 
      open={open}
      {...other}
    >
      <DialogTitle>Baixar</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef} onSubmit={handleSave}>
          <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
            <Grid container direction="column" padding={1} spacing={1}>
              {isLoading && (
                <Grid item>
                  <LinearProgress variant='indeterminate' />
                </Grid>
              )}
              <Grid container item direction="row"  spacing={2}>  
                <Grid item  xs={12} sm={12} md={12} lg={2} xl={12}>
                  <AutoCompleteContaBancaria
                    name='idconta'
                    label='Conta Bancaria'
                    isExternalLoading={isLoading}
                  />   
                </Grid> 
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <VTextFieldData
                    fullWidth
                    name='databaixa'  
                    label='Data Pagamento'
                    disabled={isLoading}
                  />
                </Grid>       
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <VFieldMoney
                    fullWidth
                    name='valorselecionado'
                    label='Valor Selecionado'
                    disabled={true}
                  />
                </Grid>  
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <VTextField
                    fullWidth
                    name='numeroch'
                    label='Numero Cheque'           
                    disabled={isLoading}
                  />     
                </Grid>  
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <VFieldMoney
                    fullWidth
                    name='valorpago'
                    label='Valor Pagar'
                    disabled={isLoading1}
                  />
                </Grid>  
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <VSwitch    
                    name='tipocaixa'      
                    label='Juntar'  
                    fdisabled={isLoading}/>                
                </Grid>   
              </Grid> 
            </Grid> 
          </Box>
        </VForm>  
      </DialogContent>
      <DialogActions>
        <Button  variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button variant='outlined' autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
    
  );
};