import { ChangeEvent, useEffect, useState } from 'react';
import { Avatar, Box, Grid, LinearProgress, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField,  VForm, useVForm, IVFormErrors, VSwitch, AutoCompleteSubgrupoProdutos, VTextFieldNumber, VFieldMoney4  } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { ProdutosService } from '../../../shared/services/api/estoque/produtos/ProdutosService';
import { AutoCompleteGrupoDeProdutos } from '../../../shared/forms/AutoCompleteGrupoDeProdutos';

interface IFormData {
  idgrupo: string;
  idsubgrupo: string;
  descricao: string;
  referencia: string;
  codbarraentrada: string;
  codbarrasaida: string;
  precocompra: number;
  precocusto: number;
  precovenda: number;
  precosaida: number;
  status: boolean;
  percentual: number;
  frete: number;
  local: string;
  diasvencimento: number;
  marca: string;
  vencimento: boolean;
  obs: string;
  codinterno: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IUpdateImg {
  urlimge: string;
}

const formValidationSchema: yup.ObjectSchema< Omit <IFormData, 'codinterno'>> = yup.object().shape({
  idgrupo: yup.string().required(),
  idsubgrupo: yup.string().required(),
  descricao: yup.string().required(),
  referencia: yup.string().optional().default(''),
  codbarraentrada: yup.string().optional().default(''),
  codbarrasaida: yup.string().optional().default(''),
  precocompra: yup.number().required().moreThan(0),
  precocusto: yup.number().required(),
  precovenda: yup.number().required().moreThan(0),
  precosaida: yup.number().required().moreThan(0),
  status: yup.boolean().optional().default(true),
  percentual: yup.number().required(),
  frete: yup.number().required(),
  local: yup.string().optional().default(''),
  diasvencimento: yup.number().optional().default(0),
  marca: yup.string().optional().default(''),
  vencimento: yup.boolean().required(),
  obs: yup.string().optional().default(''),
});


export const DetalheDeProduto: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');

  const [urlimge, setUrlimge] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | undefined>();

  const [idGrupo, SetIdGrupo] = useState(''); 
  const handleuuidgrupo = (uuid: string | undefined) => {
    if (uuid != null) {
      SetIdGrupo(uuid);
    }
  };

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);

      ProdutosService.getById(id)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
            navigate('/estoque');
          } else {
            setDescricao(result.descricao);
            formRef.current?.setData(result);
            // SetIdGrupo(result.idgrupo);
            if (result.urlimge != null) {
              setUrlimge(result.urlimge);
              setImage(process.env.REACT_APP_URL_IMGPRODUTOS+result.urlimge);
            } 
          }
        });
    } else {
      setImage('');
      formRef.current?.setData({
        idgrupo: '',
        idsubgrupo: '',
        descricao: '',
        referencia: '',
        codbarraentrada: '',
        codbarrasaida: '', 
        precocompra: 0,
        precocusto: '',
        precovenda: 0,
        precosaida: 0,
        status: true,
        percentual: 0,
        frete: 0,
        local: '',
        diasvencimento: 0,
        marca: '',
        vencimento: false,
        obs: '',
        codinterno: 0,
      });
    }
  }, [id]);

  
  const handleSave = (dados: IFormData) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { codinterno, ...dadosSemCodInterno } = dados;
    formValidationSchema.
      validate(dadosSemCodInterno, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);

        if (id === 'novo') {
          ProdutosService
            .create(selectedImage, dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/estoque');
                } else {
                  navigate(`/estoque/detalhe/${result}`);
                }
              }
            });
        } else {
          ProdutosService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/estoque');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };


  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      ProdutosService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/estoque');
          }
        });
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedImage(event.target.files?.[0]);
    const imagen =  event.target.files?.[0];  
    if (imagen) {
      const imageUrl = URL.createObjectURL(imagen);
      setImage(imageUrl);
    }
    if (id !== 'novo') {
      ProdutosService.updateByIdImg(id, imagen, urlimge)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Imgaem alterada com sucesso!');
            navigate('/estoque');
          }
        });
    }
  };

  const handlePrecoCompra = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{4})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const frete = data?.frete;
      const despesas = data?.precocusto;
      const tvalor = (Number(valor) + Number(frete) + Number(despesas));
      formRef.current?.setFieldValue('precosaida', tvalor);
      const percentual = data?.percentual;
      const tvalorVenda = (tvalor+((tvalor/100)*percentual));
      formRef.current?.setFieldValue('precovenda', tvalorVenda);
    }
  };

  const handleFrete = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{4})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const precocompra = data?.precocompra;
      const despesas = data?.precocusto;
      const tvalor = (Number(valor) + Number(precocompra) + Number(despesas));
      formRef.current?.setFieldValue('precosaida', tvalor);
      const percentual = data?.percentual;
      const tvalorVenda = (tvalor+((tvalor/100)*percentual));
      formRef.current?.setFieldValue('precovenda', tvalorVenda);
    }
  };

  const handleDespesa = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\D/g, '');
      valor = valor.replace(/(\d)(\d{4})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, ''); 
      const data = formRef.current?.getData();
      const precocompra = data?.precocompra;
      const frete = data?.frete;
      const tvalor = (Number(valor) + Number(precocompra) + Number(frete));
      formRef.current?.setFieldValue('precosaida', tvalor);
      const percentual = data?.percentual;
      const tvalorVenda = (tvalor+((tvalor/100)*percentual));
      formRef.current?.setFieldValue('precovenda', tvalorVenda);
    }
  };

  const handlePercentual = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/[^\d.]/g, '');
      const data = formRef.current?.getData();
      const precosaida = data?.precosaida;
      const tvalor = (((Number(precosaida)/100)*Number(valor))+Number(precosaida));
      formRef.current?.setFieldValue('precovenda', tvalor);
    }
  };


  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Novo Produto' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao={false}
 
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/estoque')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/estoque/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3} >
                <Box width="100%" marginRight={5} >
                  <input
                    type="file"
                    id="avatar-input"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                  />
                  <label htmlFor="avatar-input">
                    <Avatar 
                      variant='rounded'
                      alt='F'
                      src={image || undefined}
                      sx={{ width: '100%', height: 415 }}
                    />
                  </label>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                <Grid container item direction="row" spacing={2}> 
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VTextField
                      fullWidth
                      name='descricao'
                      disabled={isLoading}
                      label='Descrição'
                      onChange={e => setDescricao(e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <VTextField
                      fullWidth
                      name='codbarraentrada'
                      disabled={isLoading}
                      label='Código de Barras de Entrada'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <VTextField
                      fullWidth
                      name='codbarrasaida'
                      disabled={isLoading}
                      label='Código de Barras/QR Code de Saída'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <VTextField
                      fullWidth
                      name='codinterno'
                      disabled={true}
                      label='Código Interno'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <VTextField
                      fullWidth
                      name='referencia'
                      disabled={isLoading}
                      label='Referência'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <VTextField
                      fullWidth
                      name='marca'
                      disabled={isLoading}
                      label='Marca'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <VTextField
                      fullWidth
                      name='local'
                      disabled={isLoading}
                      label='Local'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <AutoCompleteGrupoDeProdutos
                      isExternalLoading={isLoading}
                      uuidgrupo={handleuuidgrupo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <AutoCompleteSubgrupoProdutos
                      isExternalLoading={isLoading} 
                      idgrupo={idGrupo}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                    <VFieldMoney4
                      fullWidth
                      name='precocompra'
                      disabled={isLoading}
                      label='Preço de Compra'
                      calcular={handlePrecoCompra}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                    <VFieldMoney4
                      fullWidth
                      name='frete'
                      disabled={isLoading}
                      label='Frete'
                      calcular={handleFrete}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                    <VFieldMoney4
                      fullWidth
                      name='precocusto'
                      disabled={isLoading}
                      label='Despesas'
                      calcular={handleDespesa}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                    <VTextFieldNumber
                      fullWidth
                      name='percentual'
                      disabled={isLoading}
                      label='Percentual'
                      calcular={handlePercentual}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                    <VFieldMoney4
                      fullWidth
                      name='precosaida'
                      disabled={true}
                      label='Preço de Saída' 
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                    <VFieldMoney4
                      fullWidth
                      name='precovenda'
                      disabled={true}
                      label='Preço de Venda' 
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                    <VTextFieldNumber
                      fullWidth
                      name='diasvencimento'
                      disabled={isLoading}
                      label='Dias para Vencimento'
                    />
                  </Grid>
                </Grid>
              </Grid>


              
              <Grid item xs={6} sm={6} md={3} lg={2} xl={1.5}>
                <VSwitch
                  name='vencimento'
                  label='Vencimento'
                  fdisabled={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3} lg={2} xl={1.5}>
                {(id != 'novo') && (
                  <VSwitch
                    name='status'
                    label='Status'
                    fdisabled={isLoading}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <VTextField
                  fullWidth
                  name='obs'
                  disabled={isLoading}
                  label='Observações'
                  multiline
                  rows={4}
                />
              </Grid>

              

            </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};