import { useState, useEffect } from 'react';
import { Box, MenuItem, Paper, Select, useTheme } from '@mui/material';

interface IFerramentasDaListagemProps {
  aoSelecionar?: (ano: string) => void;
  anoInicial?: string; 
}

export const FerramentasDaListagemPontoAnual: React.FC<IFerramentasDaListagemProps> = ({
  aoSelecionar,
}) => {
  const theme = useTheme();
  const currentYear = new Date().getFullYear(); // Obtém o ano atual
  const [ano, setAno] = useState<string>(currentYear.toString()); // Define o ano atual como valor inicial

  // Função para gerar a lista de anos de 2020 até o ano atual
  const generateYearList = (startYear: number, endYear: number): string[] => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year.toString());
    }
    return years;
  };

  const [anos, setAnos] = useState<string[]>(generateYearList(2020, currentYear)); // Inicializa com a lista de anos

  useEffect(() => {
    // Atualiza a lista de anos para incluir até o ano atual, se necessário
    setAnos(generateYearList(2020, currentYear));
  }, [currentYear]);

  return (
    <Box
      gap={2}
      marginX={1}
      padding={1}
      paddingX={1}
      display="flex"
      alignItems="center"
      height={theme.spacing(6)}
      component={Paper}
    >
      <Select
        labelId='demo-simple-select-helper-label'
        id='demo-simple-select-helper'
        label='Ano'
        value={ano}
        onChange={(event) => {
          setAno(event.target.value);
          aoSelecionar?.(event.target.value);
        }}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {anos.map((ano) => (
          <MenuItem key={ano} value={ano}>
            {ano}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

