
import { useEffect, useState, ChangeEvent } from 'react';
import { Box, Grid, LinearProgress, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField,  VForm, useVForm, IVFormErrors, VSwitch, VTextFieldTelefone, VTextFieldCep, VTextFieldCpfCnpj, cpfcnpjValidator } from '../../../shared/forms';

import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { TransportadoraService } from '../../../shared/services/api/cadastros/TransportadoraService';
import NavTabsTransportadora from './NavTabsTransportadora';
import { AutoCompleteMunicipio } from '../../../shared/forms/AutoCompleteMunicipio';
import { CnpjService } from '../../../shared/services/api/cadastros/CnpjService';
import { CpfService } from '../../../shared/services/api/cadastros/cpf';

interface IFormData {
  nome: string;
  cpf: string;
  fantasia: string;
  rg: string;
  telefone: string; 
  endereco: string;
  numero: string;
  idmunicipio: string;
  cep: string;
  obs: string;
  status: boolean;
  bairro: string;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  nome: yup.string().required().min(3).max(100),
  cpf: yup.string().required().min(11).max(14).test('cpf', 'CPF / CNPJ inválido', cpfcnpjValidator),
  fantasia: yup.string().optional().default(''),
  rg: yup.string().optional().default(''),
  telefone: yup.string().optional().default(''),
  endereco: yup.string().required().min(3).max(150),
  numero: yup.string().required().min(1).max(7),
  idmunicipio: yup.string().required().min(7).max(7),
  cep: yup.string().required().min(8).max(20),
  obs: yup.string().optional().default(''),
  status: yup.boolean().optional().default(true),
  bairro: yup.string().required().max(60),
});


export const DetalheDaTransportadora: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      
      TransportadoraService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/transportadoras');
          } else {
            setDescricao(result.nome);
            formRef.current?.setData(result);
          }
        });
    } else {
      formRef.current?.setData({
        nome: '',
        cpf: '',
        fantasia: '',
        rg: '',
        telefone: '',
        endereco: '',
        numero: '',
        idmunicipio: '',
        cep: '',
        obs: '',
        status: true,
        bairro: '',
      });
    }
  }, [id]);

  
  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          
          TransportadoraService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/transportadoras');
                } else {
                  navigate(`/transportadoras/detalhe/${result}`);
                }
              }
            });
        } else {
          TransportadoraService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/transportadoras');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };


  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      TransportadoraService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/transportadoras');
          }
        });
    }
  };

  const handleCPFCNPJChange = async (event: ChangeEvent<HTMLInputElement>) => {

    const fieldName = 'cpf';
    const enteredValue = event.target.value.replace(/[^\d]/g, ''); 
    try {
      
      await formValidationSchema.validateAt(fieldName, { [fieldName]: enteredValue });  

      if ((enteredValue.length === 11 || enteredValue.length === 14) && enteredValue) {
        setIsLoading(true);
        try {
          const response = await CpfService.getAlltransportadora(enteredValue);
          setIsLoading(false);
          if (typeof response !== 'string') {
            const isErrorResponse = 'message' in response && 'name' in response;
            if (isErrorResponse) {
              return;
            }
            throw new Error('Resposta inesperada da API');
          }
          const data: string = response; 
          if (data !== 'false') {
            navigate(`/transportadoras/detalhe/${data}`);
          } else if (enteredValue.length === 14 && data === 'false') { // Verifica se é CNPJ e o retorno foi 'false'
            setIsLoading(true);       
            const cnpjData = await CnpjService.getAll(enteredValue);
            if (Array.isArray(cnpjData) && cnpjData.length > 0) {
              const dataAtIndex0 = cnpjData[0]; // Acessa os dados na posição 0 do array
              const hasUndefinedFields = Object.values(dataAtIndex0).some(value => value === undefined);
              console.log(dataAtIndex0);
              if (hasUndefinedFields) {
                alert('CNPJ não encontrado na SEFAZ');
              } else {
                formRef.current?.setData({ ...formRef.current?.getData(), ...dataAtIndex0 });
              }
            } else {
              alert('CNPJ não encontrado na SEFAZ');
            }
          }
        } catch (error) {
          // console.error('Erro ao consultar a API:', error);
        } finally {
          setIsLoading(false);
        }
      }} catch (error) {
      setIsLoading(false);
    }
  };


  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Nova Transportadora' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Nova'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao = {false}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/transportadoras')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/transportadoras/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            {(id != 'novo') && (
              <NavTabsTransportadora
                id={id}
                case={0}                
              />
            )}
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={2}>   
                <VTextFieldCpfCnpj
                  fullWidth
                  onChange={handleCPFCNPJChange}
                  name='cpf'
                  disabled={isLoading}
                  label='CPF / CNPJ'
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={2}>
                <VTextField
                  fullWidth
                  name='rg'
                  disabled={isLoading}
                  label='RG'
                />
              </Grid>  
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>          
                <VTextField
                  fullWidth
                  name='nome'
                  disabled={isLoading}
                  label='Nome/Razão Social'
                  onChange={e => setDescricao(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>          
                <VTextField
                  fullWidth
                  name='fantasia'
                  disabled={isLoading}
                  label='Apelido/Nome Fantasia'
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3} xl={2}>
                <VTextFieldTelefone
                  fullWidth
                  name='telefone'
                  disabled={isLoading}
                  label='Telefone'
                />
              </Grid> 
              <Grid item xs={6} sm={6} md={6} lg={3} xl={2}>          
                <VTextFieldCep
                  fullWidth
                  name='cep'
                  disabled={isLoading}
                  label='CEP'
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>          
                <VTextField
                  fullWidth
                  name='endereco'
                  disabled={isLoading}
                  label='Endereço'
                />
              </Grid>
              <Grid item xs={3} sm={3} md={6} lg={3} xl={2}>          
                <VTextField
                  fullWidth
                  name='numero'
                  disabled={isLoading}
                  label='Número'
                />
              </Grid>
              
              <Grid item xs={9} sm={9} md={6} lg={3} xl={2}>          
                <VTextField
                  fullWidth
                  name='bairro'
                  disabled={isLoading}
                  label='Bairro'
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>     
                <AutoCompleteMunicipio isExternalLoading={isLoading}/>     
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={2} xl={1}>
                {(id != 'novo') && (
                  <VSwitch
                    name='status'
                    label='Ativo'
                    fdisabled={isLoading}/>
                )}
              </Grid> 
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <VTextField
                  fullWidth
                  name='obs'
                  disabled={isLoading}
                  label='Observações'
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm> 
    </LayoutBaseDePagina>
  );
};