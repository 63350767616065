import { Environment } from '../../../../environment';
import { Api } from '../../axios-config';


interface IFormDataGet {
  idponto: string;
  ponto: string;
  horaextra: number;
  pistola: number;
  bomba: number;
  cafe: number;
  almoco: number;
  janta: number;
  idfazenda: string;
  fazenda: string;
  status: boolean;
}


interface IDetalhesPonto{
  idponto: string;
  dia: string;
  semana: string;
  ponto: string;
  horaextra: number;
  pistola: number;
  bomba: number;
  cafe: number;
  almoco: number;
  janta: number;
  fazenda: string;
}

interface IResumoPonto{
    presenca: number;
    falta: number;
    dsr: string;
    horaextra: number;
    bomba: number;
    pistola: number;
    atestado: number;
    domingo: number;
    alimentacao: number;
    detalhes: IDetalhesPonto[];
  }

interface IFormData {
    ponto: string;
    horaextra: number;
    pistola: number;
    bomba: number;
    cafe: number;
    almoco: number;
    janta: number;
    idfazenda: string;
  }


type TpontoTotalCount = {
    data: IResumoPonto[];   
    totalCount: number;
}

// const getAll = async (page = 1, filter = ''): Promise<TCeiComTotalCount | Error> => {
//   try {
//     const urlRelativa = `/cei?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}`;

//     const { data, headers } = await Api.get(urlRelativa);

//     if (data) {
//       return {
//         data,
//         totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
//       };
//     }

//     return new Error('Erro ao listar os registros.');
//   } catch (error) {
//     return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
//   }
// };

const getAll = async (page = 1, idfun: string, periodo: string): Promise<TpontoTotalCount | Error> => {
  try {
     
    const urlRelativa = `/pontos?page=${page}&limit=${9}&idfun=${idfun}&periodo=${periodo}`;

    const { data, headers } = await Api.get(urlRelativa);


    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getById = async (id: string): Promise<IFormDataGet | Error> => {
  try {
    const { data } = await Api.get(`/pontos/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

// const create = async (dados: IDetalheCei): Promise<string | Error> => {
//   try {
//     const guuid = uuidv4();

//     const { data } = await Api.post<IDetalheCei>('/cei',  {idcei: guuid, ...dados});
  
//     if (Object.keys(data).length === 0) {
//       return guuid;
//     }

//     return new Error('Erro ao criar o registro.');
//   } catch (error) {
//     return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
//   }
// };

const updateById = async (id: string, dados:IFormData): Promise<void | Error> => {
  try {
    await Api.put(`/pontos/${id}`, dados);
  } catch (error) {
    console.log(error);
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Api.delete(`/pontos/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const PontoService = {
  getAll,
  // create,
  getById,
  updateById,
  deleteById,
};