/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Grid, Icon, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField, VForm, useVForm, VTextFieldData, VFieldMoney, AutoCompleteParceiro, IVFormErrors, VTextFieldCpf, AutoCompletePlanoDeContas } from '../../../shared/forms';
import { FerramentasDeDetalheNfe } from '../../../shared/components';
import dayjs from 'dayjs';
import { LayoutBaseDePaginaNfe } from '../../../shared/layouts';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { DialogEntrada } from './DialogEntrada';
import { v4 as uuidv4 } from 'uuid';
import { EntradaService } from '../../../shared/services/api/estoque/entrada/EntradaService';
import { AutoCompleteOperacoes } from '../../../shared/forms/AutoCompleteOperacoes';
import { DialogFaturamento } from './DialogFaturamento';
import { EstoqueOperacoesService } from '../../../shared/services/api/estoque/estoqueOperacoes/EstoqueOperacoesService';

type NullableDate = Date | null | undefined;

interface IFormData {
  idparceiro: string;
  idplanoconta: string;
  idoperacoes: string;
  dataemissao: Date;
  numeronota: string;
  numerodoc: string;
  subtotal: number;
  desconto: number;
  outrasdespesas: number;
  totalnota: number;
  acrescimo: number;
  obs: string;

  cpf: string;
}

interface IitensEntradaDialog {
  iditens: string;
  idprod: string;
  idlocalizacao: string;
  qtdsaida: number;
  validade?: NullableDate;
  un: string;
  qtdentrada: number;
  conversor: number;
  preco: number;
  total: number;

  filtro: string;
  descricao: string;
  localizacao: string;
  reqvencimento: boolean;
}

interface IItensids {
  iditens: string;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idparceiro: yup.string().required(),
  idplanoconta: yup.string().required(),
  idoperacoes: yup.string().required(),
  dataemissao: yup.date().required('O campo é obrigatório')
    .test('valid-date', 'Selecione uma data válida', function (value) {
      const currentDate = new Date();

      if (!value || value > currentDate) return false;

      return true;
    }),
  numeronota: yup.string().optional().default(''),
  numerodoc: yup.string().required(),
  subtotal: yup.number().required().moreThan(0),
  desconto: yup.number().required(),
  outrasdespesas: yup.number().required(),
  totalnota: yup.number().required(),
  acrescimo: yup.number().required(),
  obs: yup.string().optional().default(''),

  cpf: yup.string().optional().default(''),
});

export const DetalheDaEntrada: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openFaturamento, setOpenFaturamento] = useState(false);
  const [statuss, setStatuss] = useState(false);
  const [iditens, setIditens] = useState('');

  const [rows, setRows] = useState<IitensEntradaDialog[]>([]);
  const [rowsids, setRowsids] = useState<IItensids[]>([]);
  const [dadositens, setDadositens] = useState<IitensEntradaDialog>();
  const guuid = uuidv4();
  const [faturamento, setFaturamento] = useState<boolean>();
  const [venda, setvenda] = useState<boolean>();
  const [cabecalho, setCabecalho] = useState<IFormData>();
  const [operacao, setOperacao] = useState<string>();

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      EntradaService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/entradaestoque');
          } else {
            formRef.current?.setData(result);
            setRows(result.itens);
            // setStatuss(result.status);
            if (result.pagas != '0' || result.boleto != '0') {
              setStatuss(true);
            }

            EstoqueOperacoesService.getById(result.idoperacoes)
              .then((result) => {
                if (result instanceof Error) {
                  alert(result.message);
                  navigate('/entradaestoque');
                } else {
                  setFaturamento(result.faturamento);
                  setvenda(result.venda);
                  setOperacao(result.descricao);
                }
              });
          }
        });
    } else {
      formRef.current?.setData({
        idoperacoes: '',
        dataemissao: '',
        numeronota: '',
        numerodoc: '',
        subtotal: '',
        desconto: '',
        outrasdespesas: '',
        totalnota: '',
        acrescimo: '',
        obs: '',
      });
      setRows([]);
    }
  }, [id]);

  useEffect(() => {
    const totalSum = rows.reduce((accumulator, currentItem) => accumulator + currentItem.total, 0);
    const data = formRef.current?.getData();
    const desconto = data?.desconto;
    const acrescimo = data?.acrescimo;
    const outrasdespesas = data?.outrasdespesas;
    const tvalor = (Number(totalSum) - Number(desconto) + Number(acrescimo) + Number(outrasdespesas));
    formRef.current?.setFieldValue('totalnota', tvalor);
    formRef.current?.setFieldValue('subtotal', totalSum);
  }, [rows]);


  const handleSave = (dados: IFormData) => {

    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        setCabecalho(dadosValidados);

        if (id === 'novo') {
          if (faturamento == true || venda == true) {
            setOpenFaturamento(true);
          } else {
            EntradaService
              .create(dadosValidados, rows, [])
              .then((result) => {
                setIsLoading(false);

                if (result instanceof Error) {
                  alert(result.message);
                } else {
                  if (isSaveAndClose()) {
                    navigate('/entradaestoque');
                  } else {
                    navigate(`/entradaestoque/detalhe/${result}`);
                  }
                }
              });
          }
        } else {
          if (faturamento == true || venda == true) {
            setOpenFaturamento(true);
          } else {
            EntradaService
              .updateById(id, dadosValidados, rows, rowsids, [])
              .then((result) => {
                setIsLoading(false);

                if (result instanceof Error) {
                  alert(result.message);
                } else {
                  if (isSaveAndClose()) {
                    navigate('/entradaestoque');
                  }
                }
              });
          }
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });

  };

  const handleDeleteEntrada = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      EntradaService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/entradaestoque');
          }
        });
    }
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {

      const meuObjeto = { iditens: id };

      rowsids.push(meuObjeto);

      const indexToDelete = rows.findIndex((item) => item.iditens === id);
      if (indexToDelete !== -1) {
        const updatedList = [...rows];
        updatedList.splice(indexToDelete, 1);
        setRows(updatedList);
      }
    }
  };

  const handleBuscarDados = (id: string) => {
    const foundItem = rows.find((listItem) => listItem.iditens === id);
    if (foundItem) {
      setDadositens(foundItem);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseFaturamento = (newValue?: string) => {
    setOpenFaturamento(false);
    setIsLoading(false);

    if (newValue != undefined) {
      navigate(`/entradaestoque/detalhe/${newValue}`);
    }
  };

  type RowsType = IitensEntradaDialog[];

  const handleItens = (dados: IitensEntradaDialog) => {
    const index = rows.findIndex((listItem) => listItem.iditens === dados.iditens);

    let updatedList: RowsType;

    if (index !== -1) {
      updatedList = [...rows];
      updatedList[index] = dados;
    } else {
      updatedList = [...rows, dados];
    }
    setRows(updatedList);

  };

  const handlecpf = (cpfs: string | undefined) => {
    if (cpfs != null && cpfs != '') {
      formRef.current?.setFieldValue('cpf', cpfs);
    } else {
      formRef.current?.setFieldValue('cpf', '');
    }
  };

  const handleDesconto = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\./g, '');
      valor = valor.replace(/,/g, '.');
      valor = valor.replace(/(\d)(\d{4})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const subtotal = data?.subtotal;
      const acrescimo = data?.acrescimo;
      const outrasdespesas = data?.outrasdespesas;
      const tvalor = (Number(subtotal) - Number(valor) + Number(acrescimo) + Number(outrasdespesas));
      formRef.current?.setFieldValue('totalnota', tvalor);
    }
  };

  const handleAcressimo = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\./g, '');
      valor = valor.replace(/,/g, '.');
      valor = valor.replace(/(\d)(\d{4})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const subtotal = data?.subtotal;
      const desconto = data?.desconto;
      const outrasdespesas = data?.outrasdespesas;
      const tvalor = (Number(subtotal) - Number(desconto) + Number(valor) + Number(outrasdespesas));
      formRef.current?.setFieldValue('totalnota', tvalor);
    }
  };

  const handleOutrasDespesas = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\./g, '');
      valor = valor.replace(/,/g, '.');
      valor = valor.replace(/(\d)(\d{4})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      const data = formRef.current?.getData();
      const subtotal = data?.subtotal;
      const desconto = data?.desconto;
      const acrescimo = data?.acrescimo;
      const tvalor = (Number(subtotal) - Number(desconto) + Number(acrescimo) + Number(valor));
      formRef.current?.setFieldValue('totalnota', tvalor);
    }
  };

  const handleFaturamento = (faturamento: boolean | undefined) => {
    setFaturamento(faturamento);
  };

  const handleVenda = (venda: boolean | undefined) => {
    setvenda(venda);
  };

  const handleOperacao = (operacao: string | undefined) => {
    setOperacao(operacao);
  };

  return (
    <LayoutBaseDePaginaNfe
      barraDeFerramentas={
        <FerramentasDeDetalheNfe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando={isLoading}
          mostrarBotaoSalvarEFecharCarregando={isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoImpressao={false}
          mostrarBotaoApagarCarregando={statuss === false ? isLoading : true}
          mostrarBotaoTransmitir={false}
          mostrarBotaoProtocolo={false}
          mostrarBotaoCancelar={false}
          disableBotaoSalvar={statuss === false ? isLoading : true}
          disableBotaoSalvarEFechar={statuss === false ? isLoading : true}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/entradaestoque')}
          mostrarBotaoVoltarCarregando={isLoading}
          aoClicarEmNovo={() => navigate('/entradaestoque/detalhe/novo')}
          mostrarBotaoNovoCarregando={isLoading}
          aoClicarEmApagar={() => handleDeleteEntrada(id)}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={1} spacing={1}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid container item direction="row" spacing={2}>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} >
                  <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>
                    <AutoCompleteParceiro
                      isExternalLoading={statuss === false ? isLoading : true}
                      cpfs={handlecpf}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                    <VTextFieldCpf
                      fullWidth
                      name='cpf'
                      label='CPF/CNPJ'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <AutoCompleteOperacoes
                      isExternalLoading={statuss === false ? isLoading : true}
                      disabled={statuss === false ? isLoading : true}
                      faturamento={handleFaturamento}
                      venda={handleVenda}
                      operacao={handleOperacao}
                      tipo={0}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <AutoCompletePlanoDeContas
                      isExternalLoading={statuss === false ? isLoading : true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      name='numeronota'
                      label='Nº da NF-e'
                      disabled={statuss === false ? isLoading : true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6} lg={2} xl={2}>
                    <VTextField
                      fullWidth
                      name='numerodoc'
                      label='Nº do Documento'
                      disabled={statuss === false ? isLoading : true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6} lg={2} xl={2}>
                    <VTextFieldData
                      fullWidth
                      name='dataemissao'
                      label='Data de Emissão'
                      disabled={statuss === false ? isLoading : true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='subtotal'
                      label='Subtotal'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='desconto'
                      label='Desconto'
                      disabled={statuss === false ? isLoading : true}
                      calcular={handleDesconto}
                    />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='acrescimo'
                      label='Acréssimo'
                      disabled={statuss === false ? isLoading : true}
                      calcular={handleAcressimo}
                    />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='outrasdespesas'
                      label='Outras Despesas'
                      disabled={statuss === false ? isLoading : true}
                      calcular={handleOutrasDespesas}
                    />
                  </Grid>
                  <Grid item xs={6} sm={12} md={6} lg={2} xl={2}>
                    <VFieldMoney
                      fullWidth
                      name='totalnota'
                      label='Total do Pedido'
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </Grid>


              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper} variant="outlined" sx={{ width: 'auto', height: 350, maxHeight: 350 }}>
                  <Table size="small">
                    <TableHead sx={{ backgroundColor: '#5E8A71' }}>
                      <TableRow >
                        <TableCell style={{ width: 20, color: '#fff' }} align='right'  >Qtd NF</TableCell>
                        <TableCell style={{ width: 20, color: '#fff' }} align='left'>UN</TableCell>
                        <TableCell style={{ width: 140, color: '#fff' }} align='left'>Descrição</TableCell>
                        <TableCell style={{ width: 30, color: '#fff' }} align='left'>Local</TableCell>
                        <TableCell style={{ width: 20, color: '#fff' }} align='center'>Validade</TableCell>
                        <TableCell style={{ width: 10, color: '#fff' }} align='right'>Preço de Compra</TableCell>
                        <TableCell style={{ width: 10, color: '#fff' }} align='right'>Total</TableCell>
                        <TableCell style={{ width: 2, maxWidth: '1px', maxHeight: '10px', color: '#fff' }} align='center'>
                          <IconButton color='inherit' disabled={statuss === false ? isLoading : true} aria-label="add to shopping cart" size="large">
                            <AddShoppingCartIcon onClick={() => { setIditens('novo'); setOpen(true); }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.iditens}
                          hover
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell style={{ width: 20 }} align='right' >{row.qtdentrada.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</TableCell>
                          <TableCell style={{ width: 20 }} align='left'>{row.un}</TableCell>
                          <TableCell style={{ width: 140 }} align='left' >{row.descricao}</TableCell>
                          <TableCell style={{ width: 30 }} align='left'>{row.localizacao}</TableCell>
                          <TableCell style={{ width: 20 }} align='center'>{row.validade ? dayjs(row.validade).format('DD/MM/YYYY') : ''}</TableCell>
                          <TableCell style={{ width: 10 }} align='right'>{row.preco.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</TableCell>
                          <TableCell style={{ width: 10 }} align='right'>{row.total.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</TableCell>
                          <TableCell>
                            <IconButton size="small" onClick={() => { setIditens(row.iditens); handleBuscarDados(row.iditens); setOpen(true); }}>
                              <Icon >edit</Icon>
                            </IconButton>
                            <IconButton disabled={statuss === false ? isLoading : true} size="small" onClick={() => { handleDelete(row.iditens); }}>
                              <Icon>delete</Icon>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <LinearProgress variant='indeterminate' />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={2} >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <VTextField
                      fullWidth
                      name='obs'
                      label='Observações'
                      multiline
                      rows={4}
                      disabled={statuss === false ? isLoading : true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <DialogEntrada
              id={iditens}
              value={guuid}
              dados={dadositens}
              open={open}
              onDados={handleItens}
              onClose={handleClose}
              status={statuss}
            />
            <DialogFaturamento
              id={id}
              value={guuid}
              dados={cabecalho}
              dadosItens={rows}
              open={openFaturamento}
              onClose={handleCloseFaturamento}
              status={statuss}
              operacao={operacao}
              iddeletados={rowsids}
            />
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePaginaNfe>
  );
};