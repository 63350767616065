import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDebounce } from '../hooks';
import { useField } from '@unform/core';
import { LocalizacaoService } from '../services/api/estoque/localizacao/LocalizacaoService';


type TAutoCompleteOption = {
  idlocalizacao: string;
  label: string;
  idLocalizacao: string;
}

interface IAutoCompleteLocalizacaoProps {
  Label: string; 
  name: string; 
  isExternalLoading?: boolean;
  disabled?: boolean;
  idLocalizacao?: (novoTextoselect: string) => void; 
}
export const AutoCompleteLocalizacaoField: React.FC<IAutoCompleteLocalizacaoProps> = ({ isExternalLoading = false, disabled = false, idLocalizacao, name, Label }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue);

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
    });
  }, [registerField, fieldName, selectedId]);

  useEffect(() => {
    setIsLoading(true);

    debounce(() => {
      LocalizacaoService.getAllList( busca)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            // alert(result.message);
          } else {
            setOpcoes(result.data.map(localizacao => ({ idlocalizacao: localizacao.idlocalizacao, label: localizacao.descricao, idLocalizacao: localizacao.idlocalizacao })));
          }
        });
    });
  }, [busca, selectedId]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.idlocalizacao === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);


  return (
    <Autocomplete
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando...'

      disablePortal

      options={opcoes}
      loading={isLoading}
      disabled={disabled || isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue) => { setSelectedId(newValue?.idlocalizacao); idLocalizacao?.(newValue?.idlocalizacao ?? ''); setBusca(''); clearError(); }}
      popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}

          label={Label}
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
};