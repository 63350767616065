/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dayjs } from 'dayjs';
import { Environment } from '../../../../environment';
import { Apiestoque } from '../../axiosestoque';
import { v4 as uuidv4 } from 'uuid';

type NullableDate = Date | null | undefined;

export interface IListagemTransferencias {
  idtransferencia: string;
  datalan: Date;
  portador: string;

  origem: string;
  destino: string;
  status: string;
}

export interface IitensTransferenciaDialog{
  iditens: string;
  idprod: string;
  idlocalizacao: string; 
  qtdsaida: number; 
  validade?: string | null;
  qtdentrada: number;
  preco: number;
  un: string;
  conversor: number;
  total: number;

  referencia: string;
  qtdestoque: number;
  filtro: string;
  descricao: string;
  reqvencimento: boolean; 
}

interface IgetTransferencia {
  iddestino: string;
  idorigem: string;
  datalan: Date;
  dataenvio?: NullableDate;
  datarecebimento?: NullableDate;
  portador: string;
  status: number;
  itens: IitensTransferenciaDialog[];
}

interface IDetalheSaida {
  iddestino: string;
  idorigem: string;
  datalan: Date;
  dataenvio?: NullableDate;
  datarecebimento?: NullableDate;
  portador: string;
  status: number;
}

interface IItensids{
  iditens: string;
}

type TotalCount = {
  data: IListagemTransferencias[];
  totalCount: number;
}

interface ErrorResponse {
  data: {
    errors: {
      [key: string]: string;
    };
  };
}

interface EstoqueQuantidade {
  idprod: string; 
  idlocalizacao: string; 
  vencimento: boolean; 
  validade: string | Dayjs | null | undefined;
}

interface Quantidade{
  quantidade: number;
}

const getAll = async (page = 1, dataInicial = '', dataFinal='', tipo='', idlocalizacao=''): Promise<TotalCount | Error> => {
  try {
    
    const urlRelativa = `/transferenciaestoque?page=${page}&limit=${17}&tipo=${tipo}&inicial=${dataInicial}&final=${dataFinal}&origem=${idlocalizacao}`;
    const { data, headers } = await Apiestoque.get(urlRelativa);  
 
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || 17),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IgetTransferencia | Error> => {
  try {

    const { data } = await Apiestoque.get(`/transferenciaestoque/${id}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheSaida, dadositens: IitensTransferenciaDialog[]): Promise<string | Error> => {
  try {
    
    const guuid = uuidv4();
    const dadosExtraidos = dadositens.map(({ qtdestoque, referencia, descricao, reqvencimento, filtro, ...restoDoObjeto }) => ({ idoperacao: guuid, ...restoDoObjeto }));
    const dadosEntrada = {idtransferencia: guuid, datarecebimento: null, ...dados};
    
    const { data } = await Apiestoque.post<IDetalheSaida>('/transferenciaestoque',  [dadosEntrada, dadosExtraidos]);
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Lançar Entrada');
    }
  }
};

const estoqueQuantidade = async (dados: EstoqueQuantidade): Promise<Quantidade | Error> => {
  try {
    
    const { data } = await Apiestoque.post('/estoquequantidade', dados);  

    if (data) {
      return (data);
    }

    return new Error('Erro ao pesquisar quantidade do ítem selecionado.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro pesquisar quantidade do ítem.');
    }
  }
};

const updateById = async (id: string, dados: IDetalheSaida, dadositens: IitensTransferenciaDialog[], iddeletados: IItensids[]): Promise<void | Error> => {
  try {

    const dadosExtraidos = dadositens.map(({ descricao, reqvencimento, filtro, referencia, ...restoDoObjeto }) => ({ idoperacao: id, ...restoDoObjeto }));
    //const { venda, ...dadosIDetalheSaida } = dados;
    const dadosEntrada = {idtransferencia: id, datarecebimento: null, ...dados};
    await Apiestoque.put(`/transferenciaestoque/${id}`, [dadosEntrada, dadosExtraidos, iddeletados]);

  } catch (error) {

    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apiestoque.delete(`/transferenciaestoque/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const TransferenciaService = {
  getAll,
  create,
  getById,
  updateById,
  deleteById,
  estoqueQuantidade,
};