/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Avatar, Box, Grid, Icon, IconButton, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField, VForm, useVForm,  AutoCompleteFuncionario,  VFieldMoney, AutoCompletePeriodo } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePaginaNfe } from '../../../shared/layouts';
import { FolhaService, IVerbasFolha } from '../../../shared/services/api/rh/folha/FolhaService';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import recibo, { IGetFolha } from './Impressaorecibo';

interface IFormData {
  idfun: string;
  tipodesconto: string;
  valor: number;
  realizacao: string;
  historico: string;
  dataemissao: Date;
  periodo: string;
  financeiro: string;
  parcela: string;
  idoperacao: string;
  totalvale: number;
}

export const DetalheDaFolha: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();

  const { formRef, save, saveAndClose } = useVForm();
  const [rowsRecibo, setRowsRecibo] = useState<IGetFolha>();
  const [rows, setRows] = useState<IVerbasFolha[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nome, setNome] = useState('');
  const [, setIdfun] = useState('');
  const [cpf, seCpf] = useState('');
  const [funcao, setFuncao] = useState('');
  const [urlimg, setUrlimg] = useState('');
  const [statuss, ] = useState(false);
 
  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      FolhaService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/folha');
          } else {
            setRowsRecibo(result);
            setFuncao(result.funcao);
            seCpf(result.cpf);
            setIdfun(result.idfun);
            if (result.urlimg != null) {
              setUrlimg(process.env.URL_IMGFUNCIONARIO+result.urlimg);
            } 
            formRef.current?.setData(result);
            setNome(result.nome);
            setRows(result.itens);
          }
        });
    } else {
      formRef.current?.setData({
      });
    }  
  }, [id]);

  const handlefoto = (urlimg: string | undefined) => {
    if (urlimg != null) {
      setUrlimg(process.env.URL_IMGFUNCIONARIO+urlimg);
    }
  };   
   
  const handleSave = (dados: IFormData) => {
    //
  };          
     
  // const handleDelete = (id: string) => {
  //   //
  // };

  const handlePrint = () => {   
    if(rowsRecibo){    
      recibo(rowsRecibo);      
    }
  };

 
  return (
    <LayoutBaseDePaginaNfe
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando={isLoading}
          mostrarBotaoSalvarEFecharCarregando={isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoImpressao={id !== 'novo'}
          mostrarBotaoApagarCarregando={isLoading}
          mostrarBotaoImpressaoCarregando={isLoading}

          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/folha')}
          mostrarBotaoVoltarCarregando={isLoading}
          // aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmImpressao={() => handlePrint()}
          mostrarBotaoNovoCarregando={isLoading}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={1} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid item>
              <Typography variant='h6'>Geral</Typography> 
            </Grid>
            <Grid container item direction="row" spacing={3}>
              <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                <Grid container item direction="row" spacing={1}>
                
                  <Grid marginLeft={3} item xs={4} sm={4} md={2} lg={2} xl={1} >
                    <Box width="100%" marginRight={5} >
                      <Avatar
                        variant='rounded'
                        alt='F'
                        src={urlimg}
                        sx={{ width: '100%', height: 150 }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid container item direction="row" spacing={2} >
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
                          {id === 'novo' ? (
                            <AutoCompleteFuncionario 
                              isExternalLoading={id === 'novo' ? isLoading : true}
                              urlfoto={handlefoto}
                            />
                          ) : (
                            <TextField
                              fullWidth
                              value={nome}
                              label='Nome do Funcionario'
                              disabled={true}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
                          <TextField
                            fullWidth
                            value={cpf}
                            label='CPF'
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={8} sm={8} md={6} lg={6} xl={2}>
                          {id === 'novo' ? (
                            <AutoCompletePeriodo 
                              isExternalLoading={id === 'novo' ? isLoading : true}/>
                          ) : (
                            <VTextField
                              fullWidth
                              name='periodo'
                              label='Período'
                              disabled={true}
                            />
                          )}
                        
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={6} xl={2}>
                          <VFieldMoney
                            fullWidth
                            name='total'
                            label='Total'
                            disabled={id === 'novo' ? isLoading : true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid container item direction="row" marginTop={2} spacing={2} >
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
                          <TextField
                            fullWidth
                            value={funcao}
                            label='Função'
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={2} xl={3}>
                          <VTextField
                            fullWidth
                            name='tipopagamento'
                            label='Tipo de Pagamento'
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                          <VFieldMoney
                            fullWidth
                            name='salario'
                            label='Salario'
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                          <VFieldMoney
                            fullWidth
                            name='carteira'
                            label='S. Carteira'
                            disabled={id === 'novo' ? isLoading : true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={11} marginLeft={3}>
                    <TableContainer component={Paper} variant="outlined" >
                      <Table size="small">
                        <TableHead  sx={{ backgroundColor: '#5E8A71' }}>
                          <TableRow >
                            <TableCell style={{ width: 5, color: '#fff' }} align='left'>Quantidade</TableCell>
                            <TableCell style={{ width: 140, color: '#fff' }} align='left'>Descrição</TableCell>
                            <TableCell style={{ width: 10, color: '#fff' }} align='right'>Vencimentos</TableCell>
                            <TableCell style={{ width: 10, color: '#fff' }} align='right'>Descontos</TableCell>
                            <TableCell style={{ width: 2, maxWidth:'1px', maxHeight: '10px',  color: '#fff' }} align='center'>
                              <IconButton color='inherit' disabled={statuss === false ? isLoading : true}  aria-label="add to shopping cart" size="large">
                                <AddCircleOutlineIcon  />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map(row => (
                            <TableRow key={row.idverbo} 
                              hover
                              sx={{ cursor: 'pointer' }}
                            >
                              <TableCell style={{ width: 5 }} align='left' >{row.qtd.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                              <TableCell style={{ width: 140 }} align='left' >{row.descricao}</TableCell>
                              <TableCell style={{ width: 10 }} align='right'>{row.vencimentos.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                              <TableCell style={{ width: 10 }} align='right'>{row.descontos.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                              <TableCell>
                                <IconButton size="small" >
                                  <Icon >edit</Icon>
                                </IconButton>
                                <IconButton disabled={statuss === false ? isLoading : true}  size="small" onClick={() => {}}>
                                  <Icon>delete</Icon>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                          {isLoading && (
                            <TableRow>
                              <TableCell colSpan={3}>
                                <LinearProgress variant='indeterminate' />
                              </TableCell>
                            </TableRow>
                          )}
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePaginaNfe>
  );
};