import {  Box, Button, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress, Paper  } from '@mui/material';
import { useState } from 'react';
import {  AutoCompleteFazenda, IVFormErrors, VForm, VTextField, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { RelatorioService } from '../../../shared/services/api/rh/Relatorios/RelatoriosService';
import FuncionarioPorFazenda from './RelatorioFuncionarioPorFazenda';

interface IFomeTipoPgto {
  idfazenda: string;
  texto: string;      
}

const formValidationSchema: yup.ObjectSchema<IFomeTipoPgto> = yup.object().shape({
  idfazenda: yup.string().required(),
  texto: yup.string().optional().default(''),
});
          
export const SegurancaTrabalho: React.FC = () => {          
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false); 
  const [fazenda, setFazenda] = useState('');          
              
  const handleSave = (dados: IFomeTipoPgto) => {                                  
    formValidationSchema.     
      validate(dados, { abortEarly: false })     
      .then((dadosValidados) => {                 
        setIsLoading(true);   
        RelatorioService
          .RelatorioFuncionariosporFazenda(dadosValidados.idfazenda)   
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {           
              alert(result.message);   
            } else 
            {
              FuncionarioPorFazenda(result, fazenda, dadosValidados.texto);
            }
          });
      }   
      )
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};   
        errors.inner.forEach(error => {   
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });            
  };    
  
  const handleFazenda = (nome: string | undefined) => {    
    if (nome != null) {     
      setFazenda(nome);                  
    }else{                       
      setFazenda('');                         
    }                 
  }; 
  
  return (
    <VForm ref={formRef} onSubmit={handleSave}>
      <Box  alignItems="center" width='100%' alignContent='center' height="80%"  display="flex" flexDirection="column"  >
        <Box gap={1}  padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='100%' height="100%" margin={1}  alignContent='center'></Box>
        <Box   width='30%' alignItems="center"  component={Paper} height="100%" margin={1} 
          alignContent='center'>       
          <DialogTitle>Relatório de Segurança do Trabalho</DialogTitle>
          <DialogContent  dividers>    
            {isLoading && (           
              <Grid item>  
                <LinearProgress variant='indeterminate' />  
              </Grid>                      
            )}   
            <Grid container  padding={1} item direction="row" gap={2}>
              <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <AutoCompleteFazenda 
                    isExternalLoading={isLoading}
                    nome={handleFazenda}
                  />
                </Grid> 
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <VTextField
                    fullWidth
                    name='texto'
                    disabled={isLoading}
                    label='Texto'
                  />
                </Grid> 
              </Grid>        
            </Grid>         
          </DialogContent>     
          <DialogActions>
            <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={save}>Visualizar</Button>
          </DialogActions>
        </Box>
      
      </Box>
    </VForm> 
  );
};   