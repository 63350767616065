/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { useDebounce } from '../../shared/hooks';
import { ParceirosService } from '../services/api/cadastros/ParceirosService';

type TAutoCompleteOption = {
  idparceiro: string;
  label: string;
}

interface IAutoCompleteParceiroProps {
  isExternalLoading?: boolean;
  uuidparceiro?: (novoTextoselect: string | undefined) => void;
  nome?: (novoTextoselect: string | undefined) => void;
}  

export const AutoCompleteParceiroListagem: React.FC<IAutoCompleteParceiroProps> = ({ isExternalLoading = false, uuidparceiro, nome}) => {

  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<string | undefined>('');

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');

  useEffect(() => {
    setIsLoading(true);
  
    debounce(() => {
      ParceirosService.getAllList(busca, selectedId)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            // alert(result.message);
          } else {
            setOpcoes(result.data.map(parceiro => ({ idparceiro: parceiro.idparceiro, label: parceiro.nome + ' - ' + parceiro.cpf })));
          }
        });
    });
  }, [busca, selectedId]);
 
  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.idparceiro === selectedId); 
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);
   
  return (
    <Autocomplete
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando...'

      disablePortal
      size='medium'
      options={opcoes}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue) => { setSelectedId(newValue?.idparceiro); nome?.(newValue?.label); uuidparceiro?.(newValue?.idparceiro);  setBusca('');  }}
      popupIcon={(isLoading) ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}

          label="Parceiros"
          // error={!!error}  
          // helperText={error}
        />
      )}
    />
  );
};