/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Grid, Icon, IconButton, LinearProgress, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField,  VForm, useVForm, IVFormErrors, AutoCompleteFazenda, VTextFieldData } from '../../../shared/forms';
import { FerramentasDaListagem, FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { SelectNumber } from '../../../shared/forms/Selectnumber';
import { Environment } from '../../../shared/environment';
import { DialogSolicitacaoOS } from './DialogSolicitacaoOS';
import { OsService } from '../../../shared/services/api/estoque/os/OsService';
import { DialogSaidaOS } from './DialogSaidaOS';
import SwipeableTextMobileStepper from './SwipeableTextMobileStepper';
import { PatrimoniosService } from '../../../shared/services/api/cadastros/PatrimoniosService';

type NullableDate = Date | null | undefined;

interface IFormData {
  idpatrimonio: string;
  idfazenda: string;
  operador: string; 
  mecanico: string; 
  dataabertura: Date;
  datafechamento?: NullableDate;
  defeitoreclamado: string; 
  defeitoconstatado: string;
  servicosrealizados: string;
  situacao: number;
}

interface IitensSaidaDialog{
  iditens: string;
  idprod: string;
  qtdsaida: number; 
  validade?: string | null;
  un: string;
  qtdentrada: number;
  conversor: number;
  preco: number;
  total: number;

  referencia: string;
  qtdestoque: number;
  filtro: string;
  descricao: string;
  reqvencimento: boolean;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idpatrimonio: yup.string().required(),
  idfazenda: yup.string().required(),
  operador: yup.string().required(),
  mecanico: yup.string().required(),
  dataabertura: yup.date().required(),
  datafechamento: yup.date().nullable().optional().default(null),
  defeitoreclamado: yup.string().optional().default(''),
  defeitoconstatado: yup.string().optional().default(''),
  servicosrealizados: yup.string().optional().default(''),
  situacao: yup.number().required(),
});

interface IItensids{
  iditens: string;
}

interface IImage {
  imgPath: string;
}

export const DetalheDaOS: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState(0);
  const [numeroPatrimonio, setNumeroPatrimonio] = useState('');
  const [descricaoPatrimonio, setDescricaoPatrimonio] = useState('');
  const [modeloPatrimonio, setModeloPatrimonio] = useState('');
  const [images, setImages] = useState<IImage[]>([{ imgPath: `${Environment.URL_IMGPATRIMONIO}semimagem.jpg`}]);
  const [idpatrimonio, setIdpatrimonio] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  
  const [rows, setRows] = useState<IitensSaidaDialog[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const [open, setOpen] = useState(false);

  const [statuss, setStatuss] = useState(true);
  const [dadositens, setDadositens] = useState<IitensSaidaDialog>();
  //Ítens saída
  const [iditens, setIditens] = useState('');
  const handleBuscarDados = (id: string) => {
    const foundItem = rows.find((listItem) => listItem.iditens === id);
    if (foundItem) {
      setDadositens(foundItem);
    }
  };

  const [openSaida, setOpenSaida] = useState(false);

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      
      OsService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/os');
          } else {
            setDescricao(result.numero);
            setRows(result.itens);
            formRef.current?.setData(result);
            setDescricaoPatrimonio(result.nomepatrimonio);
            setModeloPatrimonio(result.modelo);
            setIdpatrimonio(result.idpatrimonio);
            if(result.fotos === null || result.fotos.length === 0){
              setImages(imagesnovo);
            }else{
              const imageList: IImage[] = [];
              result.fotos.forEach((item) => {
                imageList.push({ imgPath: Environment.URL_IMGPATRIMONIO+item.imgPath });
              });
              setImages(imageList);
            }  
          }
        });
    } else {
      formRef.current?.setData({
        idpatrimonio: '',
        idfazenda: '',
        operador: '',
        mecanico: '',
        dataabertura: new Date(),
        datafechamento: null,
        defeitoreclamado: '',
        defeitoconstatado: '',
        servicosrealizados: '',   
        situacao: 1,
        numero: 0,
      });
      setRows([]);
      setImages(imagesnovo);
    }
  }, [id]);

  
  const handleSave = (dados: IFormData) => {
    dados.idpatrimonio = idpatrimonio;
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          
          OsService
            .create(dadosValidados, rows)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/os');
                } else {
                  navigate(`/os/detalhe/${result}`);
                }
              }
            });
        } else {
          OsService
            .updateById(id, dadosValidados, rows, rowsids )
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/os');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };
  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      OsService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/os');
          }
        });
    }
  };
  const [rowsids, setRowsids] = useState<IItensids[]>([]);
  const handleDeleteItemSaida = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {

      const meuObjeto = {iditens: id};
     
      rowsids.push(meuObjeto); 
      
      const indexToDelete = rows.findIndex((item) => item.iditens === id);
      if (indexToDelete !== -1) {
        const updatedList = [...rows];
        updatedList.splice(indexToDelete, 1);
        setRows(updatedList);
      }
    }
  };

  const [detalheterceiro, setdetalheterceiro ] = useState<string>('novo');
  const handleClose = (newValue?: string) => {
    setOpen(false);
  };

  const handleCloseSaida = (newValue?: string) => {
    setOpenSaida(false);
  };

  type RowsType = IitensSaidaDialog[];

  const handleItens = (dados: IitensSaidaDialog) => {
    const index = rows.findIndex((listItem) => listItem.iditens === dados.iditens);
  
    let updatedList: RowsType;
  
    if (index !== -1) {
      updatedList = [...rows];
      updatedList[index] = dados;
    } else {
      updatedList = [...rows, dados];
    }
    setRows(updatedList);
  };

  const handleFechamento = (valor: string | undefined) => {
    if(Number(valor) === 3){
      formRef.current?.setFieldValue('datafechamento', new Date());
    }else{
      formRef.current?.setFieldValue('datafechamento', null);
    }
  }; 

  const imagesnovo = [{ imgPath: `${Environment.URL_IMGPATRIMONIO}semimagem.jpg`, arquivo: undefined }];

  const handlebusca = (numeroPatrimonios: string) => {
    setIsLoading(true);
    if (numeroPatrimonios) {
 
      PatrimoniosService.getAllListNumero(numeroPatrimonios)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setDescricaoPatrimonio(result.descricao);
            setModeloPatrimonio(result.modelo);
            setIdpatrimonio(result.idpatrimonio);
            if(result.fotos === null || result.fotos.length === 0){
              setImages(imagesnovo);
            }else{
              const imageList: IImage[] = [];
              result.fotos.forEach((item) => {
                imageList.push({ imgPath: Environment.URL_IMGPATRIMONIO+item.imgPath });
              });
              setImages(imageList);
            }  
          }
        });
    }
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Nova OS' : `Ordem de Serviço: ${String(descricao)}`}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Nova'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={false}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao = {false}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/os')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/os/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>  
                <SwipeableTextMobileStepper
                  images={images} 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={10} lg={8} xl={8}>
                <Grid container item direction="row" spacing={2}> 
                  <Grid item xs={12} sm={12} md={4} lg={6} xl={2}>
                    {id === 'novo' ?     
                      <TextField
                        fullWidth  
                        value={numeroPatrimonio}
                        label='Nº Patrimônio'
                        disabled={isLoading}
                        onChange={e => setNumeroPatrimonio(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && numeroPatrimonio.trim() !== '') {
                            handlebusca(numeroPatrimonio);
                          }
                        }}
                      />
                      :   
                      <TextField
                        fullWidth
                        value={numeroPatrimonio}
                        disabled={true}
                        label='Nº Patrimônio'
                      />
                    }                     
                  </Grid> 
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>     
                    <TextField
                      fullWidth
                      value={descricaoPatrimonio}
                      disabled={true}
                      label='Patrimônio'
                    />   
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>     
                    <TextField
                      fullWidth
                      value={modeloPatrimonio}
                      disabled={true}
                      label='Modelo'
                    />   
                  </Grid>
                  
                  <Grid item xs={6} sm={6} md={6} lg={3} xl={2}>          
                    <VTextFieldData
                      fullWidth
                      name='dataabertura'
                      disabled={true}
                      label='Data de Abertura'
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={3} xl={2}>          
                    <VTextFieldData
                      fullWidth
                      name='datafechamento'
                      disabled={true}
                      label='Data de Fechamento'
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>     
                    <AutoCompleteFazenda
                      isExternalLoading={isLoading}
                    />     
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>          
                    <SelectNumber
                      data={[
                        { nameBD: 1, descricao: 'Execução' },
                        { nameBD: 2, descricao: 'Aguardando Peças' },
                        { nameBD: 3, descricao: 'Finalizado' },
                      ]}
                      label='Situação'
                      name='situacao' 
                      disabled={isLoading}
                      valor={handleFechamento}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>          
                    <VTextField
                      fullWidth
                      name='operador'
                      disabled={isLoading}
                      label='Operador'
                      multiline
                      rows={2}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>          
                    <VTextField
                      fullWidth
                      name='mecanico'
                      disabled={isLoading}
                      label='Mecânico'
                      multiline
                      rows={2}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>          
                    <VTextField
                      fullWidth
                      name='defeitoreclamado'
                      disabled={isLoading}
                      label='Defeito Reclamado'
                      multiline
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>          
                    <VTextField
                      fullWidth
                      name='defeitoconstatado'
                      disabled={isLoading}
                      label='Defeito Constatado'
                      multiline
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>          
                    <VTextField
                      fullWidth
                      name='servicosrealizados'
                      disabled={isLoading}
                      label='Serviços Realizados'
                      multiline
                      rows={3}
                    />
                  </Grid>
                  
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
     
      </VForm> 
     
      <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
        <Grid container direction="column" padding={2} spacing={2}>
          <Grid container item direction="row" spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>    
              <FerramentasDaListagem
                textoTitulo='Peças Solicitadas'
                mostrarTextoTitulo={true}
                textoBotaoNovo='Adicionar'
                aoClicarEmNovo={() => {setdetalheterceiro('novo'); setOpen(true);}}
              />

              <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Data/Hora</TableCell>
                      <TableCell>Solicitante</TableCell>
                      <TableCell>Fazenda</TableCell>
                    </TableRow>
                  </TableHead>
  
                  {totalCount === 0 && !isLoading && (
                    <caption>{Environment.LISTAGEM_VAZIA}</caption>
                  )}
  
                  <TableFooter>
                    {isLoading && (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <LinearProgress variant='indeterminate' />
                        </TableCell>
                      </TableRow>
                    )}
                    {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Pagination
                            page={pagina}
                            count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                            onChange={(_, newPage) => setSearchParams({ pagina: newPage.toString() }, { replace: true })}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableFooter>
                </Table>
              </TableContainer>
              <DialogSolicitacaoOS
                id={detalheterceiro}
                value={''}  
                open={open} 
                onClose={handleClose}
                idFazenda={id}
              /> 
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>   
              <FerramentasDaListagem
                textoTitulo='Peças Utilizadas'
                mostrarTextoTitulo={true}
                textoBotaoNovo='Adicionar'
                aoClicarEmNovo={() => {setIditens('novo');  setOpenSaida(true);}}
              />

              <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
                <Table size="small">
                  <TableHead  sx={{ backgroundColor: '#5E8A71' }}>
                    <TableRow >
                      <TableCell style={{ width: 20, color: '#fff' }} align='right'>Qtd</TableCell>
                      <TableCell style={{ width: 15, color: '#fff' }} align='left'>UN</TableCell>
                      <TableCell style={{ width: 150, color: '#fff' }} align='left'>Descrição</TableCell>
                      <TableCell style={{ width: 20, color: '#fff' }} align='left'>Referência</TableCell>
                      <TableCell style={{ width: 2, maxWidth:'2px', maxHeight: '10px',  color: '#fff' }} align='center'>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <TableRow key={row.iditens} 
                        hover
                        sx={{ cursor: 'pointer' }}
                      >
                        {/*  */}
                        <TableCell style={{ width: 20 }} align='right' >{row.qtdsaida.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell> 
                        <TableCell style={{ width: 15 }} align='left'>{row.un}</TableCell>
                        <TableCell style={{ width: 150 }} align='left' >{row.descricao}</TableCell>
                        <TableCell style={{ width: 20 }} align='left'>{row.referencia}</TableCell>
                        <TableCell>
                          <IconButton size="small" onClick={() => {setIditens(row.iditens); handleBuscarDados(row.iditens);  setOpenSaida(true);}}>
                            <Icon >edit</Icon>
                          </IconButton>
                          <IconButton disabled={statuss ? isLoading : true}  size="small" onClick={() => {handleDeleteItemSaida(row.iditens);}}>
                            <Icon>delete</Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    {isLoading && (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <LinearProgress variant='indeterminate' />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableFooter>
                </Table>
              </TableContainer>
              <DialogSaidaOS 
                id={iditens} 
                value={''} 
                dados={dadositens}
                open={openSaida} 
                onDados={handleItens} 
                onClose={handleCloseSaida} 
                status={statuss}
              /> 
            </Grid>
          </Grid>
        </Grid>
      </Box>    
    </LayoutBaseDePagina>
  );
};