import { useEffect, useMemo, useState } from 'react';
import { Avatar,  LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LayoutBaseDeFolha, } from '../../../shared/layouts';
import { Environment } from '../../../shared/environment';
import { FerramentasDaListagemFolha } from '../../../shared/components/ferramentas-da-listagem/FerramentasDaListagemFolha';
import { FolhaService, IListagemFolha } from '../../../shared/services/api/rh/folha/FolhaService';
import { useDebounce } from '../../../shared/hooks';
     
export const ListagemFolha: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();  
  const { debounce } = useDebounce();
  const [rows, setRows] = useState<IListagemFolha[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [periodo, setPeriodo] = useState('');

  
  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const handlePesquisa = (periodo: string) => {
    setIsLoading(true);  
    FolhaService.GetAll(1, 'periodo', periodo, '')
      .then((result) => {
        setIsLoading(false);  
        if (result instanceof Error) {
          alert(result.message);
        } else { 
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });
  };  

  const handlePeriodo = (periodos: string) => {
    setPeriodo(periodos);
  };  

  useEffect(() => {
    if(periodo !== ''){
      setIsLoading(true);
      debounce(() => {
        FolhaService.GetAll(pagina, 'busca', periodo, busca)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }
  }, [busca, pagina]);
 
  return (
    <LayoutBaseDeFolha
      titulo='Folha de Pagamento'
      barraDeFerramentas={
        <FerramentasDaListagemFolha
          textoDaBusca={busca}
          aoClicarEmPesquisa={handlePesquisa}
          textoBotaoNovo='Adicionar'
          aoMudarPeriodo={handlePeriodo}
          aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, pagina: '1' }, { replace: true })}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto', marginRight: 2 }}>
        <Table>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell  sx={{ maxWidth: 10, width:10 }} size="small" aria-label="a dense table"></TableCell>
                <TableCell>Colaborador</TableCell>
                <TableCell>CPF</TableCell>
                <TableCell>Função</TableCell>
                <TableCell align='right' sx={{ maxWidth: 100, width:100 }}>Salario</TableCell>
                <TableCell align='right' sx={{ maxWidth: 100, width:100 }}>Carteira</TableCell>
                <TableCell align='right' sx={{ maxWidth: 100, width:100 }}>Vencimentos</TableCell>
                <TableCell align='right' sx={{ maxWidth: 100, width:100 }}>Descontos</TableCell>
                <TableCell align='right' sx={{ maxWidth: 100, width:100 }}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.idfolha} 
                  hover
                  sx={{ cursor: 'pointer' }}  
                  onClick={() => navigate(`/folha/detalhe/${row.idfolha}`)}
                >
                  <TableCell><Avatar alt="F" src={Environment.URL_IMGFUNCIONARIO+row.urlimg}/></TableCell>
                  <TableCell>{row.nome}</TableCell>
                  <TableCell>{row.cpf.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')}</TableCell>
                  <TableCell>{row.funcao}</TableCell>
                  <TableCell align='right'>{row.salario.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                  <TableCell align='right'>{row.carteira.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                  <TableCell align='right'>{row.vencimentos.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                  <TableCell align='right'>{row.descontos.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                  <TableCell align='right'>{row.total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2})}</TableCell>
                </TableRow>
              ))}
            </TableBody> 
   
            {totalCount === 0 && !isLoading && (
              <caption>{Environment.LISTAGEM_VAZIA}</caption>
            )}
            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <LinearProgress variant='indeterminate' />
                  </TableCell>
                </TableRow>
              )}
              {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHASSMALL) && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Pagination  
                      page={pagina}
                      count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHASSMALL)}
                      onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </Table>
      </TableContainer>
    </LayoutBaseDeFolha>
  ); 
};