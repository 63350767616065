/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosError } from 'axios';
import { Environment } from '../../../../environment';
import { ApiFiscal } from '../../axiosfiscal';

import { v4 as uuidv4 } from 'uuid';

export interface IListagemNfe {
  idnfe: string;
  dataemissao: Date;
  nfe: number;
  produtor: string;
  parceiros: string;
  cpf: string;
  vnfe: number;
  status: number;
}

export interface IitensNfe{
  iditens: string;
  idprod: string;
  cfop: number;
  cst: number;
  quantidade: number;
  preco: number;
  desconto: number;
  total: number;
  frete: number;
  seguro: number;
  bcicms: number;
  percentual: number;
  valoricms: number;
  bcst: number;
  percentualst: number;
  valoricmsst: number;
  cstipi: number;
  bcipi: number;
  percentualipi: number;
  valoripi: number;
  cstpis: number;
  bcpis: number;
  percentualpis: number;
  valorpis: number;
  cstcofins: number;
  bccofins: number;
  percentualcofins: number;
  valorcofins: number;
  voutros: number;
  preducaoicms: number;
}

interface IItensNFe{
  iditens: string;
  idprod: string;
  descricao: string;
  un: string;
  ncm: string;
  cfop: number;
  cst: number;
  quantidade: number;
  preco: number;
  desconto: number;
  total: number;
  frete: number;
  seguro: number;
  bcicms: number;
  percentual: number;
  valoricms: number;
  bcst: number;
  percentualst: number;
  valoricmsst: number;
  cstipi: number;
  bcipi: number;
  percentualipi: number;
  valoripi: number;
  cstpis: number;
  bcpis: number;
  percentualpis: number;
  valorpis: number;
  cstcofins: number;
  bccofins: number;
  percentualcofins: number;
  valorcofins: number;
  voutros: number;
  preducaoicms: number;
}

interface IItensNFe{
  iditens: string;
  idprod: string;
  descricao: string;
  un: string;
  ncm: string;
  cfop: number;
  cst: number;
  quantidade: number;
  preco: number;
  desconto: number;
  total: number;
  frete: number;
  seguro: number;
  bcicms: number;
  percentual: number;
  valoricms: number;
  bcst: number;
  percentualst: number;
  valoricmsst: number;
  cstipi: number;
  bcipi: number;
  percentualipi: number;
  valoripi: number;
  cstpis: number;
  bcpis: number;
  percentualpis: number;
  valorpis: number;
  cstcofins: number;
  bccofins: number;
  percentualcofins: number;
  valorcofins: number;
  voutros: number;
  bcicmsst: number;
  preducaoicms: number;
}

interface Igetnfe {
  serie: number;
  nfe: number;
  dataemissao: Date;
  finnfe: number;
  nfecpl: string;
  tpag: number;
  cfop: number;
  obscomplementar: string;
  idprodutor: number;
  idparceiro: string;
  idtransportadora?: string | null;
  volumequantidade: number;
  volumemarca: string;
  volumepesoliquido: number;
  volumepesobruto: number;
  vprod: number;
  vbc: number;
  vicms: number;
  vfrete: number;
  vseg: number;
  vdesc: number;
  vipi: number;
  vpis: number;
  vconfins: number;
  vnfe: number;
  idfazenda: string;
  nferef: string; 
  tpnfe: number;
  volesp: string;
  idveiculo?: string | null;
  modfrete: number;
  status: number;
  pathpdf: string;
  protocolo: string;
  dataenvio: Date;
  itens: IItensNFe[];
}


interface IDetalhenfe {
  serie: number;
  nfe: number;
  dataemissao: Date;
  finnfe: number;
  nfecpl: string;
  tpag: number;
  cfop: number;
  obscomplementar: string;
  idprodutor: number;
  idparceiro: string;
  idtransportadora?: string | null;
  volumequantidade: string;
  volumemarca: string;
  volumepesoliquido: number;
  volumepesobruto: number;
  vprod: number;
  vbc: number;
  vicms: number;
  vfrete: number;
  vseg: number;
  vdesc: number;
  vipi: number;
  vpis: number;
  vconfins: number;
  vnfe: number;
  idfazenda: string;
  nferef: string; 
  tpnfe: number;
  volesp: string;
  idveiculo?: string | null;
  modfrete: number;
}

interface IItensids{
  iditens: string;
}

export interface IRNFE {
  serie: number;
  nfe: number;
  dataemissao: Date; 
  nome: string;
  cpf: string; 
  cfop: number; 
  vnfe: number;
  finalidade: string; 
  status: string; 
}

interface INfecancelar {
  justificativa: string;
}


type TotalCount = {
    data: IListagemNfe[];
    totalCount: number;
}

const getAll = async (page = 1, filter = '', tipo=''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa = `/nfe?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}&tipo=${tipo}`;

    const { data, headers } = await ApiFiscal.get(urlRelativa);  
 
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<Igetnfe | Error> => {
  try {

    const { data } = await ApiFiscal.get(`/nfe/${id}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalhenfe, dadositens: IItensNFe[]): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const dadosExtraidos = dadositens.map(({ descricao, un, ncm, ...restoDoObjeto }) => ({ idnfe: guuid, ...restoDoObjeto }));

    const dadosnfe = {idnfe: guuid, ...dados};

    const { data } = await ApiFiscal.post<IDetalhenfe>('/nfe',  [dadosnfe, dadosExtraidos]);
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalhenfe, dadositens: IItensNFe[], iddeletados: IItensids[]): Promise<void | Error> => {
  try {

    const dadosExtraidos = dadositens.map(({ descricao, un, ncm, ...restoDoObjeto }) => ({idnfe: id, ...restoDoObjeto }));

    await ApiFiscal.put(`/nfe/${id}`, [dados, dadosExtraidos, iddeletados]);

  } catch (error) {

    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
    
  }
};

interface ErrorResponse {
  error: {
    default: string;
  };
}

const transmitir = async (id: string): Promise<string | Error> => {
  try {
    const { data } = await ApiFiscal.put(`/transmitirnfe/${id}`);
    return data.pdf; 
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

const cancelarnfe = async (id: string, dados: INfecancelar): Promise<string | Error> => {
  try {
    const { data } = await ApiFiscal.put(`/cancelarnfe/${id}`, dados);
    return data.pdf; 
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<ErrorResponse>;
      if (axiosError.response) {
        const errorMessage = axiosError.response.data.error?.default || 'Erro desconhecido';
        return new Error(errorMessage);
      } else if (axiosError.request) {
        return new Error('Nenhuma resposta do servidor');
      } else {
        return new Error(axiosError.message);
      }
    } else {
      return new Error('Erro inesperado');
    }
  }
};

const Relatorio = async (produtor = 0, tipo = '', dataInicial = '', dataFinal=''): Promise<IRNFE[] | Error> => {
  try {
    const urlRelativa = `/relatorionfe?produtor=${produtor}&tipo=${tipo}&datainicial=${dataInicial}&datafinal=${dataFinal}`;

    const { data } = await ApiFiscal.get(urlRelativa);
    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const NfeService = {
  getAll,
  create,
  getById,
  updateById,
  transmitir,
  cancelarnfe,
  Relatorio,
};