import { useEffect, useState } from 'react';
import { SwitchProps, Switch, FormControlLabel } from '@mui/material';
import { useField } from '@unform/core';

 
type TVSwitchdProps = SwitchProps & {
    name: string;
    label: string;
    fdisabled: boolean;
    onFCheckedChange: (checked: boolean) => void;
}

export const VSwitch01: React.FC<TVSwitchdProps> = ({ name, label, fdisabled, onFCheckedChange}) => {
  const { fieldName, registerField } = useField(name);

  const [checked, setChecked] = useState(true);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => checked,
      setValue: (_, newchecked) => setChecked(newchecked),
    });
  }, [registerField, fieldName, checked]);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={e => {setChecked(e.target.checked); onFCheckedChange(e.target.checked);}}
          color="primary"
          defaultChecked
          disabled = {fdisabled}
        />
      } 
      label = {label}
    />
  );
}; 