import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material';
import { LayoutBaseDeDashboard } from '../../../shared/layouts';
import { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts';
import { getTemaSistema } from '../../../shared/contexts';
import OutlinedCard2Faturamento from './OutlinedCard2Faturamento';
import { DashboardFaturamentoService } from '../../../shared/services/api/faturamento/dashboardFaturamento/DashboardFaturamentoService';
import GraficoArea from '../../../shared/forms/GraficoArea';
import GraficoBarra from '../../../shared/forms/GraficoBarra';

export const DashboardFaturamento = () => {
  const teme = getTemaSistema();
  const [tema, setTema] = useState<'dark' | 'light'>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (teme === 'dark') {
      setTema('dark');
    } else {
      setTema('light');
    }
  }, [teme]);
  
  const [Server, setServer] = useState(
    {
      nivel: 1,

      raberto: 0,
      rfechado: 0,
      rvencido: 0,
        
      romaneioaberto:[], 
      romaneiovencido:[],

      producao:[],
      producaoquebra:[],

      vendas:[],

      cultivo:[{titulo: '', valor: 0}],

      clientes:[],
      frutas:[],
      
    }
  );

  useEffect(() => {
    setLoading(true);
    DashboardFaturamentoService.GetDashboardCadastroService()
      .then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setServer(result);
          setLoading(false);
        }
      });
  },[]); 


  type Extrair = {
    titulo: string;
    valor: number;
  };

  function extrairNomes(titulo: Extrair[] | undefined): string[] {
    return titulo ? titulo.map(titulo => titulo.titulo) : [];
  }
  
  function extrairValores(titulo: Extrair[] | undefined): number[] {
    return titulo ? titulo.map(titulo => titulo.valor) : [];
  }

  function extrairValores2(titulo: Extrair[] | undefined): number[] {
    return titulo ? titulo.map(titulo => parseFloat(titulo.valor.toFixed(2))) : [];
  }
  

  const seriesDataRomaneiosAbertos = [
    {
      name: 'Romaneios Abertos',
      data: extrairValores(Server.romaneioaberto) ?? [],
    }
  ];

  const optionsDataRomaneiosAbertos = {
    title: {
      text: 'Romaneios Abertos',
    },
    colors:['#00E396'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories: extrairNomes(Server.romaneioaberto) 
    }
  };

  const seriesDataRomaneiosVencidos = [
    {
      name: 'Romaneios Vencidos',
      data: extrairValores(Server.romaneiovencido) ?? [],
    }
  ];

  const optionsDataRomaneiosVencidos = {
    title: {
      text: 'Romaneios Vencidos',
    },
    colors:['#00E396'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories: extrairNomes(Server.romaneiovencido) 
    }
  };

  const seriesDataRomaneiosVendas = [
    {
      name: 'Vendas R$',
      data: extrairValores(Server.vendas) ?? [],
    }
  ];

  const optionsDataRomaneiosVendas = {
    title: {
      text: 'Vendas R$',
    },
    colors:['#00E396'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories: extrairNomes(Server.vendas) 
    } 
  };

  const seriesDataRomaneiosProducao = [
    {
      name: 'Produção Kg',
      data: extrairValores(Server.producao) ?? [],
    },
    {
      name: 'Quebra Kg',
      data: extrairValores(Server.producaoquebra) ?? [],
    }
  ];

  const optionsDataRomaneiosProducao = {
    title: {
      text: 'Produção dos Últimos 12 Meses Kg',
    },
    colors:['#00E396', '#FF4560'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories: extrairNomes(Server.producao) 
    }
  };

  const seriesDataCliente = [
    {
      name: 'Valor R$',
      data: extrairValores(Server.clientes) ?? [],
    }
  ];

  const optionsDataCliente = {
    title: {
      text: 'Clientes mais Frequentes dos Últimos 12 Meses',
    },
    colors:['#00E396'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories: extrairNomes(Server.clientes) 
    }
  };

  const seriesDataFruta = [
    {
      name: 'Valor R$',
      data: extrairValores2(Server.frutas) ?? [],
    }
  ];

  const optionsDataFruta = {
    title: {
      text: 'Média de Valor de Fruta dos Últimos 12 Meses R$',
    },
    colors:['#00E396'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories: extrairNomes(Server.frutas) 
    }
  };


  return (
    <LayoutBaseDeDashboard>
      <Box display="flex" component={Paper} variant="outlined" >
        <Grid container item direction="row" >
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <OutlinedCard2Faturamento
              nome="Romaneios Abertos" 
              valor={Server.raberto} 
              cor="#82D2ED"
              img='/img/romaneio_aberto.png'
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <OutlinedCard2Faturamento 
              nome="Romaneios Fechados" 
              valor={Server.rfechado} 
              cor="#DEED82"
              img='/img/romaneio_fechado.png'
              loading={loading}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <OutlinedCard2Faturamento 
              nome="Romaneios Vencidos" 
              valor={Server.rvencido} 
              cor="#82D2ED"
              img='/img/romaneio_vencido.png'
              loading={loading}
            />
          </Grid>
            
        </Grid>
          
      </Box>
      <Box display="flex" component={Paper} variant="outlined">
        <Grid container item direction="row" >
          {(Server.nivel === 1) && (
            <>
              <Grid component={Paper} item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper elevation={5}>
                  {loading ? <Skeleton variant="rectangular" height={260}/> : 
                    <GraficoArea
                      series={seriesDataRomaneiosAbertos}
                      options={optionsDataRomaneiosAbertos} />
                  }
                </Paper>
              </Grid>
              <Grid component={Paper}  item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper elevation={5}>
                  {loading ? <Skeleton variant="rectangular" height={260}/> : 
                    <GraficoArea
                      series={seriesDataRomaneiosVencidos}
                      options={optionsDataRomaneiosVencidos} />
                  }
                </Paper>
              </Grid>
            </>
          )}
          {(Server.nivel === 2) && (
            <>
              <Grid component={Paper} item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Paper elevation={5} >
                  <Box marginLeft={1}>
                    {loading ? <Skeleton variant="rectangular" height={260}/> : 
                      <GraficoArea
                        series={seriesDataRomaneiosVendas} 
                        options={optionsDataRomaneiosVendas}
                      />
                    }
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box marginLeft={0.2}>
                  <Paper elevation={5}>
                    {loading ? <Skeleton variant="rectangular" height={260}/> : 
                      <GraficoArea
                        series={seriesDataRomaneiosProducao} 
                        options={optionsDataRomaneiosProducao}
                      />
                    }
                  </Paper>
                </Box>
              </Grid>
              <Grid component={Paper} item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper elevation={5}>
                  {loading ? <Skeleton variant="rectangular" height={260}/> : 
                    <Box  height={'40%'}  display="flex" flexDirection="column">
                      {Server.cultivo && Server.cultivo.length > 0 && (
                        <Box marginLeft={1} height={'255px'}>
                          <>
                            <Typography sx={{ fontSize: 15, fontWeight: 'bold' }} gutterBottom component="div">
                        Cultivos mais Utilizados dos Últimos 12 Meses R$
                            </Typography>
                            <BarChart
                            
                              xAxis={[
                                {
                                  id: 'barCategories',
                                  data: Server.cultivo.map((item) => item.titulo),
                                  scaleType: 'band',
                                },
                              ]}
                              series={[
                                {
                                  data: Server.cultivo.map((item) => item.valor),
                                },
                              ]}
                              height={250} />
                          </>
                        </Box>
                      )}
            
                    </Box>
                  }
                </Paper>
              </Grid>
              <Grid component={Paper} item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box marginLeft={0.2}>
                  <Paper elevation={5}>
                    {loading ? <Skeleton variant="rectangular" height={260}/> : 
                      <GraficoBarra
                        series={seriesDataCliente} 
                        options={optionsDataCliente}
                      />
                    }
                  </Paper>
                </Box>
              </Grid>
              <Grid   item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Box marginLeft={0.2}>
                  <Paper elevation={5}>
                    {loading ? <Skeleton variant="rectangular" height={260}/> : 
                      <GraficoArea
                        series={seriesDataFruta} 
                        options={optionsDataFruta}
                      />
                    }
                  </Paper>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>

    </LayoutBaseDeDashboard>
  );
};