/* eslint-disable @typescript-eslint/no-unused-vars */
import { Environment } from '../../../environment';
import { ApiFinanceira } from '../axiosfinanceiro';
import { ErrorResponse } from 'react-router-dom';

export interface IListagem {
  idcontaspagar: string;
  nome: string;
  cpf: string;
  numerodoc: string;
  dataemissao: Date;
  vencimento: Date;
  valorpagar: number; 
}

export interface IConfirmarBoleto{
  idcontaspagar: string;
  nossonumero: string;
  boleto: boolean;
}

type TotalCount = {
    data: IListagem[];
    totalCount: number;
}

const getAll = async (page = 1, parceiro = '', tipo='', datainicial: string, datafinal: string): Promise<TotalCount | Error> => {
  try {

    const urlRelativa = `/confirmarboleto?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&parceiro=${parceiro}&tipo=${tipo}&datainicial=${datainicial}&datafinal=${datafinal}`;

    const { data, headers } = await ApiFinanceira.get(urlRelativa); 

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao listar os registros');
    }}
};

const ConfirmarBoleto = async (Confirmar: IConfirmarBoleto): Promise<void | Error> => {
  try {  
   
    await ApiFinanceira.put('/confirmarboleto', Confirmar); 
    return;   
  } catch (error) {
    if (typeof error === 'object' && error !== null && 'data' in error && 'errors' in (error as ErrorResponse).data) {
      const e = error as ErrorResponse;
      const errorMessages = Object.entries(e.data.errors)
        .map(([field, message]) => `${field}: ${message}`)
        .join('; ');
      return new Error(errorMessages);
    } else {
      return new Error('Erro ao Baixra');
    }}
};

export const ConfirmarBoletoService = {  
  getAll,
  ConfirmarBoleto,
};