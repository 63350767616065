import { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { CeiService } from '../../../shared/services/api/rh/cei/CeiService';
import { VTextField, AutoCompleteProdutor,  VForm, useVForm, IVFormErrors, VSwitch  } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';

interface IFormData {
  numero: string;
  idprodutor: number;
  endereco: string;
  cidade: string;
  bairro: string;
  cep: string;
  caepf: string;
  status: boolean;
  uf: string;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  numero: yup.string().required().min(3),
  idprodutor: yup.number().integer().required().moreThan(0),
  endereco: yup.string().optional().default(''),
  cidade: yup.string().optional().default(''),
  bairro: yup.string().optional().default(''),
  cep: yup.string().optional().default(''),
  caepf: yup.string().optional().default(''),
  status: yup.boolean().optional().default(true),
  uf: yup.string().optional().default(''),
});


export const DetalheDeCei: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();

  const [isLoading, setIsLoading] = useState(false);
  const [numero, setNumero] = useState('');

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);

      CeiService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/cei');
          } else {
            setNumero('Numero CEI: '+ result.numero);
            formRef.current?.setData(result);
          }
        });
    } else {
      formRef.current?.setData({
        numero: '',
        idprodutor: '',
        endereco: '',
        cidade: '',  
        bairro: '',
        cep: '',
        caepf: '',
        status: '',
        uf: '',
      });
    }
  }, [id]);

  
  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);

        if (id === 'novo') {
          CeiService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/cei');
                } else {
                  navigate(`/cei/detalhe/${result}`);
                }
              }
            });
        } else {
          CeiService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/cei');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };


  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      CeiService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/cei');
          }
        });
    }
  };


  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Novo Cei' : numero}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao={false}
 
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/cei')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/cei/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid item>
              <Typography variant='h6'>Geral</Typography>
            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  name='numero'
                  disabled={isLoading}
                  label='Numero'
                  onChange={e => setNumero('Numero CEI: '+e.target.value)}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  name='caepf'
                  disabled={isLoading}
                  label='CAEPF'
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>          
                <AutoCompleteProdutor isExternalLoading={isLoading} />
              </Grid>

            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                <VTextField
                  fullWidth
                  name='endereco'
                  label='Endereço'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  name='cep'
                  label='CEP'
                  disabled={isLoading}
                />
              </Grid>
            </Grid>

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <VTextField
                  fullWidth
                  name='cidade'
                  label='Cidade'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                <VTextField
                  fullWidth
                  name='uf'
                  label='UF'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  name='bairro'
                  label='Bairro'
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                {(id != 'novo') && (
                  <VSwitch
                    name='status'
                    label='Status'
                    fdisabled={isLoading}/>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};