import { useEffect, useState } from 'react';
import { Avatar, Box, Grid, LinearProgress, Paper, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField, VForm, useVForm, IVFormErrors, AutoCompleteFuncionario, VFieldMoney, AutoCompletePeriodo } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { GratificacoesService } from '../../../shared/services/api/rh/gratificacoes/GratificacoesService';
import { dataextenso } from '../../../shared/services/util/dataextenso';
import { Environment } from '../../../shared/environment';

interface IFormData {
  idfun: string;
  periodo: string;
  valor:number;
  historico: string;
}

const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'idoperacao' | 'totalgrafificacao'>> = yup.object().shape({
 
  idfun: yup.string().required(),
  valor: yup.number().required().moreThan(0),
  historico: yup.string().required(),
  periodo: yup.string().required().min(1).max(30),
  
 
});

export const DetalheDeGratificacoes: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [urlimg, setUrlimg] = useState('');
  const [nome, setNome] = useState('');

  useEffect(() => {
    
    if (id !== 'novo') {
      setIsLoading(true);

      GratificacoesService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            
            alert(result.message);
            navigate('/gratificacoes');
          } else {
            if (result.urlimg != null) {
              setUrlimg(Environment.URL_IMGFUNCIONARIO+result.urlimg);
            }else{
              setUrlimg('/logo.png');
            } 
            formRef.current?.setData(result);
            setNome(result.nome);
          }
        });
    } else {
      formRef.current?.setData({
        idfun: '',
        valor: 0.00,
        historico: ' ',
        periodo: dataextenso(new Date()),
      });
    }  
  }, [id]);

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          GratificacoesService
            .create(dadosValidados)
            .then((result) => {
              
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/gratificacoes');
                } else {
                  navigate(`/gratificacoes/detalhe/${result}`);
                }
              }
            });
           
        } else {
          GratificacoesService
            .updateById(id, dadosValidados)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/gratificacoes');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };
  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      GratificacoesService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/gratificacoes');
          }
        });
    }
  };

  const handlefoto = (urlimg: string | undefined) => {
    if (urlimg != null) {
      setUrlimg(Environment.URL_IMGFUNCIONARIO+urlimg);
    }else{
      setUrlimg('/logo.png');
    }
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? '  Nova Gratificação' : 'Gratificação'}
      
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando={isLoading}
          mostrarBotaoSalvarEFecharCarregando={isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoImpressao={false}
          mostrarBotaoApagarCarregando={isLoading}
          mostrarBotaoImpressaoCarregando={isLoading}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/gratificacoes')}
          mostrarBotaoVoltarCarregando={isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/gratificacoes/novo')}
          mostrarBotaoNovoCarregando={isLoading}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={2} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={1} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid item>
              <Typography variant='h6'>Geral</Typography>
            </Grid>
            <Grid container item direction="row" spacing={3}>
              <Grid container item direction="row" spacing={2} >
                <Grid item xs={6} sm={6} md={3} lg={2} xl={1.5} >
                  <Box width="100%" marginRight={5} >
                    <Avatar
                      variant='rounded'
                      alt='F'
                      src={urlimg}
                      sx={{ width: '100%', height: 200 }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={10} xl={10.5}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container item direction="row" spacing={2} >
                      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        {id === 'novo' ? (
                          <AutoCompleteFuncionario 
                            isExternalLoading={id === 'novo' ? isLoading : true}
                            urlfoto={handlefoto}
                          />
                        ) : (
                          <TextField
                            fullWidth
                            value={nome}
                            label='Nome do Funcionario'
                            disabled={true}
                          />
                        )}
                        
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <AutoCompletePeriodo isExternalLoading={isLoading} />
                      </Grid>
                    
                      <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
                        <VFieldMoney
                          fullWidth
                          name='valor'
                          label='Valor'
                          disabled={isLoading}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <VTextField
                          fullWidth
                          name='historico'
                          label='Historico'
                          multiline
                          rows={4}
                          disabled={isLoading}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePagina>
  );
};