import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
 
export interface IListagemContasReceber {
  idcontaspagar: string;
  dataoperacao: Date;
  dataemissao: Date;
  vencimento: Date;
  parcela: string;
  nossonumero: string;
  numerodoc: string;
  valornota: number;
  valorpago: number;
  desconto: number;
  acrescimo: number;
  valorparcela: number;
  status: boolean;
  marcado: boolean;
  lcdpr: boolean;
  valorpagar: number
}


async function PDFPagar(dados: IListagemContasReceber[], total: number, selecionado: number, nome: string){
  
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const contapagar = dados.map((pagar) => {
    return [
      {text: pagar.numerodoc, alignment: 'right',  fontSize: 8},
      {text: moment(pagar.dataemissao).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 8},
      {text: moment(pagar.vencimento).format('DD/MM/YYYY'), alignment: 'center',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(pagar.valorparcela), alignment: 'right', fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(pagar.acrescimo), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(pagar.desconto), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(pagar.valorpago), alignment: 'right',  fontSize: 8},
      {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(pagar.valorpagar), alignment: 'right',  fontSize: 8},
    ]; 
  });  

  async function getBase64ImageFromUrl(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = 'Anonymous'; 
      img.onload = function () {
        const canvas: HTMLCanvasElement = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject('Não foi possível obter o contexto 2D');
          return;
        }
        const image = this as HTMLImageElement;
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = function () {
        reject(`Não foi possível carregar a imagem de ${url}`);
      };
      img.src = url;
    });
  }

  const imageUrl =  process.env.REACT_APP_URL_IMGLOG || ''; 
  const imageData = await getBase64ImageFromUrl(imageUrl);

  if (!imageData.startsWith('data:image')) {
    return new Error('A imagem não foi convertida corretamente para base64.');
  }
  
  const documentDefinition = {

    content: [
      {
        style: 'tableExample',
        table: {
          margin: [0, 0, 0, 0],
          widths: [48, 500],
          body: [
            [ 
              {
                image: imageData,
                width: 48,
                border: [false, false, false, false]
              },
              {
                border: [false, false, false, false],
                table: {
                  body: [
                    [ 
                      {text: process.env.REACT_APP_URL_EMPRESA, fontSize: 16,  margin: [0, 5, 0, 0], bold: true, color: process.env.COR_RELATORIOS_TEXTO,  alignment: 'center', fillColor: process.env.COR_RELATORIOS, border: [false, false, false, false]},
                    ],
                    [ 
                      {text: process.env.REACT_APP_URL_ENDERECO, fontSize: 10, border: [false, false, false, true]},
                    ],
                    [ 
                      {text: 'Relatório de Contas a Pagar', margin: [5, 10, 0, 0], fontSize: 18, bold: true,  alignment: 'center',  border: [false, false, false, false]},
                    ],
                  ]
                }
              }
            ],
          ]
        }
      },
      {
        table:{
          margin: [5, 10, 5, 10],
          heights:[10],
          widths: [530],
          body: [
            [
              {text: 'Parceiro: '+ nome, border: [false, false, false, false]},
            ],
            [
              {text: 'Total do Débito: '+new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(total), alignment: 'right',  fontSize: 10, border: [false, false, false, false]},
            ],
          ]
        },
      },
      {
        table:{
          widths: [ 48, 55, 55, 70, 50, 50, 70, 70],
          body: [
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
            ],
            [
              {text: 'Número', alignment: 'right', fillColor: '#dddddd', fontSize: 10},
              {text: 'Emissão', alignment: 'center', fillColor: '#dddddd',  fontSize: 10},
              {text: 'Vencimento', alignment: 'center', fillColor: '#dddddd', fontSize: 10},
              {text: 'Valor Parcela', alignment: 'right', fillColor: '#dddddd',  fontSize: 10},
              {text: 'Acrés.', alignment: 'right', fillColor: '#dddddd', fontSize: 10},
              {text: 'Desconto.', alignment: 'right', fillColor: '#dddddd', fontSize: 10},
              {text: 'Valor Pago', alignment: 'right', fillColor: '#dddddd', fontSize: 10},
              {text: 'Valor Pagar', alignment: 'right', fillColor: '#dddddd', fontSize: 10},
            ],
            ...contapagar,
            [
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]},
              {text: 'Valor Total', border: [false, false, false, false]},
              {text: new Intl.NumberFormat('pt-br', {minimumFractionDigits: 2}).format(selecionado), alignment: 'right',  fillColor: '#dddddd', fontSize: 10, bold: true},
            ]
          ]
          
        },
      },
      
    ]
  };

  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

  pdfDocGenerator.open();

}
export default PDFPagar;

