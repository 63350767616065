import React, { useEffect, useState } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useField } from '@unform/core';

type TVTextFieldProps = TextFieldProps & {
  name: string;
  calcular?: (novoTextoselect: string | undefined) => void;
};

export const VFieldMoney: React.FC<TVTextFieldProps> = ({calcular,  name, ...rest }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

  const [value, setValue] = useState<string>(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => formatValueNumber(value),
      setValue: (_, newValue) => setValue(formatValueString(newValue)),
    });
  }, [registerField, fieldName, value]);

  const formatValueString = (Value: string) =>{
    return  Number(Value).toLocaleString('pt-BR', { minimumFractionDigits: 2});
  };

  const formatValueNumber = (Value: string) => {
    if (Value === undefined || Value === null) {
      return '';  
    }  
    Value = Value.replace(/\D/g, '');
    Value = Value.replace(/(\d)(\d{2})$/, '$1.$2');
    Value = Value.replace(/(?=(\d{3})+(\D))\B/g, '');
    return Value;
  };

  const formatInputValue = (inputValue: string) => {
    inputValue = inputValue.replace(/\D/g, '');
    inputValue = inputValue.replace(/(\d)(\d{2})$/, '$1,$2');
    inputValue = inputValue.replace(/(?=(\d{3})+(\D))\B/g, '.');
    return inputValue;
  };

  return (
    <TextField  
      {...rest}
      error={!!error}
      helperText={error}
      defaultValue={defaultValue}
      value={value || ''}
      onChange={(e) => {
        const inputValue = formatInputValue(e.target.value);
        e.target.value = inputValue;
        setValue(inputValue);
        calcular?.(e.target.value);
        rest.onChange?.(e);
      }}
      onKeyDown={(e) => {if (error) { clearError(); }
        rest.onKeyDown?.(e);
      }}
    />
  );
};
