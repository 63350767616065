import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDebounce } from '../hooks';
import { useField } from '@unform/core';
import { PivotService } from '../services/api/cadastros/PivotService';

type TAutoCompleteOption = {
  idpivot: string;
  label: string;
}

interface IAutoCompleteSubgruposProps {
  isExternalLoading?: boolean;
  idfazenda?: string;
}

export const AutoCompletePivot: React.FC<IAutoCompleteSubgruposProps> = ({ isExternalLoading = false, idfazenda = '' }) => {

  const { fieldName, registerField, defaultValue, error, clearError } = useField('idpivot');

  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue);

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  
  const [busca, setBusca] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
    });
  }, [registerField, fieldName, selectedId]);

  useEffect(() => {
    setSelectedId(undefined);
    setBusca('');
  }, [idfazenda]);

  useEffect(() => {
    
    setIsLoading(true);
    debounce(() => {
  
      PivotService.getAlllist(idfazenda, busca, selectedId)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            //alert(result.message);
          } else {
            setOpcoes(result.data.map(pivot => ({ idpivot: pivot.idpivot, label: pivot.descricao })));
          }
        });

    });

  },[idfazenda, busca, selectedId]);
 
  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.idpivot === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);
  
  return (
    <Autocomplete
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando...'

      disablePortal

      options={opcoes}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue) => { setSelectedId(newValue?.idpivot); setBusca(''); clearError(); }}
      popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}

          label="Pivot"
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
};