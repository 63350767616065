import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

interface OutlinedCardProps {
  nome: string;
  valor: string;
  cor: string;
}

const OutlinedCard01: React.FC<OutlinedCardProps> = ({ nome, valor, cor }) => {
  return (
    <Box sx={{ margin: 1,  minWidth: '200px'}} >
      <Card variant="outlined" sx={{ borderRadius: '8px' }}>
        <CardContent style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '8px', display: 'flex' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '1px', backgroundColor: cor }} sx={{ width: '0.1px', height: '70px' }} />
          </Box>
          <Box sx={{ flex: '1 0 auto', margin: 1 }}>
            <Typography sx={{ fontSize: 18 }} >
              {nome}
            </Typography>
            <Typography sx={{ fontSize: 25 }} color="text.secondary" textAlign= 'end'>
              {valor}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default OutlinedCard01;
