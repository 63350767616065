import { Accordion, AccordionDetails, AccordionSummary, Avatar, Divider, Drawer, Icon, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { Box } from '@mui/system';

import { useAppThemeContext, useAuthContext, useDrawerContext } from '../../contexts';
import { Environment } from '../../environment';
import { DialogPefilFoto } from './DialogPefilFoto';
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { DialogPefil } from './DialogPerfil';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IListItemLinkProps {
  to: string;
  icon: string;
  label: string;
  onClick: (() => void) | undefined;
}
const ListItemLink: React.FC<IListItemLinkProps> = ({ to, icon, label, onClick }) => {
  const navigate = useNavigate();

  const resolvedPath = useResolvedPath(to);
  const match = useMatch({ path: resolvedPath.pathname, end: false });


  const handleClick = () => {
    navigate(to);
    onClick?.();
  };

  return (
    <ListItemButton selected={!!match} onClick={handleClick}>
      <ListItemIcon>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
};

interface IMenuLateralProps {
  children: React.ReactNode
}

export const MenuLateral: React.FC<IMenuLateralProps> = ({ children }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { isDrawerOpen, drawerOptions, drawerOptionsr, toggleDrawerOpen } = useDrawerContext();
  const { toggleTheme } = useAppThemeContext();
  const { logout, logoutsistema } = useAuthContext();
  const [openFoto, setOpenFoto] = useState(false);
  const [openPerfil, setOpenPerfil] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fotoperfil, setFotoperfil] = useState('');
  const [iduser, setIduser] = useState('');
  const open = Boolean(anchorEl);
  
  interface JwtPayload{
    uid: string;
    img: string;
  }

  const handleCloseFoto = () => {
    setOpenFoto(false);
  };

  const handleClosePerfil = () => {
    setOpenPerfil(false);
  };

  const handleFotoPerfil = () => {
    setOpenFoto(true);
    setAnchorEl(null);
  };

  const handlePerfil = () => {
    setOpenPerfil(true);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('APP_ACCESS_TOKEN');
    if (accessToken) {
      const decoded = jwtDecode<JwtPayload>(accessToken);
      setFotoperfil(Environment.URL_IMGUSUARIOS+decoded.img);
      setIduser(decoded.uid);
    }
  }, []);

  return (
    <>
      <Drawer open={isDrawerOpen} variant={smDown ? 'temporary' : 'permanent'} onClose={toggleDrawerOpen}>
        <Box width={theme.spacing(28)} height="100%" display="flex" flexDirection="column">
          <Box width="100%" height={theme.spacing(15)} display="flex" alignItems="center" justifyContent="center">
            <div
              id="avatar-button"
              aria-controls={open ? 'avatar-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              style={{ cursor: 'pointer' }}
            >
              <Avatar 
                sx={{ height: theme.spacing(12), width: theme.spacing(12) }}
                src={fotoperfil}
              />
            </div>
            <Menu
              id="avatar-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose1}
              MenuListProps={{
                'aria-labelledby': 'avatar-button',
              }}
            >
              <MenuItem onClick={handleFotoPerfil}>Foto do Perfil</MenuItem>
              <MenuItem onClick={handlePerfil}>Perfil</MenuItem>
            </Menu> 
          </Box>
          <Divider />

          <Box flex={1}>
            <List  component="nav">
              {drawerOptions.map(drawerOption => (
                <ListItemLink
                  to={drawerOption.path}
                  key={drawerOption.path}
                  icon={drawerOption.icon}
                  label={drawerOption.label}
                  onClick={smDown ? toggleDrawerOpen : undefined}
                />
              ))}
            </List>
          </Box>
          {drawerOptionsr.length > 0 ?  
            <Box flex={1}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography
                  
                  >
                    Relatórios
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List  component="nav">
                    {drawerOptionsr.map(drawerOption => (
                      <ListItemLink
                        to={drawerOption.path}
                        key={drawerOption.path}
                        icon={drawerOption.icon}
                        label={drawerOption.label}
                        onClick={smDown ? toggleDrawerOpen : undefined}
                      />
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
            : ''}
          <Box>
            <List component="nav">
              <ListItemButton onClick={toggleTheme}>
                <ListItemIcon>
                  <Icon>dark_mode</Icon>
                </ListItemIcon>
                <ListItemText primary="Alternar tema" />
              </ListItemButton>
              <ListItemButton onClick={logoutsistema}>
                <ListItemIcon>
                  <Icon>flipcameraandroidoutlinedicon</Icon>
                </ListItemIcon>
                <ListItemText primary="Trocar Sistema" />
              </ListItemButton>
              <ListItemButton onClick={logout}>
                <ListItemIcon>
                  <Icon>logout</Icon>
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </ListItemButton>
            </List>
          </Box>
        </Box>
      </Drawer>
      <DialogPefilFoto
        id={iduser}
        img={fotoperfil}
        value={''}  
        open={openFoto} 
        onClose={handleCloseFoto}
      /> 
      <DialogPefil
        id={iduser}
        value={''}  
        open={openPerfil} 
        onClose={handleClosePerfil}
      /> 
      <Box height="100vh" marginLeft={smDown ? 0 : theme.spacing(28)}>
        {children}
      </Box>
    </>
  );
};