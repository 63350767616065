import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { useDebounce } from '../../shared/hooks';
import { useField } from '@unform/core';
import { VeiculosService } from '../services/api/cadastros/VeiculosService';

type TAutoCompleteOption = {
  idveiculo: string;
  label: string;
}

interface IAutoCompleteVeiculosProps {
  isExternalLoading?: boolean;
  idtransportadora?: string;
}

export const AutoCompleteVeiculos: React.FC<IAutoCompleteVeiculosProps> = ({ isExternalLoading = false, idtransportadora = '' }) => {

  const { fieldName, registerField, defaultValue, error, clearError } = useField('idveiculo');

  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue);

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  
  const [busca, setBusca] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
    });
  }, [registerField, fieldName, selectedId]);

  useEffect(() => {
    setSelectedId(undefined);
    setBusca('');
  }, [idtransportadora]); 

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      VeiculosService.getAllList(idtransportadora, busca, selectedId)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            // alert(result.message);
          } else {
            setOpcoes(result.data.map(veiculos => ({ idveiculo: veiculos.idveiculo, label: veiculos.placa })));
          }
        });
    });
  },[idtransportadora,  busca, selectedId]);
 
  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.idveiculo === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);
  
  return (
    <Autocomplete
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando...'

      disablePortal

      options={opcoes}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue) => { setSelectedId(newValue?.idveiculo); setBusca(''); clearError(); }}
      popupIcon={(isLoading) ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}

          label="Veiculos"
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
};