/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { AutoCompleteFazenda, AutoCompleteProdutor, BasicSelect, IVFormErrors, SelectNumber, VFieldMoney, VForm, VSwitch, VTextField, VTextFieldNumber, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { EntradaService } from '../../../shared/services/api/estoque/entrada/EntradaService';
import { VFieldMoney4Faturamento } from './VFieldMoney4Faturamento';

type NullableDate = Date | null | undefined;

interface IFormDataCabecalho {
  idparceiro: string;
  idplanoconta: string;
  idoperacoes: string;
  dataemissao: Date;
  numeronota: string;
  numerodoc: string;
  subtotal: number;
  desconto: number;
  outrasdespesas: number;
  totalnota: number;
  acrescimo: number;
  obs: string;

  cpf: string;
}

interface IitensEntradaDialog {
  iditens: string;
  idprod: string;
  idlocalizacao: string;
  qtdsaida: number;
  validade?: NullableDate;
  un: string;
  qtdentrada: number;
  conversor: number;
  preco: number;
  total: number;

  filtro: string;
  descricao: string;
  localizacao: string;
  reqvencimento: boolean;
}

interface IParcelasFaturamento {
  idcontaspagar: string;
  idparceiro: string;
  idplanoconta: string;
  idprodutor: number;
  dataemissao: Date;
  numerodoc: string;
  numeronfe: string;
  valornfe: number;
  valornota: number;
  historico: string;
  lcdpr: boolean;
  tipodoc: number;
  tipopagamento: string;
  historicolcdpr: string;
  vencimento: Date;
  valorparcela: number;
  nfeparcela: number;
  parcela: string;
  nfepago: number;
  nfepagar: number;
  valorpago: number;
  valorpagar: number;
  tipo: number;
  desconto: number;
  acrescimo: number;
  idfazenda: string;

  qtdParcela: number;
}

interface IItensids {
  iditens: string;
}


export interface ConfirmationDialogRawProps {
  id: string;
  value: string;
  open: boolean;
  status: boolean;
  operacao?: string;
  dados?: IFormDataCabecalho;
  dadosItens?: IitensEntradaDialog[];
  iddeletados?: IItensids[];
  onClose: (value?: string) => void; //
}

const formValidationSchema: yup.ObjectSchema<Omit<IParcelasFaturamento, 'idcontaspagar' | 'idparceiro' | 'idplanoconta' | 'nfeparcela' | 'nfepago' | 'valorpago' | 'dataemissao' | 'parcela' | 'valorparcela' | 'vencimento' | 'nfepagar' | 'valorpagar' | 'tipo' | 'desconto' | 'acrescimo'>> = yup.object().shape({
  idprodutor: yup.number().optional().default(0).when('lcdpr', {
    is: true,
    then: () => yup.number().required('Campo obrigatório')
  }),
  idfazenda: yup.string().required('Campo obrigatório'),
  numerodoc: yup.string().required(),
  numeronfe: yup.string().optional().default('').when('lcdpr', {
    is: true,
    then: () => yup.string().required('Campo obrigatório')
  }),
  valornfe: yup.number().optional().default(0).when('lcdpr', {
    is: true,
    then: () => yup.number().required('Campo obrigatório').moreThan(0)
  }),
  valornota: yup.number().required().moreThan(0),
  historico: yup.string().required(),
  lcdpr: yup.boolean().required().default(true),
  tipodoc: yup.number().optional().default(0).when('lcdpr', {
    is: true,
    then: () => yup.number().required('Campo obrigatório')
  }),
  tipopagamento: yup.string().required(),
  historicolcdpr: yup.string().optional().default('').when('lcdpr', {
    is: true,
    then: () => yup.string().required('Campo obrigatório')
  }),
  qtdParcela: yup.number().required().moreThan(0),
});

export const DialogFaturamento: React.FC<ConfirmationDialogRawProps> = (props: ConfirmationDialogRawProps) => {

  const { formRef, save, } = useVForm();
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState('');
  const [lista, setLista] = useState<IParcelasFaturamento[]>([]);
  const [atualizarLista, setAtualizarLista] = useState<{ idcontaspagar: string; valorparcela: number; }[]>([]);

  const itensnovo = {
    numerodoc: props.dados?.numerodoc,
    numeronfe: props.dados?.numeronota,
    valornfe: 0,
    valornota: props.dados?.totalnota,
    historico: props.operacao,
    lcdpr: true,
    tipopagamento: '',
    historicolcdpr: '',
    vencimento: '',
    qtdParcela: 1,
    intervalo: 0,
  };

  useEffect(() => {
    formRef.current?.setData(itensnovo);
    setQtdParcelas(1);
    setIntervalo(0);
  }, [id, formRef]);

  const [valornfe, setvalornfe] = useState(0);
  const [valornota, setvalornota] = useState(props.dados?.totalnota);
  const [idprodutor, setIdprodutor] = useState(0);
  const [qtdParcelas, setQtdParcelas] = useState(1);
  const [intervalo, setIntervalo] = useState(0);
  const [tipopagamento, setTipopagamento] = useState('');
  const [tipodoc, setTipodoc] = useState(0);
  const [historicolcdpr, sethistoricolcdpr] = useState('');
  const [lcdpr, setLcdpr] = useState<boolean>(true);
  const [fazenda, setFazenda] = useState('');

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    } else {
      setId(valueProp);
    }
    setvalornota(props.dados?.totalnota);
  }, [valueProp, open]);

  const handleVnfe = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\./g, '');
      valor = valor.replace(/,/g, '.');
      valor = valor.replace(/(\d)(\d{4})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      setvalornfe(Number(valor));
    }
  };

  const handleVnota = (valor: string | undefined) => {
    if (valor != null) {
      valor = valor.replace(/\./g, '');
      valor = valor.replace(/,/g, '.');
      valor = valor.replace(/(\d)(\d{4})$/, '$1.$2');
      valor = valor.replace(/(?=(\d{3})+(\D))\B/g, '');
      setvalornota(Number(valor));
    }
  };

  const handleIdProdutor = (valor: number | undefined) => {
    if (valor != null) {
      setIdprodutor(valor);

    }
  };

  const handleFazenda = (valor: string | undefined) => {
    if (valor != null) {
      setFazenda(valor);
    }
  };

  const handleQtdParcelas = (valor: string | undefined) => {
    if (valor != null) {
      setQtdParcelas(Number(valor));
    }
  };

  const handleIntervalo = (valor: string | undefined) => {
    if (valor != null) {
      setIntervalo(Number(valor));
    }
  };

  const handleTipopagamento = (valor: string | undefined) => {
    if (valor != null) {
      setTipopagamento(valor);
    }
  };

  const handleIdTipodoc = (valor: string | undefined) => {
    if (valor != null) {
      setTipodoc(Number(valor));
    }
  };

  const handlehistoricolcdpr = (valor: string | undefined) => {
    if (valor != null) {
      sethistoricolcdpr(valor);
    }
  };

  const handleLcdpr = (valor: boolean | undefined) => {
    if (valor != null) {
      setLcdpr(valor);
    }
  };

  useEffect(() => {
    setLista([]);
    const valorparcelanfe = valornfe! / qtdParcelas;
    const valorparcela = valornota! / qtdParcelas;
    for (let i = 0; i < qtdParcelas; i++) {
      const dataVencimento = new Date();
      if (intervalo != 0) {
        dataVencimento.setDate(dataVencimento.getDate() + intervalo * i);
      } else {
        const newMonth = dataVencimento.getMonth() + i;
        dataVencimento.setMonth(newMonth);
      }
      const ops = (
        {
          idcontaspagar: uuidv4(),
          idparceiro: props.dados?.idparceiro || '',
          idplanoconta: props.dados?.idplanoconta || '',
          idprodutor: idprodutor,
          dataemissao: props.dados?.dataemissao || new Date(),
          numerodoc: props.dados?.numerodoc || '',
          numeronfe: props.dados?.numeronota || '',
          valornfe: valornfe!,
          valornota: props.dados?.totalnota || 0,
          historico: props.operacao || '',
          lcdpr: lcdpr!,
          tipodoc: tipodoc,
          tipopagamento: tipopagamento,
          historicolcdpr: historicolcdpr,
          vencimento: dataVencimento,
          valorparcela: valorparcela,
          nfeparcela: valorparcelanfe,
          parcela: `${i + 1}/${qtdParcelas}`,
          nfepago: 0,
          valorpago: 0,
          nfepagar: valorparcelanfe,
          valorpagar: valorparcela,
          tipo: 1,
          desconto: 0,
          acrescimo: 0,
          qtdParcela: 0,
          idfazenda: fazenda
        });
      setLista((oldLista) => [...oldLista, ops]);
    }
  }, [
    valornota,
    tipopagamento,
    qtdParcelas,
    intervalo,
    valornfe,
    tipodoc,
    idprodutor,
    fazenda,
    historicolcdpr,
    lcdpr,
    open
  ]);

  const formatValue = (inputValue: string | number): string => {
    let formattedValue: string;

    if (typeof inputValue === 'number') {
      inputValue = inputValue.toString();
    }
    // Caso seja um número inteiro
    if (!inputValue.includes('.')) {
      formattedValue = inputValue.replace(/\D/g, ''); // Remove caracteres não numéricos
      formattedValue += ',0000'; // Adiciona a vírgula e 4 zeros
      formattedValue = formattedValue.replace(/(\d)(?=(\d{3})+(,))/g, '$1.'); // Adiciona pontos como separadores de mil
    } else {
      // Caso seja um número decimal
      formattedValue = inputValue.replace(/\./g, ','); // Substitui ponto por vírgula
      formattedValue = formattedValue.replace(/(\d+,\d{0,4})\d*/, '$1'); // Limita a 4 casas decimais
      formattedValue = formattedValue.replace(/(\d)(?=(\d{3})+(,))/g, '$1.'); // Adiciona pontos como separadores de mil
    }

    return formattedValue;
  };

  const handleSave = (dados: IParcelasFaturamento) => {

    const totalSum = lista.reduce((accumulator, currentItem) => accumulator + currentItem.valorparcela, 0);
    if (totalSum.toFixed(2) === props.dados?.totalnota.toFixed(2).toString()) {
      formValidationSchema
        .validate(dados, { abortEarly: false })
        .then((dadosValidados) => {
          setIsLoading(true);
          if (props.id === 'novo') {
            EntradaService.create(props.dados!, props.dadosItens!, lista).then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
                onClose();
              } else {
                alert('Lançamento realizado com sucesso!');
                onClose(result);
              }
            });
            setIsLoading(false);
          } else {
            EntradaService
              .updateById(props.id, props.dados!, props.dadosItens!, props.iddeletados!, lista)
              .then((result) => {
                setIsLoading(false);

                if (result instanceof Error) {
                  alert(result.message);
                } else {
                  onClose();
                }
              });
          }
        })
        .catch((errors: yup.ValidationError) => {
          const validationErrors: IVFormErrors = {};
          errors.inner.forEach((error) => {
            if (!error.path) return;
            validationErrors[error.path] = error.message;
          });
          formRef.current?.setErrors(validationErrors);
        });
    } else {
      alert('Valor Total das Parçelas não Confere: R$ ' + formatValue(String(totalSum)));
    }
  };

  const handleInputChange = useCallback((id: string, valor: string) => {
    const updatedList = lista.map((listItem) => {
      if (listItem.idcontaspagar === id) {
        const novoObjeto = { idcontaspagar: id, valorparcela: Number(valor) };
        setAtualizarLista(prevLista => [...prevLista, novoObjeto]);

        return {
          ...listItem,
          valorpagar: Number(valor),
          valorparcela: Number(valor),
        };
      } else {

        return listItem;
      }
    });

    setLista(updatedList);
  }, [lista]);

  const handleInputChangeDate = useCallback((id: string, valor: Date) => {
    const updatedList = lista.map((listItem) => {
      if (listItem.idcontaspagar === id) {
        return {
          ...listItem,
          vencimento: valor,
        };
      } else {

        return listItem;
      }
    });
    setAtualizarLista(updatedList);
    setLista(updatedList);
  }, [lista]);

  return (
    <Dialog
      open={open}
      {...other}
      sx={{ width: '100%' }}
      maxWidth="md"
    >
      <DialogTitle>Faturamento</DialogTitle>
      <DialogContent dividers>
        <VForm ref={formRef} onSubmit={handleSave}>
          <Grid container direction="column" padding={1} >
            <Grid container item direction="row" padding={1} spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container item direction="row" spacing={1} >

                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <VTextFieldNumber
                      fullWidth
                      name='numerodoc'
                      label='Nº Documento'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <VFieldMoney
                      fullWidth
                      name='valornota'
                      label='Valor Nota'
                      calcular={handleVnota}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <BasicSelect
                      name='tipopagamento'
                      label='Forma de Pagamento'
                      data={[
                        { nameBD: 'Boleto', descricao: 'Boleto' },
                        { nameBD: 'Carteira', descricao: 'Carteira' },
                        { nameBD: 'Cartão Agro-Negócio', descricao: 'Cartão Agro-Negócio' },
                        { nameBD: 'Depósito', descricao: 'Depósito' },
                        { nameBD: 'Débito em conta', descricao: 'Débito em conta' },
                        { nameBD: 'pix', descricao: 'PIX' },
                      ]}
                      aoClicar={handleTipopagamento}
                      disabled={props.status === false ? isLoading : true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={4} xl={4}>
                    <VTextFieldNumber
                      fullWidth
                      name='qtdParcela'
                      label='Qtd Parçela'
                      calcular={handleQtdParcelas}
                      disabled={props.status === false ? isLoading : true}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={2} lg={4} xl={4}>
                    <VTextFieldNumber
                      fullWidth
                      name='intervalo'
                      label='Intervalo'
                      calcular={handleIntervalo}
                      disabled={props.status === false ? isLoading : true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={4} xl={4}>
                    <VTextField
                      fullWidth
                      name='historico'
                      label='Histórico'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <AutoCompleteFazenda
                      isExternalLoading={props.status === false ? isLoading : true}
                      uuidfazenda={handleFazenda}
                    />
                  </Grid>

                  <Box style={{ backgroundColor: '#F7F7F7' }}>
                    <Grid container item direction="row" padding={1} spacing={1}>
                      <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                        <VTextFieldNumber
                          fullWidth
                          name='numeronfe'
                          label='Nº NF-e'
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                        <VFieldMoney
                          fullWidth
                          name='valornfe'
                          label='Valor NF-e'
                          calcular={handleVnfe}
                          disabled={props.status === false ? isLoading : true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <SelectNumber
                          name='tipodoc'
                          label='Tipo de Documento'
                          data={[
                            { nameBD: 1, descricao: 'Nota Fiscal' },
                            { nameBD: 2, descricao: 'Fatura' },
                            { nameBD: 3, descricao: 'Recibo' },
                            { nameBD: 4, descricao: 'Contrato' },
                            { nameBD: 5, descricao: 'Folha de Pagamento' },
                            { nameBD: 6, descricao: 'Outros' },
                          ]}
                          valor={handleIdTipodoc}
                          disabled={props.status === false ? isLoading : true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <AutoCompleteProdutor
                          isExternalLoading={props.status === false ? isLoading : true}
                          id={handleIdProdutor}
                        />
                      </Grid>


                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <VTextField
                          fullWidth
                          name='historicolcdpr'
                          label='Histórico LCDPR'
                          valor={handlehistoricolcdpr}
                          disabled={props.status === false ? isLoading : true}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                        <VSwitch
                          name='lcdpr'
                          label='LCDPR'
                          valor={handleLcdpr}
                          fdisabled={props.status === false ? isLoading : true} />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VForm>
        <List sx={{ pt: 0 }}>
          <DialogTitle>Parçelas</DialogTitle>
          {lista.map((listItem) => (
            <ListItem disableGutters key={listItem.idcontaspagar}>
              <Grid container direction="column" padding={1} >
                <Grid container item direction="row" padding={1} spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container item direction="row" spacing={1} >
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format='DD/MM/YYYY'
                            value={dayjs(listItem.vencimento)}
                            label="Vencimento"
                            onChange={(newValue) => {
                              const inputValue = newValue!.toDate();
                              handleInputChangeDate(listItem.idcontaspagar, inputValue);
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                        <TextField
                          value={listItem.numerodoc}
                          label="Nº do Documento"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                        <TextField
                          value={listItem.parcela}
                          label="Nº da Parcela"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                        <VFieldMoney4Faturamento
                          id={listItem.idcontaspagar}
                          valor={handleInputChange}
                          label="Valor"
                          defaultValue={String(listItem.valorparcela)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='error' onClick={handleCancel}>Cancelar</Button>
        <Button variant='outlined' disabled={props.status === false ? isLoading : true} autoFocus onClick={save}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
}; 