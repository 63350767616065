import { Box, Button, Icon, MenuItem, Paper, Select, TextField, useTheme } from '@mui/material';
import { Environment } from '../../environment';

interface IDadosSelect {
  nameBD: string;
  descricao: string;
}

interface IFerramentasDaListagemProps {
  dadosSelecao?: IDadosSelect[];
  textoDaBusca?: string;
  textoDoSelect?: string;
  mostrarInputBusca?: boolean;
  aoMudarTextoDeBusca?: (novoTexto: string) => void;
  aoMudarTextoDoSelect?: (novoTextoselect: string) => void;
  textoBotaoNovo?: string;
  mostrarBotaoNovo?: boolean;
  mostrarInputBasicSelect?: boolean
  aoClicarEmNovo?: () => void;
}
export const FerramentasDaListagemCadastros: React.FC<IFerramentasDaListagemProps> = ({
  textoDaBusca = '',
  textoDoSelect = '',
  aoMudarTextoDeBusca,
  aoMudarTextoDoSelect,
  mostrarInputBusca = false,
  mostrarInputBasicSelect = false,
  aoClicarEmNovo,
  textoBotaoNovo = 'Novo',
  mostrarBotaoNovo = true,
  dadosSelecao =[],
}) => {
  const theme = useTheme();
 
  
  return (
    
    <Box
      gap={1}
      marginX={1}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(5)}
      component={Paper}
    >
      {mostrarInputBasicSelect && (
        <Select
          size="small" sx={{ minWidth: 130 }}
          value={textoDoSelect}
          onChange={(event) => aoMudarTextoDoSelect?.(event.target.value)}
        >
          {dadosSelecao.map((item) => (
            <MenuItem key={item.nameBD} value={item.nameBD}>
              {item.descricao}
            </MenuItem>
          ))}
        </Select>
      )}

      {mostrarInputBusca && (
        <TextField
          size="small"
          value={textoDaBusca}
          placeholder={Environment.INPUT_DE_BUSCA}
          onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
        />
      )} 


      <Box flex={1} display="flex" justifyContent="end">
        {mostrarBotaoNovo && (
          <Button
            color='primary'
            disableElevation
            variant='contained'
            onClick={aoClicarEmNovo}
            endIcon={<Icon>add</Icon>}
          >{textoBotaoNovo}</Button>
        )}
      </Box>
    </Box>
  );
};