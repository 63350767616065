/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { Checkbox, Icon, IconButton, LinearProgress, Pagination, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LayoutBaseDeFinanceiro, } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import dayjs, { Dayjs } from 'dayjs';
import { FerramentasDaListagemFinanceiroCheque } from '../../../shared/components';
import { v4 as uuidv4 } from 'uuid';
import { DialoBaixaCheque } from './DialogBaixa';
import { ChequeService, IListagemCheque } from '../../../shared/services/api/Financeiro/Cheque';
import PDFCheque from './ImpressaoCheque';

export const ListagemCheque: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();     
  const [rows, setRows] = useState<IListagemCheque[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [istexto, setIsTexto] = useState(false);
  const [isdate, setIsDate] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [selecionado, setSelecionado] = useState(0);
  const [openBaixa, setOpenBaixa] = useState(false);
  const [dataInicial, setDataInicial] = useState(''); 
  const [dataFinal, setDataFinal] = useState(''); 

    
  const guuid = uuidv4();   
    
  const busca = useMemo(() => {
    return searchParams.get('busca') || '';
  }, [searchParams]);  

  const basicselect = useMemo(() => {
    return searchParams.get('basicselect') || 'data'; 
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);  
  
  useEffect(() => {
    if(basicselect === 'data'){
      setIsTexto(false);
      setIsDate(true);
    }else{
      setIsTexto(true);
      setIsDate(false);
    }
  }, [basicselect]);

  useEffect(() => {
    if(basicselect === 'data'){
      if(dataInicial !== ''){
        setIsLoading(true);  
        ChequeService.getAll(1, '', 'data', dataInicial, dataFinal)
          .then((result) => {
            setIsLoading(false);  
            if (result instanceof Error) {
              alert(result.message);
            } else { 
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      }
    }else{
      setIsLoading(true);  
      debounce(() => {  
        ChequeService.getAll(pagina, busca, 'numero', '', '')
          .then((result) => {
            setIsLoading(false);  
            if (result instanceof Error) {
              alert(result.message);
            } else { 
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }   

  }, [pagina]);  
 
  useEffect(() => {  
    if(istexto){
      setIsLoading(true);  
      debounce(() => {  
        ChequeService.getAll(1, busca, 'numero', '', '')
          .then((result) => {
            setIsLoading(false);  
            if (result instanceof Error) {
              alert(result.message);
            } else { 
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }else{
      setIsLoading(false);  
      setRows([]);
      setTotalCount(0);
    }
  }, [busca]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    const index = rows.findIndex(row => row.idcheque === id);
    if (index === -1) return; 
    const updatedRow = { ...rows[index], marcado: checked };
    const updatedRows = [...rows.slice(0, index), updatedRow, ...rows.slice(index + 1)];
    setRows(updatedRows);
    const valueToUpdate = checked ? updatedRow.valor : -updatedRow.valor;
    setSelecionado(currentSelecionado => currentSelecionado + valueToUpdate);
  };

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const updatedRows = rows.map(row => ({ ...row, marcado: checked }));
    setRows(updatedRows);
    if (checked) {
      const totalSelecionado = updatedRows.reduce((acc, row) => acc + row.valor, 0);
      setSelecionado(totalSelecionado);
    } else {
      setSelecionado(0);
    }
  };
           
  const handleCloseBaixa = (newValue?: string) => {
    setOpenBaixa(false);  
    if (newValue === 'true') {
      setIsLoading(true);  
      ChequeService.getAll(1, '', 'data', dataInicial, dataFinal)
        .then((result) => {
          setIsLoading(false);  
          if (result instanceof Error) {
            alert(result.message);
          } else { 
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    }
  };  

  const handlePesquisa = (datainicial: Dayjs | null, datafinal: Dayjs | null) => {
    const formattedDateInicial = datainicial ? dayjs(datainicial).format('DD/MM/YYYY') : '';
    const formattedDateFinal = datafinal ? dayjs(datafinal).format('DD/MM/YYYY') : '';
    setDataInicial(formattedDateInicial);
    setDataFinal(formattedDateFinal);
    setIsLoading(true);  
    ChequeService.getAll(1, '', 'data', formattedDateInicial, formattedDateFinal)
      .then((result) => {
        setIsLoading(false);  
        if (result instanceof Error) {
          alert(result.message);
        } else { 
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });
  };  

  const handlePrint = () => {
    if(totalCount != 0){
      const filteredList = rows.filter((item) => item.marcado === true);
      PDFCheque(filteredList, selecionado);
    }   
  };  


  const handleBaixa = () => {
    if(totalCount != 0){
      const filteredList = rows.filter((item) => item.marcado === true);
      setRows(filteredList);
      setOpenBaixa(true);
    }
  };

  const handleDesc = (dados?: IListagemCheque[]) => {  
    if (dados) {
      setRows(dados);
    }
  };

  return (
    <LayoutBaseDeFinanceiro
      mostraCardTotal={false}
      titulo='Cheque de Cliente'
      labelSelecionado='Selecionado'
      labeltotal='Total Pagar'
      total={''}
      selecionado={selecionado.toLocaleString('pt-BR', { minimumFractionDigits: 2})}
      barraDeFerramentas={
        <FerramentasDaListagemFinanceiroCheque
          mostrarInputBusca={istexto}
          mostrarInputData={isdate}
          mostrarInputBasicSelect
          textoDaBusca={busca}
          textoDoSelect={basicselect}
          aoClicarEmBaixa={handleBaixa}
          aoClicarEmImpressao={handlePrint}
          aoClicarEmPesquisa={handlePesquisa}
          aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, basicselect: basicselect, pagina: '1' }, { replace: true })}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ busca: '', basicselect: textoselect, pagina: '1' }, { replace: true })}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto', marginRight: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ maxWidth: 10, width:10 }}> <Checkbox checked={rows.length > 0 && rows.every(row => row.marcado)} onChange={handleSelectAllChange} /></TableCell>
              <TableCell sx={{ maxWidth: 110, width:110 }} align='left' >Numero Cheque</TableCell>
              <TableCell sx={{ maxWidth: 100, width:100 }}>Data Operação</TableCell>
              <TableCell sx={{ maxWidth: 100, width:100 }}>Data Deposito</TableCell>
              <TableCell align='center' >Agencia</TableCell>
              <TableCell align='center' >Conta</TableCell>
              <TableCell align='left' >Cliente</TableCell>
              <TableCell align='left' >Titular</TableCell>
              <TableCell align='center' >Status</TableCell>
              <TableCell align='right' >Valor</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead> 
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idcheque} 
                hover
                sx={{ cursor: 'pointer' }}  
              >
                <TableCell> {row.status == 1 ? <Checkbox  checked={row.marcado} id={row.idcheque} onChange={handleChange}/> : 'X' }</TableCell>
                <TableCell sx={{ maxWidth: 110, width:110 }} align='center'>{row.numero}</TableCell>
                <TableCell align='center'>{dayjs(row.dataoperacao).format('DD/MM/YYYY')}</TableCell>
                <TableCell align='center'>{dayjs(row.datadeposito).format('DD/MM/YYYY')}</TableCell>
                <TableCell sx={{ maxWidth: 110, width:110 }} align='center'>{row.agencia}</TableCell>
                <TableCell sx={{ maxWidth: 110, width:110 }} align='center'>{row.conta}</TableCell>
                <TableCell align='left'>{row.nome}</TableCell>
                <TableCell align='left'>{row.titular}</TableCell>
                <TableCell sx={{ maxWidth: 110, width:110 }} align='center'>{row.tipostatus}</TableCell>
                <TableCell align='right'>{row.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                {/* <TableCell >
                  <IconButton size='large'  onClick={() => navigate(`/chequeemitidos/detalhe/${row.idcheque}`)}>
                    <Icon >edit</Icon>
                  </IconButton>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody> 
   
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination  
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <DialoBaixaCheque
        value={guuid}
        open={openBaixa} 
        onClose={handleCloseBaixa}
        dados={rows}
        onDados={handleDesc}
      /> 
    </LayoutBaseDeFinanceiro>
  ); 
};