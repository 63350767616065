import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useDebounce } from '../hooks';
import { LocalizacaoService } from '../services/api/estoque/localizacao/LocalizacaoService';


type TAutoCompleteOption = {
  idlocalizacao: string;
  label: string;
}

interface IAutoCompleteLocalizacaoProps {
  isExternalLoading?: boolean;
  localizacaoText?: (novoTextoselect: string) => void;  
  idLocalizacao?: (novoTextoselect: string) => void; 
}
export const AutoCompleteLocalizacao01: React.FC<IAutoCompleteLocalizacaoProps> = ({ isExternalLoading = false, localizacaoText, idLocalizacao }) => {

  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<string | undefined>();

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');

  useEffect(() => {
    setIsLoading(true);

    debounce(() => {
      LocalizacaoService.getAllList(busca)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            // alert(result.message);
          } else {
            setOpcoes(result.data.map(localizacao => ({ idlocalizacao: localizacao.idlocalizacao, label: localizacao.descricao, idLocalizacao: localizacao.idlocalizacao })));
          }
        });
    });
  }, [busca, selectedId]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.idlocalizacao === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);


  return (
    <Autocomplete
      openText='Abrir' 
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando...'
      fullWidth
      disablePortal
      options={opcoes}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue) => { setSelectedId(newValue?.idlocalizacao); idLocalizacao?.(newValue?.idlocalizacao ?? ''); localizacaoText?.(newValue?.label ?? ''); setBusca(''); }}
      popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}

          label="Localização"
        />
      )}
    />
  );
};