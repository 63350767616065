/* eslint-disable @typescript-eslint/no-unused-vars */
import {  Box, Button, DialogActions, DialogContent, DialogTitle, Grid,  LinearProgress,  Paper } from '@mui/material';
import { useState } from 'react';
import { AutoCompleteCultivo, AutoCompleteFazenda,  AutoCompleteOperacoes, AutoCompleteOperacoes01, IVFormErrors, VForm, VSwitch, VTextFieldData, useVForm } from '../../../shared/forms';
import * as yup from 'yup';
import { RelatorioEstoqueService } from '../../../shared/services/api/estoque/Relatorios/RelatoriosService';
import ROperacaoSaidaDetalhes from './ROperacoesSaidaDetalhes';
import ROperacoesSaidaResumido from './ROperacoesSaidaResumido';

interface IFormData {
  idoperacoes: string;
  idfazenda: string;
  idcultivo: string;
  datainicial: Date;              
  datafinal: Date;
  detalhes: boolean;
}  

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idoperacoes:yup.string().required(), 
  idfazenda: yup.string().required(),
  idcultivo: yup.string().optional().default(''),
  datainicial: yup.date().required(),       
  datafinal: yup.date().required(),                 
  detalhes: yup.boolean().required(),   
});
    
export const RelatoriosOperacoes: React.FC = () => {            
   
  const [isLoading, setIsLoading] = useState(false);   
  const {formRef, save, } = useVForm();     
  const [operacoes, setOperacao] = useState('');      
      
  const handleSave = (dados: IFormData) => {   
    formValidationSchema.      
      validate(dados, { abortEarly: false })        
      .then((dadosValidados) => {       
        RelatorioEstoqueService       
          .Operacoes(dadosValidados)   
          .then((result) => {           
            setIsLoading(false);     
            if (result instanceof Error) {  
              alert(result.message);
            }else {
              if(dados.detalhes){
                ROperacaoSaidaDetalhes(result, operacoes);  
              }else{
                ROperacoesSaidaResumido(result, operacoes);  
              }   
            }     
          });    
      })  
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;  
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);   
      });  
  };    

  const handleOperacoes = (descricao: string | undefined) => {
    if (descricao != null) {
      setOperacao(descricao);
    }
  };
          
  return (
    <Box  alignItems="center" width='100%' alignContent='center' height="100%"  display="flex" flexDirection="column"  >
      <Box gap={1}  padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='80%' height="20%" margin={1}  alignContent='center'></Box>
      <Box   width='50%' alignItems="center"  component={Paper} height="40%" margin={1} 
        alignContent='center'>       
        <DialogTitle>Relatório de Operações do Estoque</DialogTitle>
        <DialogContent  dividers>
          <VForm ref={formRef}  onSubmit={handleSave}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />  
              </Grid>                   
            )}   
            <Grid container  padding={1} item direction="row" gap={2}>
              <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <AutoCompleteOperacoes01
                    operacao={handleOperacoes} 
                  />             
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={8}>  
                  <AutoCompleteFazenda
                  />   
                </Grid>  
                <Grid item xs={6} sm={6} md={6} lg={6} xl={4}> 
                  <AutoCompleteCultivo    
                  />   
                </Grid>       
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <VTextFieldData
                    fullWidth
                    name='datainicial'  
                    label='Data Inicial'
                    disabled={isLoading}
                  />        
        
                </Grid>  
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <VTextFieldData  
                    fullWidth
                    name='datafinal'    
                    label='Data Final'      
                    disabled={isLoading}          
                  />    
                </Grid>    
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <VSwitch    
                    name='detalhes'      
                    label='Detalhado'      
                    fdisabled={isLoading}/>                
                </Grid>          
              </Grid>        
            </Grid>         
          </VForm>           
        </DialogContent>     
        <DialogActions>
          <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={save}>Visualizar</Button>
        </DialogActions>
      </Box>
    </Box>
  );
};   