import {  Box, DialogContent, DialogTitle, Grid,  LinearProgress,  Paper } from '@mui/material';
import { useState } from 'react';
import { AutoCompleteGrupoDeProdutos01, AutoCompleteLocalizacao01, AutoCompleteSubgrupoProdutos01 } from '../../../shared/forms';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { RelatorioEstoqueService } from '../../../shared/services/api/estoque/Relatorios/RelatoriosService';
import RLocalizacao from './RLocalizacao';

export const RelatoriosLocalizacao: React.FC = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [idGrupo, setIdGrupo] = useState(''); 
  const [idLocalizacao, setIdLocalizacao] = useState(''); 
  const [idSubGrupo, SetIdSubGrupo] = useState(''); 
  const [grupo, setGrupo] = useState(''); 
  const [localizacao, setLocalizacao] = useState(''); 
  const [subGrupo, SetSubGrupo] = useState(''); 
 
  
  const handleuuidgrupo = (uuid: string | undefined) => {
    if (uuid != null) {
      setIdGrupo(uuid);
    }
  };

  const handleuuidgrupotext = (descricao: string | undefined) => {
    if (descricao != null) {
      setGrupo(descricao);
    }
  };

  const handleuuidLocalizacao = (uuid: string | undefined) => {
    if (uuid != null) {
      setIdLocalizacao(uuid);
    }
  }; 

  const handleuuidLocalizacaotext = (descricao: string | undefined) => {
    if (descricao != null) {
      setLocalizacao(descricao);
    }
  }; 

  const handleuuidsubgrupo = (uuid: string | undefined) => {
    if (uuid != null) {
      SetIdSubGrupo(uuid);
    }
  };

  const handleuuidsubgrupotext = (descricao: string | undefined) => {
    if (descricao != null) {
      SetSubGrupo(descricao);
    }
  };

  
  const handleVisualizarLocalizacao = () => {
    if(idLocalizacao === ''){
      alert('Informe uma Localização');
    }else{
      setIsLoading(true);
      RelatorioEstoqueService
        .Localizacao(idLocalizacao, 'localizacao', '', '')
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            RLocalizacao(result, localizacao, '', '');
          }
        });  
    }

    
  };

  const handleVisualizargrupo = () => {
    if(idGrupo === ''){
      alert('Informe uma Grupo');
    }else{
      setIsLoading(true);
      RelatorioEstoqueService
        .Localizacao(idLocalizacao, 'grupo', idGrupo, '')
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            RLocalizacao(result, localizacao, grupo, '');
          }
        });
    }
    
  };

  const handleVisualizarsubgrupo = () => {
    if(idSubGrupo === ''){
      alert('Informe uma SubGrupo');
    }else{
      setIsLoading(true);
      RelatorioEstoqueService
        .Localizacao(idLocalizacao, 'subgrupo', idGrupo, idSubGrupo)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            RLocalizacao(result, localizacao, grupo, subGrupo);
          }
        });
    }
  };

  return (
    <Box  alignItems="center" width='100%' alignContent='center' height="100%"  display="flex" flexDirection="column"  >
      <Box gap={1}  padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='80%' height="20%" margin={1}  alignContent='center'></Box>
      <Box   width='50%' alignItems="center"  component={Paper} height="40%" margin={1} 
        alignContent='center'>       
        <DialogTitle>Relatório de Localização</DialogTitle>
        <DialogContent  dividers>
          {isLoading && (
            <Grid item>
              <LinearProgress variant='indeterminate' />
            </Grid>
          )}
          <Grid container  padding={1} item direction="row" gap={2}>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={11}>
                <AutoCompleteLocalizacao01
                  idLocalizacao={handleuuidLocalizacao}
                  localizacaoText={handleuuidLocalizacaotext}
                /> 
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: 55 }} color='primary'  onClick={() => {handleVisualizarLocalizacao();}} />
              </Grid>
            </Grid> 
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={11}>
                <AutoCompleteGrupoDeProdutos01
                  uuidgrupo={handleuuidgrupo}   
                  grupo={handleuuidgrupotext}  
                />   
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: 55 }} color='primary'  onClick={() => {handleVisualizargrupo();}} />
              </Grid>
            </Grid>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={10} sm={10} md={10} lg={10} xl={11}>
                <AutoCompleteSubgrupoProdutos01
                  idgrupo={idGrupo}
                  uuidsubgrupo={handleuuidsubgrupo}
                  subgrupo={handleuuidsubgrupotext}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: 55 }} color='primary'  onClick={() => {handleVisualizarsubgrupo();}} />
              </Grid>
            </Grid>     
          </Grid>
        </DialogContent>   
      </Box>
    </Box>
  );
};