/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField,  VForm, useVForm, IVFormErrors, SelectNumber, AutoCompleteProdutor, AutoCompleteFazenda, VTextFieldData, VFieldMoney, VTextFieldCpf, cpfValidator, cnpjValidator, AutoCompleteParceiro} from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { LCDPRService } from '../../../shared/services/api/fiscal/LCDPR/LCDPRService';
import { AutoCompleteContaBancaria } from '../../../shared/forms/AutoCompleteContaBancaria';


interface IFormData {
  idprodutor: string;
  idfazenda: string;
  idconta: string;
  datacaixa: Date;
  dataemissao: Date;
  numero: string;
  tipodoc: number;
  tipolan: number;
  idparceiro?: string | null;
  entrada: number;
  saida: number;
  historico: string;

  idparticipante: string;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idprodutor: yup.string().required(),
  idfazenda: yup.string().required(),
  idconta: yup.string().required(),
  datacaixa: yup.date().required(),
  dataemissao: yup.date().required(),
  numero: yup.string().required(),
  tipodoc: yup.number().required(),
  tipolan: yup.number().required(),
  idparceiro: yup.string().nullable().optional().default(null),
  entrada: yup.number().optional().default(0).when('tipolan', {
    is: 1 || 3,
    then: () => yup.number().required('Campo obrigatório') 
  }),
  saida: yup.number().optional().default(0).when('tipolan', {
    is: 2,
    then: () => yup.number().required('Campo obrigatório') 
  }),
  historico: yup.string().required(),

  idparticipante: yup.string().optional().default('').when('idparceiro', {
    is: null,
    then: () => yup.string().required('Campo obrigatório')
      .test('cpf-ou-cnpj', 'CPF ou CNPJ inválido', function(value) {
        if (!value) {
          return this.createError({ path: this.path, message: 'Campo obrigatório' });
        }

        if (value.length === 11) {
          if (!cpfValidator.call(this, value)) {
            return this.createError({ path: this.path, message: 'CPF inválido' });
          }
        } else if (value.length === 14) {
          if (!cnpjValidator.call(this, value)) {
            return this.createError({ path: this.path, message: 'CNPJ inválido' });
          }
        } else {
          return this.createError({ path: this.path, message: 'CPF ou CNPJ inválido' });
        }

        return true;
      })
  }),
});


export const DetalheDeLCDPR: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [tipolan, setTipoLan] = useState(0);

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      
      LCDPRService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/lcdpr');
          } else {
            setTipoLan(result.tipolan); 
            formRef.current?.setData(result);
          } 
        });
    } else {
      formRef.current?.setData({
        datacaixa: new Date(),
        dataemissao: new Date(),
        numero: '',
        tipodoc: null,
        tipolan: null,
        entrada: 0,
        saida: 0,
        historico: '',
      });
    }
  }, [id]);

  
  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          
          LCDPRService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/lcdpr');
                } else {
                  navigate(`/lcdpr/detalhe/${result}`);
                }
              }
            });
        } else {
          LCDPRService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/lcdpr');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      LCDPRService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/lcdpr');
          }
        });
    }
  };

  const handleIdTipoLan = (valor: string | undefined) => {
    if (valor != null) {
      setTipoLan(Number(valor));
    }
  };

  return (
    <LayoutBaseDePagina
      titulo='Lançamento LCDPR'
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvar
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao = {false}
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/lcdpr')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/lcdpr/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <AutoCompleteProdutor
                  isExternalLoading={isLoading}                  
                />
              </Grid> 
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <AutoCompleteFazenda
                  isExternalLoading={isLoading}
                />
              </Grid> 
              <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                <AutoCompleteContaBancaria
                  name='idconta'
                  isExternalLoading={isLoading}
                  label='Conta Bancária'
                />
              </Grid> 
              <Grid item xs={6} sm={12} md={6} lg={3} xl={2}>
                <VTextFieldData
                  fullWidth
                  name='datacaixa'
                  label='Data Caixa'
                  disabled={isLoading}
                />
              </Grid> 
              <Grid item xs={6} sm={12} md={6} lg={3} xl={2}>
                <VTextFieldData
                  fullWidth
                  name='dataemissao'
                  label='Data Emissão'
                  disabled={isLoading}
                />
              </Grid> 
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <VTextField
                  fullWidth
                  name='numero'
                  disabled={isLoading}
                  label='Nº do Documento'
                />
              </Grid> 
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <SelectNumber
                  name='tipodoc'
                  label='Tipo de Documento'
                  data={[
                    { nameBD: 1, descricao: 'Nota Fiscal' },
                    { nameBD: 2, descricao: 'Fatura' },
                    { nameBD: 3, descricao: 'Recibo' },
                    { nameBD: 4, descricao: 'Contrato' },
                    { nameBD: 5, descricao: 'Folha de Pagamento' },
                    { nameBD: 6, descricao: 'Outros' },
                  ]}
                  
                  disabled={isLoading}
                />  
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                <SelectNumber
                  name='tipolan'
                  label='Tipo de Lançamento'
                  data={[
                    { nameBD: 1, descricao: 'Receita da Atividade Rural' },
                    { nameBD: 2, descricao: 'Despesas de Custeio e Investimentos' },
                    { nameBD: 3, descricao: 'Receita de Produtos Entregues no Ano' },
                  ]}
                  valor={handleIdTipoLan}
                  disabled={isLoading}
                />  
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                <AutoCompleteParceiro
                  isExternalLoading={isLoading}
                />  
              </Grid> 
              <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                <VTextFieldCpf
                  fullWidth
                  name='idparticipante'
                  disabled={isLoading}
                  label='CPF/CNPJ de Parceiro não Cadastrado'
                />
              </Grid> 
              {(tipolan === 1 || tipolan === 3) && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                  <VFieldMoney
                    fullWidth
                    name='entrada'
                    disabled={isLoading}
                    label='Valor Entrada'
                  />
                </Grid> 
              )}
              {(tipolan === 2 ) && (
                <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
                  <VFieldMoney
                    fullWidth
                    name='saida'
                    disabled={isLoading}
                    label='Valor Saída'
                  />
                </Grid> 
              )}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <VTextField
                  fullWidth
                  name='historico'
                  label='Histórico'
                  multiline
                  rows={4}
                  disabled={isLoading}
                />
              </Grid> 
            </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};