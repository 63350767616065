import { v4 as uuidv4 } from 'uuid';
import { Apiestoque } from '../../axiosestoque';
import { Environment } from '../../../../environment';

export interface IListagemOperacoes {
  idoperacoes: string;
  descricao: string;
  tipooperacao: string;
  faturamento: boolean;
  venda: boolean;
  status: boolean;
  ajuste: boolean;
}

export interface IDetalheOperacoes {
  descricao: string;
  tipo: number;
  faturamento: boolean;
  venda: boolean;
  status: boolean;
  ajuste: boolean;
}

type TComTotalCount = {
    data: IListagemOperacoes[];
    totalCount: number;
}

const getAll = async (page = 1, filter = ''): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa = `/estoqueoperacoes?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}`;

    const { data, headers } = await Apiestoque.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAlllist = async (filter = '', id = '', tipo: number): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa: string = id === '' ? `/estoqueoperacoeslist?limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}&tipo=${tipo}` : `/estoqueoperacoeslist/${id}`;

    const { data, headers } = await Apiestoque.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalheOperacoes | Error> => {
  try {
    const { data } = await Apiestoque.get(`/estoqueoperacoes/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalheOperacoes): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Apiestoque.post<IDetalheOperacoes>('/estoqueoperacoes',  {idoperacoes: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalheOperacoes): Promise<void | Error> => {
  try {
    await Apiestoque.put(`/estoqueoperacoes/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apiestoque.delete(`/estoqueoperacoes/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};


export const EstoqueOperacoesService = {
  getAll,
  getAlllist,
  create,
  getById,
  updateById,
  deleteById,
};