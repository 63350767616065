
export const menuestoque =  [
  {
    icon: 'dashboardicone',
    path: '/dashboardestoque',
    label: 'Pagina Inicial',
  },
  {
    icon: 'card_giftcard',
    path: '/estoque',
    label: 'Produtos',
  },
  {
    icon: 'lan',
    path: '/grupos',
    label: 'Grupo',
  },
  {
    icon: 'account_tree',
    path: '/subgrupos',
    label: 'Subgrupo',
  },
  {
    icon: 'brightness_7',
    path: '/estoqueoperacoes',
    label: 'Operações',
  },
  {
    icon: 'content_paste_go',
    path: '/entradaestoque',
    label: 'Entrada',
  },
  {
    icon: 'content_paste',
    path: '/saidaestoque',
    label: 'Saída',
  },
  {
    icon: 'where_to_vote',
    path: '/localizacao',
    label: 'Localização',
  },
  {
    icon: 'compare_arrows',
    path: '/transferenciaestoque',
    label: 'Transferências',
  },
  {
    icon: 'check_circle',
    path: '/transferenciarecebimento',
    label: 'Recebimentos',
  },
  {
    icon: 'construction',
    path: '/os',
    label: 'Ordem de Serviço',
  }
];

export const menuestoquerelatorio =  [
  {  
    icon: 'print',
    path: '/restoquelocalizacao',
    label: 'Localização',
  },
  {
    icon: 'print',
    path: '/restoquevencimento',
    label: 'Vencimentos',
  },
  {
    icon: 'print',
    path: '/restoqueoperacoes',
    label: 'Operações',
  }
];