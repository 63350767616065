/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import {  IconButton, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import {  useSearchParams } from 'react-router-dom';

import { LayoutBaseDeFinanceiro, } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import dayjs, { Dayjs } from 'dayjs';
import { FerramentasDaListagemFinanceiroConfirmarBoleto } from '../../../shared/components';
import { v4 as uuidv4 } from 'uuid';
import { ConfirmarBoletoService, IListagem } from '../../../shared/services/api/Financeiro/ConfirmarBoleto';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { DialogConfirmar } from './DialogConfirmar';

export const ListagemConfirmarBoleto: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [rows, setRows] = useState<IListagem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [istexto, setIsTexto] = useState(false);
  const [isdate, setIsDate] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [openConfirmar, setOpenConfirmar] = useState(false);
  const [dataInicial, setDataInicial] = useState(''); 
  const [dataFinal, setDataFinal] = useState(''); 
  const [ids, setIds] = useState(''); 

  const guuid = uuidv4();   
  
  const basicselect = useMemo(() => {
    return searchParams.get('basicselect') || 'data'; 
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);  
  
  useEffect(() => {
    if(basicselect === 'data'){
      setIsTexto(false);
      setIsDate(true);
    }else{
      setIsTexto(true);
      setIsDate(false);
    }
    setTotalCount(0);
    setRows([]);
  }, [basicselect]);

  
  const parceiroselect = useMemo(() => {
    return searchParams.get('parceiroselect') || ''; 
  }, [searchParams]);

  useEffect(() => {
    if(parceiroselect !== ''){
      ConfirmarBoletoService.getAll(1, parceiroselect, 'parceiro', '', '')
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    }
  }, [parceiroselect]);  

  useEffect(() => {
    if(basicselect === 'data'){
      if(dataInicial !== ''){
        setIsLoading(true);  
        ConfirmarBoletoService.getAll(pagina, '', 'data', dataInicial, dataFinal)
          .then((result) => {
            setIsLoading(false);  
            if (result instanceof Error) {
              alert(result.message);
            } else { 
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      }
    }else{
      setIsLoading(true);  
      ConfirmarBoletoService.getAll(pagina, parceiroselect, 'parceiro', '', '')
        .then((result) => {
          setIsLoading(false);  
          if (result instanceof Error) {
            alert(result.message);
          } else { 
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    }  
  }, [pagina]);  
  
          
  const handleCloseBaixa = (newValue?: string) => {
    setOpenConfirmar(false);  
    if(basicselect === 'data'){
      if(dataInicial !== ''){
        setIsLoading(true);  
        ConfirmarBoletoService.getAll(pagina, '', 'data', dataInicial, dataFinal)
          .then((result) => {
            setIsLoading(false);  
            if (result instanceof Error) {
              alert(result.message);
            } else { 
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      }
    }else{
      setIsLoading(true);  
      ConfirmarBoletoService.getAll(pagina, parceiroselect, 'parceiro', '', '')
        .then((result) => {
          setIsLoading(false);  
          if (result instanceof Error) {
            alert(result.message);
          } else { 
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    }  
  };  

  const handlePesquisa = (datainicial: Dayjs | null, datafinal: Dayjs | null) => {
    const formattedDateInicial = datainicial ? dayjs(datainicial).format('DD/MM/YYYY') : '';
    const formattedDateFinal = datafinal ? dayjs(datafinal).format('DD/MM/YYYY') : '';
    setDataInicial(formattedDateInicial);
    setDataFinal(formattedDateFinal);
    setIsLoading(true);  
    ConfirmarBoletoService.getAll(1, '', 'data', formattedDateInicial, formattedDateFinal)
      .then((result) => {
        setIsLoading(false);  
        if (result instanceof Error) {
          alert(result.message);
        } else { 
          setTotalCount(result.totalCount);
          setRows(result.data);
        }
      });
  };  

  return (
    <LayoutBaseDeFinanceiro
      mostraCardTotal={false}
      mostraCardSelecionado={false}
      titulo='Confirmar Boleto'  
      labelSelecionado='Selecionado'
      labeltotal='Total Pagar'
      total={''}
      selecionado={''}
      barraDeFerramentas={
        <FerramentasDaListagemFinanceiroConfirmarBoleto
          mostrarInputData={isdate}
          mostrarAutoCompliteParceiro={istexto}
          textoDoSelect={basicselect}
          aoClicarEmPesquisa={handlePesquisa}
          aoMudarTextoDoSelect={textoselect => setSearchParams({basicselect: textoselect, parceiroselect: parceiroselect, pagina: '1' }, { replace: true })}
          aoMudarTextoDoParceiro={texto => setSearchParams({basicselect: basicselect, parceiroselect: texto, pagina: '1' }, { replace: true })}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto', marginRight: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Parceiro</TableCell>
              <TableCell align='left'>CNPJ/CPF</TableCell>
              <TableCell align='left'>Nº Documento</TableCell>
              <TableCell align='center'>Data Emissão</TableCell>
              <TableCell align='center'>Vencimento</TableCell>
              <TableCell align='right' >Valor Pagar</TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead> 
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idcontaspagar} 
                hover
                sx={{ cursor: 'pointer' }}  
              >
                <TableCell align='left'>{row.nome}</TableCell>
                <TableCell align='left'>{row.cpf}</TableCell>
                <TableCell align='left'>{row.numerodoc}</TableCell>
                <TableCell align='center'>{dayjs(row.dataemissao).format('DD/MM/YYYY')}</TableCell>
                <TableCell align='center'>{dayjs(row.vencimento).format('DD/MM/YYYY')}</TableCell>
                <TableCell align='right'>{row.valorpagar.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                <TableCell >
                  <IconButton color='primary'>
                    <CheckCircleOutlineIcon sx={{ fontSize: 33 }} onClick={() => {setIds(row.idcontaspagar);  setOpenConfirmar(true);}} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody> 
   
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={7}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination  
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      <DialogConfirmar
        value={guuid}
        open={openConfirmar} 
        id={ids}
        onClose={handleCloseBaixa}
      /> 
    </LayoutBaseDeFinanceiro>
  ); 
};