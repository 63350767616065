/* eslint-disable @typescript-eslint/no-unused-vars */
import {  Box, Button, DialogActions, DialogContent, DialogTitle, Grid,  LinearProgress,  MenuItem,  Paper, Select, Switch } from '@mui/material';
import { useState } from 'react';
import {  AutoCompleteContaBancariaListagem, AutoCompleteParceiroListagem } from '../../../shared/forms';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import RelatorioChequeEmitidos from './RelatorioChequeEmitidos';
import { ChequeEmitidosService } from '../../../shared/services/api/Financeiro/ChequeEmitidos';
           
export const RelatoriosChequeEmitidos: React.FC = () => {                        
  const [isLoading, setIsLoading] = useState(false);         
  const [idconta, setIdconta] = useState(0);           
  const [status, setStatus] = useState(5);  
  const [tipodata, setTipodata] = useState('c.datadeposito');                  
  const [dataInicial, setDataInicial] = useState<Dayjs | null>(dayjs());      
  const [dataFinal, setDataFinal] = useState<Dayjs | null>(dayjs());      
                
  const handleSave = () => {                           
    ChequeEmitidosService                   
      .RelatorioCheque(idconta, status, tipodata, dayjs(dataInicial).format('DD/MM/YYYY'), dayjs(dataFinal).format('DD/MM/YYYY'))   
      .then((result) => {           
        setIsLoading(false);                 
        if (result instanceof Error) {                
          alert(result.message);                    
        }else {   
          if(result.length > 0){
            RelatorioChequeEmitidos(result,  `Período :${dayjs(dataInicial).format('DD/MM/YYYY')} a  ${dayjs(dataFinal).format('DD/MM/YYYY')}`);  
          }else{
            alert('Não a Registros'); 
          }
        }             
      });              
  };          

  const handleidconta = (codigo: number | undefined) => {    
    if (codigo != null) { 
      setIdconta(codigo );                  
    }else{                       
      setIdconta(0);                         
    }                  
  };   
   

  return (
    <Box  alignItems="center" width='100%' alignContent='center' height="100%"  display="flex" flexDirection="column"  >
      <Box gap={1}  padding={1} paddingX={2} display="flex" alignItems="center" alignSelf='center' width='80%' height="20%" margin={1}  alignContent='center'></Box>
      <Box   width='50%' alignItems="center"  component={Paper} height="35%" margin={1} 
        alignContent='center'>       
        <DialogTitle>Relatório de Cheque Emitidos</DialogTitle>
        <DialogContent  dividers>    
          
          {isLoading && (           
            <Grid item>  
              <LinearProgress variant='indeterminate' />  
            </Grid>                      
          )}   
          <Grid container  padding={1} item direction="row" gap={2}>
            <Grid container spacing={2} item direction="row" xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <AutoCompleteContaBancariaListagem
                  codigoconta={handleidconta}
                />                       
              </Grid> 
              <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>  
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker  
                    format='DD/MM/YYYY'
                    value={dataInicial}
                    onChange={(newValue) => setDataInicial(newValue)} 
                    label='Data Inicial'
                  />
                </LocalizationProvider>
        
              </Grid>  
              <Grid item xs={4} sm={4} md={4} lg={4} xl={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    value={dataFinal}
                    onChange={(newValue) => setDataFinal(newValue)} 
                    label='Data Final'
                  />
                </LocalizationProvider>
              </Grid>   
              <Grid item xs={2} sm={2} md={2} lg={2} xl={3}>
                <Select
                  fullWidth
                  value={tipodata}
                  onChange={(event) => setTipodata(event.target.value)}
                >
                  <MenuItem value={'c.dataoperacao'}>Data Operação</MenuItem>
                  <MenuItem value={'c.datadeposito'}>Deposito</MenuItem>
                  <MenuItem value={'c.databaixa'}>Baixa</MenuItem>
                </Select>           
              </Grid>   
              <Grid item xs={2} sm={2} md={2} lg={2} xl={3}>
                <Select
                  fullWidth
                  value={status}
                  onChange={(event) => setStatus(Number(event.target.value))}
                >
                  <MenuItem value={1}>Liberado p/ Deposito</MenuItem>
                  <MenuItem value={2}>Compensado</MenuItem>
                  <MenuItem value={3}>Devolvido 1ª Vez</MenuItem>
                  <MenuItem value={4}>Devolvido</MenuItem>
                  <MenuItem value={5}>Todos</MenuItem>
                </Select>           
              </Grid>   
            </Grid>        
          </Grid>         
        </DialogContent>     
        <DialogActions>
          <Button sx={{ minWidth:'30%'  }} variant='outlined' autoFocus onClick={handleSave}>Visualizar</Button>
        </DialogActions>
      </Box>
    </Box>
  );
};   