/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { useDebounce } from '../../shared/hooks';
import { useField } from '@unform/core';
import { CeiService } from '../services/api/rh/cei/CeiService';


type TAutoCompleteOption = {
  idcei: string;
  label: string;
}

interface IAutoCompleteProdutorProps {
  isExternalLoading?: boolean;
}

export const AutoCompleteCEI: React.FC<IAutoCompleteProdutorProps> = ({ isExternalLoading = false }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField('idcei');
  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue);

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [opcoes1, setOpcoes1] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');
  const [buscaLimpa, setBuscaLimpa] = useState('');

  useEffect(() => {
    registerField({ 
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
    });
  }, [registerField, fieldName, selectedId]);

  useEffect(() => { 
    setIsLoading(true);
    debounce(() => { 
      CeiService.getAllList(buscaLimpa, selectedId)
        .then((result) => { 
          setIsLoading(false);

          if (result instanceof Error) {
            // alert(result.message);
          } else {
            setOpcoes(result.data.map(cei => ({ idcei: cei.idcei, label: cei.numero + ' - ' + cei.nome })));
          }
        });
    });
  }, [buscaLimpa, selectedId]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.idcei === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);

  const autoCompleteSelectedOption1 = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption1 = opcoes1.find(opcao => opcao.idcei === selectedId);
    if (!selectedOption1) return null;

    return selectedOption1;
  }, [selectedId, opcoes1]);


  return (
    <Autocomplete
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando...'
 
      disablePortal

      defaultValue={autoCompleteSelectedOption1}

      options={opcoes}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => {setBusca(newValue); setBuscaLimpa(busca.replace(/[\d\W]/g, ''));}}
      onChange={(_, newValue) => { setSelectedId(newValue?.idcei); setBusca(''); clearError();}}
      popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}

          label="CEI"
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
};