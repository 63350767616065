/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Grid, Icon,  IconButton,  MenuItem,  Paper, Select, TextField, Typography, useTheme } from '@mui/material';
import { Environment } from '../../environment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { AutoCompleteContaBancariaListagem } from '../../forms';

interface IFerramentasDaListagemProps {
  mostrarBotaoFecharConta?: boolean;
  mostrarBotaoAbrirConta?: boolean;
  aoClicarEmPesquisa?: (datainicial: Dayjs | null, datafinal: Dayjs | null) => void;
  aoMudarTextoDoIdconta?: (novoTextoselect: number, texto1: string) => void;
  aoClicarEmImpressao?: () => void;
  aoClicarEmFecharConta?: () => void;
  aoClicarEmAbrirConta?: () => void;
  textonome?: string;
}
export const FerramentasDaListagemFinanceiroConciliacao: React.FC<IFerramentasDaListagemProps> = ({
  aoClicarEmPesquisa,
  aoClicarEmImpressao,
  aoClicarEmFecharConta,
  aoClicarEmAbrirConta,
  aoMudarTextoDoIdconta,
  mostrarBotaoFecharConta = false,
  mostrarBotaoAbrirConta = false,
  textonome='',
}) => {
  const theme = useTheme();
  const [dataInicial, setDataInicial] = useState<Dayjs | null>(dayjs()); 
  const [dataFinal, setDataFinal] = useState<Dayjs | null>(dayjs()); 

  const handlePesquisa = () => {
    aoClicarEmPesquisa?.(dataInicial, dataFinal);
  };

  const handleDescricaoconta = (nome: string | undefined) => {
    if (nome != null) {
      textonome = nome;
    }  
  };

  const handleidconta = (codigo: number | undefined) => {
    if (codigo!= null) {
      aoMudarTextoDoIdconta?.(codigo, textonome);
    }  
  };

  return (
    <Box
      gap={1}
      marginX={1}
      padding={1}
      paddingX={2}
      display="flex"
      alignItems="center"
      height={theme.spacing(6)}
      component={Paper}
    >
      <Grid container item direction="row" spacing={1}>  
        <Grid item xs={9} sm={9} md={12} lg={12} xl={12}>  
          <Grid container item direction="row" spacing={1}>  
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>  
              <AutoCompleteContaBancariaListagem
                descricao={handleDescricaoconta}
                codigoconta={handleidconta}
                isExternalLoading={false}
              />  
            </Grid>  
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>  
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format='DD/MM/YYYY'
                  value={dataInicial}
                  onChange={(newValue) => setDataInicial(newValue)}
                />
              </LocalizationProvider>
            </Grid>   
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>  
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format='DD/MM/YYYY'
                  value={dataFinal}
                  onChange={(newValue) => setDataFinal(newValue)} 
                />
              </LocalizationProvider>
            </Grid>   
            <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>   
              <IconButton color='primary'>
                <SearchIcon sx={{ fontSize: 45 }} onClick={() => {handlePesquisa();}} />
              </IconButton>
            </Grid>   
            <Grid item marginTop={1} xs={1} sm={1} md={1} lg={1} xl={1}> 
              <Button
                size="medium"
                color='primary'
                variant='contained'
                disableElevation
                onClick={aoClicarEmImpressao}
                startIcon={<Icon>printoutlined</Icon>}
              >
                <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                  Imprimir
                </Typography>
              </Button>
            </Grid> 
            {mostrarBotaoFecharConta && (
              <Grid item marginTop={1} marginLeft={3} xs={1} sm={1} md={1} lg={1} xl={1}> 
                <Button 
                  size="medium"
                  color='primary'
                  variant='contained'
                  disableElevation
                  onClick={aoClicarEmFecharConta}
                  startIcon={<Icon>printoutlined</Icon>}
                >
                  <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                  FecharConta
                  </Typography>
                </Button>
              </Grid> 
            )} 
            {mostrarBotaoAbrirConta && (
              <Grid item marginTop={1} marginLeft={3} xs={1} sm={1} md={1} lg={1} xl={1}> 
                <Button 
                  size="medium"
                  color='primary'
                  variant='contained'
                  disableElevation
                  onClick={aoClicarEmAbrirConta}
                  startIcon={<Icon>printoutlined</Icon>}
                >
                  <Typography variant='button' whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                    Abrir Conta
                  </Typography>
                </Button>
              </Grid> 
            )} 
          </Grid> 
        </Grid> 
      </Grid> 
    </Box>
  );
};