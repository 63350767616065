import { Avatar, Box, Grid, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import { AutoCompleteFuncionario, AutoCompletePeriodo, VForm, useVForm } from '../../../shared/forms';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { Environment } from '../../../shared/environment';
import { PontoService } from '../../../shared/services/api/rh/ponto/PontoService';
import { dataextenso } from '../../../shared/services/util/dataextenso';
import { DialogPonto } from './DialogoPonto';
import OutlinedCard2Ponto from './OutlinedCard2Ponto';

interface IDetalhesPonto{
  idponto: string;
  dia: string;
  semana: string;
  ponto: string;
  horaextra: number;
  pistola: number;
  bomba: number;
  cafe: number;
  almoco: number;
  janta: number;
  fazenda: string;
}

interface IResumoPonto{
  presenca: number;
  falta: number;
  dsr: string;
  horaextra: number;
  bomba: number;
  pistola: number;
  atestado: number;
  domingo: number;
  alimentacao: number;
  detalhes: IDetalhesPonto[];
}

export const DetalheDePonto: React.FC = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const { formRef } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [urlimg, setUrlimg] = useState('');
  const [idfun, setIdfun] = useState('');
  const [periodo, setPeriodo] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [detalheponto, setdetalheponto ] = useState<string>('');
  const [rows, setRows] = useState<IDetalhesPonto[]>([]);
  const [resumo, setResumo] = useState<IResumoPonto>({} as IResumoPonto);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const handleSave = ( ) => {
    //
  };

  const handlefoto = (urlimg: string | undefined) => {
    if (urlimg != null) {
      setUrlimg(Environment.URL_IMGFUNCIONARIO+urlimg);
    }else{
      setUrlimg('/logo.png');
    }
  };

  const handleuuid = (uuid: string | undefined) => {
    if (uuid != null) {
      setIdfun(uuid);
      if (periodo !== ''){
        setIsLoading(true);
        PontoService.getAll(1, uuid, periodo)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              getdados(result.data);
            }
          });
      }else{
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (idfun != ''){
      setIsLoading(true);
      PontoService.getAll(pagina, idfun, periodo)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            if (result.data.length > 0){
              setTotalCount(result.totalCount);
              getdados(result.data);
            }
          }
        });
    }
  }, [open, pagina]);

  useEffect(() => {
    const fperiodo = dataextenso(new Date());
    const ames = fperiodo.slice(0, -5);
    const aano = fperiodo.slice(-4);
    const aperiodo = `${ames}%2f${aano}`;    
    setPeriodo(aperiodo);
    formRef.current?.setData({periodo: fperiodo});
  },[]);

  const handlPeriodo = (fperiodo: string | undefined) => {
    if (fperiodo != null) {
      setIsLoading(true);
      const ames = fperiodo.slice(0, -5);
      const aano = fperiodo.slice(-4);
      const aperiodo = `${ames}%2f${aano}`;    
      setPeriodo(aperiodo);
      if (idfun != ''){ 
        PontoService.getAll(1, idfun, aperiodo)
          .then((result) => {
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              getdados(result.data);
            }
          });
      }else{
        setIsLoading(false);
      }
    }
  };

  function getdados(dados: IResumoPonto[]) {
    const [resuno, detalhes] = dados;
    if (resuno){
      setResumo(resuno);
      setRows(detalhes.detalhes);
    }else{
      setResumo({} as IResumoPonto);
      setRows([]);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <VForm ref={formRef}  onSubmit={handleSave}   >
      <Box display="flex" flexDirection="column" component={Paper} variant="outlined">
        <Grid container direction="column" padding={2}>
          {isLoading && (
            <Grid item>
              <LinearProgress variant='indeterminate' />
            </Grid>
          )}
          <Typography  sx={{ marginBottom: 1, fontSize: '200%'}}>Lançamento de Ponto</Typography>

          <Grid container item direction="row" spacing={3}>
            <Grid container item direction="row" spacing={2} >
              <Grid item xs={4} sm={6} md={3} lg={1.5} xl={1.33}>
                <Box width="100%" marginRight={5} >
                  <Avatar
                    variant='rounded'
                    alt='Remy Sharp'
                    src={urlimg}
                    sx={{ width: '100%', height: 170 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={10.5} xl={10.5}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container item direction="row" spacing={2} >
                    
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                      <AutoCompleteFuncionario 
                        isExternalLoading={isLoading} 
                        urlfoto={handlefoto}
                        uuidfun={handleuuid}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                      <AutoCompletePeriodo isExternalLoading={isLoading} novoperiodo={handlPeriodo} />
                    </Grid> 
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                      <Grid container item direction="row" spacing={0}>
                        <Grid item xs={4} sm={6} md={3} lg={2.4} xl={1.33}>
                          <OutlinedCard2Ponto
                            nome='Presença'
                            cor='#a6f5e4'
                            valor={resumo.presenca !== undefined ? resumo.presenca: 0}
                            img=''
                            loading={isLoading}
                          />  
                        </Grid>    
                        <Grid item xs={4} sm={6} md={3} lg={2.4} xl={1.33}>
                          <OutlinedCard2Ponto
                            nome='Faltas'
                            cor='#f5c3ab'
                            valor={resumo.falta !== undefined ? resumo.falta: 0}
                            img=''
                            loading={isLoading}
                          />   
                        </Grid>   
                        <Grid item xs={4} sm={6} md={3} lg={2.4} xl={1.33}>
                          <OutlinedCard2Ponto
                            nome='DSR'
                            cor='#d2d4d2'
                            valor={resumo.dsr !== undefined ? Number(resumo.dsr) : 0}
                            img=''
                            loading={isLoading}
                          />   
                        </Grid>   
                        <Grid item xs={4} sm={6} md={3} lg={2.4} xl={1.33}>
                          <OutlinedCard2Ponto
                            nome='Hora Extra' 
                            cor='#86babf'
                            valor={resumo.horaextra !== undefined ? resumo.horaextra: 0}
                            img=''
                            loading={isLoading}
                          />   
                        </Grid>   
                        <Grid item xs={4} sm={6} md={3} lg={2.4} xl={1.33}>
                          <OutlinedCard2Ponto
                            nome='Bomba'
                            cor='#d1ba8c'
                            valor={resumo.bomba !== undefined ? resumo.bomba: 0}
                            img=''
                            loading={isLoading}
                          />   
                        </Grid>   
                        <Grid item xs={4} sm={6} md={3} lg={2.4} xl={1.33}>
                          <OutlinedCard2Ponto
                            nome='Pistola'
                            cor='#82a882'
                            valor={resumo.pistola !== undefined ? resumo.pistola: 0}
                            img=''
                            loading={isLoading}
                          />   
                        </Grid>   
                        <Grid item xs={4} sm={6} md={3} lg={2.4} xl={1.33}>
                          <OutlinedCard2Ponto
                            nome='Atestado'
                            cor='#6d71f7'
                            valor={resumo.atestado !== undefined ? resumo.atestado: 0}
                            img=''
                            loading={isLoading}
                          />   
                        </Grid>   
                        <Grid item xs={4} sm={6} md={3} lg={2.4} xl={1.33}>
                          <OutlinedCard2Ponto
                            nome='Domingo'
                            cor='#345e4b'
                            valor={resumo.domingo !== undefined ? resumo.domingo: 0}
                            img=''
                            loading={isLoading}
                          />   
                        </Grid>   
                        <Grid item xs={4} sm={6} md={3} lg={2.4} xl={1.33}>
                          <OutlinedCard2Ponto
                            nome='Alimentação'
                            cor='#f7d0f5'
                            valor={resumo.alimentacao !== undefined ? resumo.alimentacao: 0}
                            img=''
                            loading={isLoading}
                          />   
                        </Grid>
                      </Grid> 
                    </Grid>
                  </Grid>
                </Grid>
                
              </Grid>
              

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper} variant="outlined" sx={{width: 'auto', marginRight: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: 40 }} align='center' >Dia</TableCell>
                        <TableCell style={{ width: 140 }}>Dia da Semana</TableCell>
                        <TableCell style={{ width: 20 }} align='center' >Ponto</TableCell>
                        <TableCell style={{ width: 80}} align='right'>Hora Extra</TableCell>
                        <TableCell style={{ width: 60 }} align='center'>Bomba</TableCell>
                        <TableCell style={{ width: 60 }} align='center'>Pistola</TableCell>
                        <TableCell style={{ width: 60 }} align='center'>Café</TableCell>
                        <TableCell style={{ width: 60 }} align='center'>Almoço</TableCell>
                        <TableCell style={{ width: 60 }} align='center'>Janta</TableCell>
                        <TableCell >Fazenda</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.idponto} 
                
                          hover
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {setdetalheponto(row.idponto); setOpen(true);}}
                        >
                          <TableCell style={{ width: 40 }} align='center' >{row.dia}</TableCell>
                          <TableCell style={{ width: 140 }} >{row.semana}</TableCell>
                          <TableCell style={{ width: 40 }} align='center' >{row.ponto}</TableCell>
                          <TableCell style={{ width: 80 }} align='right'>{row.horaextra.toLocaleString('pt-BR', { minimumFractionDigits: 2})}</TableCell>
                          <TableCell style={{ width: 60 }} align='center' >{row.bomba}</TableCell>
                          <TableCell style={{ width: 60 }} align='center' >{row.pistola}</TableCell>
                          <TableCell style={{ width: 60 }} align='center' >{row.cafe}</TableCell>
                          <TableCell style={{ width: 60 }} align='center' >{row.almoco}</TableCell>
                          <TableCell style={{ width: 60 }} align='center' >{row.janta}</TableCell>
                          <TableCell  >{row.fazenda}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
  
                    {totalCount === 0 && !isLoading && (
                      <caption>{Environment.LISTAGEM_VAZIA}</caption>
                    )}
  
                    <TableFooter>
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <LinearProgress variant='indeterminate' />
                          </TableCell>
                        </TableRow>
                      )}
                      {(totalCount > 0 && totalCount > 9) && (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Pagination
                              page={pagina}
                              count={Math.ceil(totalCount / 9)}
                              onChange={(_, newPage) => setSearchParams({ pagina: newPage.toString() }, { replace: true })}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>   
          <DialogPonto
            id={detalheponto}
            value={''}  
            open={open} 
            onClose={handleClose}
          />       
        </Grid>
      </Box>
    </VForm>
  );
};