/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { Box, Button, Grid, LinearProgress, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField,  VForm, useVForm, IVFormErrors, VSwitch, AutoCompleteFazenda, VTextFieldData, VFieldMoney, AutoCompleteGpPatrimonio, VTextFieldNumber, BasicSelect } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { PatrimoniosService } from '../../../shared/services/api/cadastros/PatrimoniosService';
import SwipeableTextMobileStepper from './SwipeableTextMobileStepper';
import { DialogImagens } from './DialogImagens';
import { Environment } from '../../../shared/environment';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

type NullableDate = Date | null | undefined;

interface IFormData {
  descricao: string;
  numero: string;
  idfazenda: string;
  idgrupopatrimonio: string;
  dataaquisicao: Date;
  fabricante: string;
  valoraquisicao: number;
  marca: string;
  modelo: string;
  cor: string;
  ano: number;
  numeroserie: string;
  datagarantia: NullableDate;
  numeroapolice: string;
  databaixa: NullableDate;
  motivobaixa: string;
  obs: string;
  seguradora: string;
  status: boolean;
  veiculos: boolean,
  litros: number;
  tipomedicao: string;
  fotos: IImage[];
}


interface IImage {
  imgPath: string;
  arquivo: File | undefined;
}


const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'fotos'>> = yup.object().shape({
  descricao: yup.string().required().min(3).max(60),
  numero: yup.string().required().min(1).max(20),
  idfazenda: yup.string().required(),
  idgrupopatrimonio: yup.string().required(),
  dataaquisicao: yup.date().required(),
  fabricante: yup.string().optional().default(''),
  valoraquisicao: yup.number().required().moreThan(0),
  marca: yup.string().optional().default(''),
  modelo: yup.string().optional().default(''),
  cor: yup.string().optional().default(''),
  ano: yup.number().required().moreThan(0),
  numeroserie: yup.string().optional().default(''),
  datagarantia: yup.date().nullable(),
  numeroapolice: yup.string().optional().default(''),
  databaixa: yup.date().nullable().optional().when('status', {
    is: false,
    then: () => yup.date().nullable().required('Campo obrigatório') 
  }), 
  motivobaixa: yup.string().optional().default(''),
  obs: yup.string().optional().default(''),
  seguradora: yup.string().optional().default(''),
  status: yup.boolean().default(true),
  veiculos: yup.boolean().optional().default(false),
  litros: yup.number().default(0).when('veiculos', {
    is: true,
    then: () => yup.number().required('Campo obrigatório').moreThan(0)
  }), 
  tipomedicao: yup.string().optional().default('').when('veiculos', {
    is: true,
    then: () => yup.string().required('Campo obrigatório')
  }), 
});

export const DetalheDePatrimonio: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  
  const navigate = useNavigate();

  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');
  const [veiculoss, setVeiculoss] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [images, setImages] = useState<IImage[]>([]);
  const [open, setOpen] = useState(false);

  const imagesnovo = [{ imgPath: `${Environment.URL_IMGPATRIMONIO}semimagem.jpg`, arquivo: undefined }];

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      PatrimoniosService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/patrimonios');
          } else {
            setDescricao(result.descricao);
            formRef.current?.setData(result);
            if(result.fotos === null || result.fotos.length === 0){
              setImages(imagesnovo);
            }else{
              const imageList: IImage[] = [];
              result.fotos.forEach((item) => {
                imageList.push({ imgPath: Environment.URL_IMGPATRIMONIO+item.imgPath, arquivo: undefined });
              });
              setImages(imageList);
            }  
          }
        });
    } else {
      formRef.current?.setData({
        descricao: '',
        numero: '',
        idfazenda: '',
        idgrupopatrimonio: '',
        fabricante: '',
        valoraquisicao: 0,
        marca: '',
        modelo: '',
        cor: '',
        ano: 0,
        numeroserie: '',
        numeroapolice: '',
        obs: '',
        seguradora: '',
        status: true,
        veiculos: false,
        datagarantia: null,
        dataaquisicao: '',
      });
      setImages(imagesnovo);
    }
  }, [id]);

  
  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (dadosValidados.databaixa) {
          dadosValidados.status = false;
        }
        if (id === 'novo') {
          
          PatrimoniosService
            .create(files, dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/patrimonios');
                } else {
                  navigate(`/patrimonios/detalhe/${result}`);
                }
              }
            });
        } else {
          PatrimoniosService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/patrimonios');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      PatrimoniosService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/patrimonios');
          }
        });
    }
  };

  const handleveiculos = (veiculos: boolean | undefined) => {
    if (veiculos != null) {
      formRef.current?.setFieldValue('veiculos', veiculos);
      setVeiculoss(veiculos);
    }  
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImg = (dados: IImage[]) => {
    if(dados.length === 0){
      setImages(imagesnovo);
    }else{
      const fileList: File[] = [];
      const imageList: IImage[] = [];
      dados.forEach((item) => {
        if (item.arquivo) {
          const imageUrl = URL.createObjectURL(item.arquivo);
          fileList.push(item.arquivo);
          imageList.push({ imgPath: imageUrl, arquivo: item.arquivo });
        } else {
          imageList.push({ imgPath: item.imgPath, arquivo: undefined });
        }
      });
      setFiles(fileList);
      setImages(imageList);
    }   
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Novo Patrimônio' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Novo'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao = {false}
 
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/patrimonios')} 
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/patrimonios/detalhe/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} >

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid container item direction="row" spacing={2}>
              <Grid container  spacing={1} item xs={12} sm={12} md={12} lg={4} xl={4}>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>  
                  <SwipeableTextMobileStepper
                    images={images}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8} xl={12}>
                  <PhotoCameraIcon  onClick={() => {setOpen(true);}}   color='secondary'  sx={{ fontSize: 28 }} />
                </Grid>            
              </Grid>
              <Grid container   item xs={12} sm={12} md={12} lg={8} xl={8}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container item direction="row" spacing={2}>  
                    <Grid item xs={12} sm={12} md={3} lg={4} xl={2}>   
                      <VTextField
                        fullWidth
                        name='numero'
                        disabled={isLoading}
                        label='Número'
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={8} xl={4}>
                      <VTextField
                        fullWidth
                        name='descricao'
                        disabled={isLoading}
                        label='Descrição'
                        onChange={e => setDescricao(e.target.value)}
                      />
                    </Grid>  
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>          
                      <AutoCompleteGpPatrimonio
                        isExternalLoading={isLoading}
                        veiculos={handleveiculos}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>          
                      <AutoCompleteFazenda
                        isExternalLoading={isLoading}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                      <VTextField
                        fullWidth
                        name='cor'
                        disabled={isLoading}
                        label='Cor'
                      />
                    </Grid> 
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>          
                      <VTextField
                        fullWidth
                        name='modelo'
                        disabled={isLoading}
                        label='Modelo'
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={3}>          
                      <VTextField
                        fullWidth
                        name='marca'
                        disabled={isLoading}
                        label='Marca'
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>          
                      <VTextField
                        fullWidth
                        name='numeroserie'
                        disabled={isLoading}
                        label='Número de Série'
                      />
                    </Grid>
                    <Grid item xs={3} sm={12} md={6} lg={4} xl={2}>          
                      <VTextFieldNumber
                        fullWidth
                        name='ano'
                        disabled={isLoading}
                        label='Ano'
                      />
                    </Grid>              
                    <Grid item xs={9} sm={12} md={6} lg={4} xl={4}>          
                      <VTextField
                        fullWidth
                        name='fabricante'
                        disabled={isLoading}
                        label='Fabricante'
                      />
                    </Grid>
                    <Grid item xs={6} sm={12} md={6} lg={4} xl={2}>     
                      <VTextFieldData
                        fullWidth
                        name='datagarantia'
                        disabled={isLoading}
                        label='Data Garantia'
                      />    
                    </Grid>
                    <Grid item xs={6} sm={12} md={6} lg={4} xl={2}>     
                      <VTextFieldData
                        fullWidth
                        name='dataaquisicao'
                        disabled={isLoading}
                        label='Data Aquisição'
                      />    
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>     
                      <VFieldMoney
                        fullWidth
                        name='valoraquisicao'
                        disabled={isLoading}
                        label='Valor Aquisição'
                      />   
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={8} xl={6}>          
                      <VTextField
                        fullWidth
                        name='seguradora'
                        disabled={isLoading}
                        label='Seguradora'
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={6}>          
                      <VTextField
                        fullWidth
                        name='numeroapolice'
                        disabled={isLoading}
                        label='Número Apólice'
                      />
                    </Grid>  
                    {(veiculoss) && (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>          
                          <BasicSelect
                            name='tipomedicao' 
                            label='Tipo'
                            data={[
                              { nameBD: 'km', descricao: 'KM' },
                              { nameBD: 'horimetro', descricao: 'Horimetro' },
                            ]}
                            disabled={isLoading}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={2}>          
                          <VTextField
                            fullWidth
                            name='litros'
                            disabled={isLoading}
                            label='Qtd Litos Tanque'
                          />
                        </Grid>
                        <Grid item xs={6} sm={12} md={6} lg={2} xl={1}>
                          <VSwitch
                            name='veiculos'
                            label='Veiculos'
                            fdisabled={true} 
                          />
                        </Grid>
                        <Grid item xs={6} sm={12} md={6} lg={2} xl={7}>

                        </Grid>
                      </>
                    )}             
                    {(id != 'novo') && (
                      <><Grid item xs={12} sm={12} md={6} lg={4} xl={2}>
                        <VTextFieldData
                          fullWidth
                          name='databaixa'
                          disabled={isLoading}
                          label='Data da Baixa' />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>          
                        <VTextField
                          fullWidth
                          name='motivobaixa'
                          disabled={isLoading}
                          label='Motivo da Baixa'
                        />
                      </Grid>
                      <Grid item xs={6} sm={12} md={6} lg={2} xl={1}>
                        <VSwitch
                          name='status'
                          label='Ativo'
                          fdisabled={isLoading} />
                      </Grid></>
                    )}                 
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <VTextField
                        fullWidth
                        name='obs'
                        disabled={isLoading}
                        label='Observações'
                        multiline
                        rows={4}
                      />
                    </Grid>
                  </Grid>    
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <DialogImagens
            id={id}
            value={''}  
            open={open} 
            onClose={handleClose}
            dados={images}
            onDados={handleImg}
          /> 
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};