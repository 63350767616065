
import { useEffect, useMemo, useState } from 'react';
import { Box, Grid, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { VTextField,  VForm, useVForm, IVFormErrors, VSwitch,VTextFieldCep } from '../../../shared/forms';

import { FerramentasDaListagem, FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';

import { AutoCompleteMunicipio } from '../../../shared/forms/AutoCompleteMunicipio';
import { FazendaService } from '../../../shared/services/api/cadastros/FazendaService';
import { SelectNumber } from '../../../shared/forms/Selectnumber';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { IListagemTerceirosFazenda, TerceirosService } from '../../../shared/services/api/cadastros/TerceirosService';
import { DialogTerceirosProdutor } from './DialogTerceirosProdutor';
import { IListagemPivotFazenda, PivotService } from '../../../shared/services/api/cadastros/PivotService';
import { DialogDescricaoPivot } from './DialogDescricaoPivot';


interface IFormData {
  descricao: string;
  status: boolean;
  caditr: number;
  endereco: string;
  numero: string;
  compl: string;
  bairro: string;
  idmunicipio: string;
  cep: string;
  tipoexploracao: number;
  ir: boolean;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  descricao: yup.string().required(),
  status: yup.boolean().optional().default(true),
  caditr: yup.number().required(),
  endereco: yup.string().required(),
  numero: yup.string().required(),
  compl: yup.string().required(),
  bairro: yup.string().required(),
  idmunicipio: yup.string().required(),
  cep: yup.string().required(),
  tipoexploracao: yup.number().required(),
  ir: yup.boolean().required(),
});


export const DetalheDaFazenda: React.FC = () => {
  //Detalhe da Fazenda
  const { id = 'novo' } = useParams<'id'>();
  const navigate = useNavigate();
  const { formRef, save, saveAndClose, isSaveAndClose } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');


  //Treceiros
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  
  const [rows, setRows] = useState<IListagemTerceirosFazenda[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  const [open, setOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [atualizar, setAtualizar] = useState(false);

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      debounce(() => {
        TerceirosService.getAll(pagina, id)
          .then((result) => {
            
            setIsLoading(false);
  
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }
  }, [pagina, id, open, atualizar]);

  //Área de cultivo
  const [searchParamsPivot, setSearchParamsPivot] = useSearchParams();
  const [rowsPivot, setRowsPivot] = useState<IListagemPivotFazenda[]>([]);
  const [totalCountPivot, setTotalCountPivot] = useState(0);

  const paginaPivot = useMemo(() => {
    return Number(searchParamsPivot.get('pagina') || '1');
  }, [searchParamsPivot]);

  const [openPivot, setOpenPivot] = useState(false);

  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      debounce(() => {
        PivotService.getAll(paginaPivot, id)
          .then((result) => {
            setAtualizar(true);
            setIsLoading(false);
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCountPivot(result.totalCountPivot);
              setRowsPivot(result.data);
            }
          });
        setAtualizar(false);
      });
      
    }
  }, [paginaPivot, id, openPivot]);

    
  
  //Fazenda
  useEffect(() => {
    if (id !== 'novo') {
      setIsLoading(true);
      
      FazendaService.getById(id)
        .then((result) => {
          setIsLoading(false);

          if (result instanceof Error) {
            alert(result.message);
            navigate('/fazendas');
          } else {
            setDescricao(result.descricao);
            formRef.current?.setData(result);
          }
        });
    } else {
      formRef.current?.setData({
        descricao: '',
        status: true,
        caditr: '',
        endereco: '',
        numero: '',
        compl: '',
        bairro: '',
        idmunicipio: '',
        cep: '',
        tipoexploracao: '',
        ir: false,
      });
    }
  }, [id]);

  
  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          
          FazendaService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/fazendas');
                } else {
                  navigate(`/fazendas/detalhes/${result}`);
                }
              }
            });
        } else {
          FazendaService
            .updateById(id, dadosValidados )
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                if (isSaveAndClose()) {
                  navigate('/fazendas');
                }
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };


  const handleDelete = (id: string) => {
    if (confirm('Realmente deseja apagar?')) {
      FazendaService.deleteById(id)
        .then(result => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Registro apagado com sucesso!');
            navigate('/fazendas');
          }
        });
    }
  };

  const [detalheterceiro, setdetalheterceiro ] = useState<string>('novo');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClose = (newValue?: string) => {
    setOpen(false);
  };

  const [detalhepivot, setDetalhepivot ] = useState<string>('novo');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClosePivot = (newValue?: string) => {
    setOpenPivot(false);
  };


  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Nova Fazenda' : descricao}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          textoBotaoNovo='Nova'
          mostrarBotaoSalvarEFechar
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={id !== 'novo'}
          mostrarBotaoApagar={id !== 'novo'}
          mostrarBotaoApagarCarregando = {isLoading}
          mostrarBotaoImpressao = {false}
 
          aoClicarEmSalvar={save}
          aoClicarEmSalvarEFechar={saveAndClose}
          aoClicarEmVoltar={() => navigate('/fazendas')}
          mostrarBotaoVoltarCarregando = {isLoading}
          aoClicarEmApagar={() => handleDelete(id)}
          aoClicarEmNovo={() => navigate('/fazendas/detalhes/novo')}
          mostrarBotaoNovoCarregando = {isLoading}
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  name='descricao'
                  disabled={isLoading}
                  label='Nome da Fazenda'
                  onChange={e => setDescricao(e.target.value)}
                />
              </Grid>  
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>     
                <AutoCompleteMunicipio isExternalLoading={isLoading}/>     
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>          
                <VTextField
                  fullWidth
                  name='endereco'
                  disabled={isLoading}
                  label='Endereço'
                />
              </Grid>
              <Grid item xs={3} sm={3} md={6} lg={1} xl={1}>          
                <VTextField
                  fullWidth
                  name='numero'
                  disabled={isLoading}
                  label='Número'
                />
              </Grid>
              <Grid item xs={9} sm={9} md={6} lg={4} xl={4}>          
                <VTextField
                  fullWidth
                  name='compl'
                  disabled={isLoading}
                  label='Complemento'
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>          
                <VTextField
                  fullWidth
                  name='bairro'
                  disabled={isLoading}
                  label='Bairro'
                />
              </Grid>
              <Grid item xs={6} sm={4} md={6} lg={4} xl={2}>          
                <VTextFieldCep
                  fullWidth
                  name='cep'
                  disabled={isLoading}
                  label='CEP'
                />
              </Grid>
              <Grid item xs={6} sm={8} md={6} lg={4} xl={2}>          
                <SelectNumber
                  data={[
                    { nameBD: 1, descricao: 'Exploração individual' },
                    { nameBD: 2, descricao: 'Condomínio' },
                    { nameBD: 3, descricao: 'Imóvel arrendado' },
                    { nameBD: 4, descricao: 'Parceria' },
                    { nameBD: 5, descricao: 'Comodato' }, 
                    { nameBD: 6, descricao: 'Outros' },
                  ]}
                  label='Tipo de Exploração'
                  name='tipoexploracao' 
                  disabled={isLoading}
                />
              </Grid>  
              <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>          
                <VTextField
                  fullWidth
                  name='caditr'
                  disabled={isLoading}
                  label='CAFIR'
                />
              </Grid>
              <Grid item xs={2} sm={6} md={2} lg={1} xl={1}>
                <VSwitch
                  name='ir'
                  label='IR'
                  fdisabled={isLoading}/>
              </Grid>        
              <Grid item xs={2} sm={6} md={2} lg={1} xl={1}>
                {(id != 'novo') && (
                  <VSwitch
                    name='status'
                    label='Ativo'
                    fdisabled={isLoading}/>
                )}
              </Grid> 
            </Grid>
          </Grid>
        </Box>
      </VForm> 
      {(id != 'novo') && (
        <Box display="flex" flexDirection="row" >
          <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined" style={{ width: '50%' }} >
            <FerramentasDaListagem
              textoTitulo='Produtor'
              mostrarTextoTitulo={true}
              textoBotaoNovo='Adicionar'
              aoClicarEmNovo={() => {setdetalheterceiro('novo'); setOpen(true);}}
            />

            <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Tipo Contraparte</TableCell>
                    <TableCell>Percentual</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <TableRow key={row.idterceiros} 
                      hover
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {setdetalheterceiro(row.idterceiros); setOpen(true);}}
                    >
                      <TableCell>{row.produtor}</TableCell>
                      <TableCell>{row.contraparte}</TableCell>
                      <TableCell>{row.percentual}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
  
                {totalCount === 0 && !isLoading && (
                  <caption>{Environment.LISTAGEM_VAZIA}</caption>
                )}
  
                <TableFooter>
                  {isLoading && (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <LinearProgress variant='indeterminate' />
                      </TableCell>
                    </TableRow>
                  )}
                  {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Pagination
                          page={pagina}
                          count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                          onChange={(_, newPage) => setSearchParams({ pagina: newPage.toString() }, { replace: true })}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableFooter>
              </Table>
            </TableContainer>
            <DialogTerceirosProdutor
              id={detalheterceiro}
              value={''}  
              open={open} 
              onClose={handleClose}
              idFazenda={id}
            /> 

          </Box>

          <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined" style={{ width: '50%' }}>
            <FerramentasDaListagem
              textoTitulo='Descrição de áreas de cultivo'
              mostrarTextoTitulo={true}
              textoBotaoNovo='Adicionar'
              aoClicarEmNovo={() => {setDetalhepivot('novo'); setOpenPivot(true);}}
            />

            <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Hectare</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsPivot.map(rowsPivot => (
                    <TableRow key={rowsPivot.idpivot} 
                      hover
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {setDetalhepivot(rowsPivot.idpivot); setOpenPivot(true);}}
                    >
                      <TableCell>{rowsPivot.descricao}</TableCell>
                      <TableCell>{rowsPivot.hectare}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
  
                {totalCountPivot === 0 && !isLoading && (
                  <caption>{Environment.LISTAGEM_VAZIA}</caption>
                )}
  
                <TableFooter>
                  {isLoading && (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <LinearProgress variant='indeterminate' />
                      </TableCell>
                    </TableRow>
                  )}
                  {(totalCountPivot > 0 && totalCountPivot > Environment.LIMITE_DE_LINHAS) && (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Pagination
                          page={pagina}
                          count={Math.ceil(totalCountPivot / Environment.LIMITE_DE_LINHAS)}
                          onChange={(_, newPage) => setSearchParamsPivot({ pagina: newPage.toString() }, { replace: true })}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableFooter>
              </Table>
            </TableContainer>
            <DialogDescricaoPivot
              id={detalhepivot}
              value={''}  
              open={openPivot} 
              onClose={handleClosePivot}
              idFazenda={id}
            /> 

          </Box>        
        </Box>
      )}
      

    </LayoutBaseDePagina>
  );
};