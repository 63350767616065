import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { useDebounce } from '../../shared/hooks';
import { useField } from '@unform/core';
import { ProdutosNfeService } from '../services/api/fiscal/produtosnfe/ProdutosNfeService';

type TAutoCompleteOption = {
  idprod: string;
  label: string;
  descricao: string;
  un: string;
  ncm: string;
}

interface IAutoCompleteFuncionariosProps {
  isExternalLoading?: boolean;
  descricao?: (novoTextoselect: string | undefined) => void;
  un?: (novoTexto: string | undefined) => void;
  ncm?: (novoTexto: string | undefined) => void;
}

export const AutoCompleteProdutosNfe: React.FC<IAutoCompleteFuncionariosProps> = ({ isExternalLoading = false, descricao, un, ncm}) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField('idprod');
  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue);

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
    });
  }, [registerField, fieldName, selectedId]);

  useEffect(() => {
    setIsLoading(true);

    debounce(() => {
      ProdutosNfeService.getAllList(busca, selectedId)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            // alert(result.message);
          } else {
            setOpcoes(result.data.map(produtos => ({ idprod: produtos.idprod, label: produtos.descricao + ' - ' + produtos.ncm, 
              descricao: produtos.descricao, un: produtos.un, ncm: produtos.ncm })));
          }
        });
    });

  }, [busca, selectedId]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opcoes.find(opcao => opcao.idprod === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opcoes]);

  return (
    <Autocomplete
      openText='Abrir'
      closeText='Fechar'
      noOptionsText='Sem opções'
      loadingText='Carregando...'

      disablePortal

      options={opcoes}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue) => { setSelectedId(newValue?.idprod); setBusca(''); descricao?.(newValue?.descricao); un?.(newValue?.un); ncm?.(newValue?.ncm);  clearError(); }}
      popupIcon={(isLoading) ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Produtos NFE"
          error={!!error}
          helperText={error}
        />
      )}
    />
  );
}; 