import { useState,useEffect, useCallback } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { IListagemValeresumo, ValeresumoService,IListagemValesgrafico} from '../../../shared/services/api/rh/colaborador/valesresumo';
import { FerramentasDaListagemvaleresumo } from '../../../shared/components/ferramentas-da-listagem/FerramentasDaListagemvaleresumo';
import NavTabs from './NavTabs';
import { Api } from '../../../shared/services/api/axios-config';

export const Listagemcolaboradorvale: React.FC = () => {
  const [dadosDoGrafico, setDadosDoGrafico] = useState<IListagemValesgrafico[]>([]);
  
  const [rows, setRows] = useState<IListagemValeresumo[]>([]);

  const [nomeColaborador, setNomeColaborador] = useState('');
 


  
  const idfun = new URLSearchParams(location.search).get('idfun');
 
  
  const getCurrentYear = () => {
    return new Date().getFullYear().toString();
  };

  const [anoSelecionado, setAnoSelecionado] = useState<string>(getCurrentYear());

  useEffect(() => {
    const fetchData = async () => {
  
      try {
        const response = await Api.get(`/funcionarios/${idfun}`);
        const colaboradorData = response.data;

        setNomeColaborador(colaboradorData.nome);
          
      } catch (error) {
        // setIsLoading(false);
        alert('Erro ao obter dados do colaborador.');
        // navigate(`/dependentes?idfun=${idColaborador}`);
      }
      
    };
  
    fetchData();
  }, [idfun]);

  const HandleSelect = useCallback((ano: string) => {
    if (ano != null) {
      ValeresumoService.getAll(idfun!, ano)
        .then((result) => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            const formattedData = result.grafico.map(item => ({
              mes: item.mes as number, // Ensure that the type of mes is number
              periodo: item.periodo,
              total: item.total as number, // Ensure that the type of total is number
            }));
            setRows(result.detalhes);
            setDadosDoGrafico(formattedData);
          }
        });
    }
  }, [idfun]); // Assuming idfun is a constant value or doesn't change over time

  useEffect(() => {
    // Ao iniciar a aplicação, chame HandleSelect com o ano atual
    HandleSelect(anoSelecionado); // Utilizando o anoSelecionado ao iniciar
  }, [anoSelecionado, HandleSelect]); // Incluindo anoSelecionado como dependência para reagir a mudanças

 
  
  
  return (
    <LayoutBaseDePagina 
     
      titulo={ nomeColaborador }

      barraDeFerramentas={
         
        <FerramentasDaListagemvaleresumo
        /* textoano='ano'
      mostrarBotaoano*/
          aoSelecionar={(ano) => {
            if (ano) {
              
              setAnoSelecionado(ano); // Atualiza o anoSelecionado ao selecionar um novo ano
              HandleSelect(ano); // Chama HandleSelect com o novo ano selecionado
            }
          }}
          anoInicial={anoSelecionado}
        />
      }
    > 
      <NavTabs
        id={idfun!}
        case={4}  
         
      />
      <Box margin={1} height={'100%'} display="flex" flexDirection="column" gap={3} >
        <Box  height={'40%'}  display="flex">
          {/*<GraficoDeBarras dados= {dadosDoGrafico} />                        */}
          {dadosDoGrafico && dadosDoGrafico.length > 0 && (
            <BarChart
              xAxis={[
                {
                  id: 'barCategories',
                  data: dadosDoGrafico.map((item) => item.periodo),
                  scaleType: 'band',
                },
              ]}
              series={[
                {
                  data: dadosDoGrafico.map((item) => item.total),
                },
              ]}
              width={1500}
              height={250}
            />
          )}
          
        </Box>
        
        <Box gap={3} height={'60%'}>
          <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto', marginRight: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Período</TableCell>
                  <TableCell>Histórico</TableCell>
                  <TableCell>Valor</TableCell>
                  <TableCell>Financeiro</TableCell>
                  <TableCell>Forma de Desconto</TableCell>
                  <TableCell>Parcela</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <TableRow key={row.idvale} 
      
                    hover
                    sx={{ cursor: 'pointer' }}>

                    <TableCell>{row.periodo}</TableCell>
                    <TableCell>{row.historico}</TableCell>
                    <TableCell>{row.valor}</TableCell>
                    <TableCell>{row.financeiro}</TableCell>
                    <TableCell>{row.tipodesconto}</TableCell>
                    <TableCell>{row.parcela}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
  
            </Table>
          </TableContainer>
       
        </Box>
      
      </Box> 
    </LayoutBaseDePagina>
  ); 
};